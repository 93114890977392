import { AccountLicense } from '@experiences/constants';
import React from 'react';
import { useSelector } from 'react-redux';

import type { IDownloadCard } from '../../../common/interfaces/resource';
import { accountType } from '../../../store/selectors';
import ResourceCenterCMSDownloadCardComponent from './ResourceCenterCMSDownloadCardComponent';
import UpgradeBoxComponent from './UpgradeBoxComponent';

const ResourceCenterCMSDownloadCard: React.FC<{
    cards: IDownloadCard[];
}> = ({ cards }) => {
    const currentAccountType = useSelector(accountType);
    return (
        <div>
            {cards.map((card, index) => (
                <div key={index}>
                    <ResourceCenterCMSDownloadCardComponent card={card} />
                </div>
            ))}
            {AccountLicense[currentAccountType] === AccountLicense.COMMUNITY &&
            <UpgradeBoxComponent />}
        </div>
    );
};

export default ResourceCenterCMSDownloadCard;
