import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import type { IFeaturedDownloadCard } from '../../../common/interfaces/resource';
import ResourceCenterCMSFeaturedDownloadGridComponent from './ResourceCenterCMSFeaturedDownloadGridComponent';

const useStyles = makeStyles(() => ({
    ...createStyles({
        root: {
            display: 'flex',
            height: '100%',
        },
        listContainer: {
            display: 'flex',
            width: '100%',
        },
    }),
}));

const ResourceCenterCMSFeaturedDownloadComponent: React.FC<{
    cards: IFeaturedDownloadCard[];
    showAllDownloads: boolean;
}> = ({
    cards, showAllDownloads,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.listContainer}>
                <Grid
                    container
                    direction="column">
                    <ResourceCenterCMSFeaturedDownloadGridComponent
                        cards={cards.filter((card) => card.highlight)}
                        collapse={false}
                    />
                    <Collapse in={showAllDownloads}>
                        <ResourceCenterCMSFeaturedDownloadGridComponent
                            cards={cards.filter((card) => !card.highlight)}
                            collapse
                        />
                    </Collapse>
                </Grid>
            </div>
        </div>
    );
};

export default ResourceCenterCMSFeaturedDownloadComponent;
