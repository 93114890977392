import {
    UiDataContextProvider,
    UiSuspensefulOutlet,
} from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
    VpnGateway,
    VpnGatewayConnections,
    VpnGatewayConnectionsCreateEdit,
    VpnGatewayCreateEdit,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import { useTenantIdContext } from '../../common/providers/TenantIdProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import {
    getAllVpnGateways,
    vpnGatewaysUri,
} from '../../services/hypervisor';
import { accountLogicalName } from '../../store/selectors';
import type { IVpnGatewayContext } from './interfaces/gateway';

const VpnGatewayComponent = React.lazy(() => import('./VpnGatewayComponent'));
const VpnGatewayAddComponent = React.lazy(() => import('./VpnGatewayAddComponent'));
const VpnGatewayConnectionsGrid = React.lazy(() => import('./VpnGatewayConnectionsGrid'));
const VpnGatewayAddEditConnectionComponent = React.lazy(() => import('./VpnGatewayAddEditConnectionComponent'));

const VpnGatewayProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();
    const accountName = useSelector(accountLogicalName);
    const {
        tenantName, tenantId,
    } = useTenantIdContext();

    const { vpnGatewayKey } = useParams<{ vpnGatewayKey: string }>();

    const { data: responseData } = useSWR(
        {
            requestUri: vpnGatewaysUri,
            accountLogicalName: accountName,
            selectedTenantId: tenantId,
        },
        getAllVpnGateways,
        { suspense: true }
    );

    const gatewayData = useMemo(() => {
        const data = responseData?.value[0];
        return data;
    }, [ responseData ]);

    const breadcrumbs = useMemo(() =>
        [
            {
                index: 0,
                link: routePaths.admin,
                name: organizationName,
            },
            {
                index: 1,
                link: routePaths.adminTenantHome,
                name: tenantName,
            },
            {
                index: 2,
                link: routePaths.adminTenantGateway,
                name: gatewayData?.Name ??
                    translate({ id: gatewayData?.GatewayType === 'ExpressRoute' ? 'CLIENT_EXPRESSROUTE' : 'CLIENT_GATEWAY' }),
            },
            {
                index: 3,
                link: routePaths.adminTenantGatewayConnections.replace(':vpnGatewayKey', vpnGatewayKey ?? ''),
                name: translate({ id: 'CLIENT_CONNECTIONS' }),
            },
            {
                index: 4,
                link: routePaths.adminTenantGatewayConnectionsAdd.replace(':vpnGatewayKey', vpnGatewayKey ?? ''),
                name: translate({ id: 'CLIENT_CONNECTION_CREATE' }),
            },
            {
                index: 4,
                link: routePaths.adminTenantGatewayConnectionsEdit.replace(':vpnGatewayKey', vpnGatewayKey ?? ''),
                name: translate({ id: 'CLIENT_EDIT_CONNECTION' }),
            },
        ],
    [
        gatewayData?.GatewayType,
        gatewayData?.Name,
        organizationName,
        tenantName,
        translate,
        vpnGatewayKey,
    ]);

    return <BreadcrumbProvider breadcrumbs={breadcrumbs}>
        {children}
    </BreadcrumbProvider>;
};

export const VpnGatewayRoutes: UiRouteObject = {
    path: routePaths.adminTenantGateway,
    element: <VpnGatewayProvider>
        <UiSuspensefulOutlet />
    </VpnGatewayProvider>,
    children: [
        {
            path: routePaths.adminTenantGateway,
            redirectUrls: [ VpnGateway ],
            element: <UiDataContextProvider<IVpnGatewayContext> initialState={{ refresh: false }}>
                <VpnGatewayComponent />
                <UiSuspensefulOutlet />
            </UiDataContextProvider>,
            children: [
                {
                    path: routePaths.adminTenantGatewayAddEdit,
                    redirectUrls: [ VpnGatewayCreateEdit ],
                    element: <VpnGatewayAddComponent />,
                },
            ],
        },
        {
            path: routePaths.adminTenantGatewayConnections,
            redirectUrls: [ VpnGatewayConnections ],
            element: <VpnGatewayConnectionsGrid />,
        },
        {
            path: routePaths.adminTenantGatewayConnectionsAddEdit,
            redirectUrls: [ VpnGatewayConnectionsCreateEdit ],
            element: <VpnGatewayAddEditConnectionComponent />,
        },
    ],
};
