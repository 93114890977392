import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import type { IDownloadItem } from '../../../common/interfaces/resource';
import ResourceCenterCMSCardFooter from './ResourceCenterCMSCardFooter';

const useStyles = makeStyles(theme =>
    createStyles({
        itemDescription: {
            fontWeight: 400,
            color: theme.palette.semantic.colorForeground,
        },
        itemList: {
            listStylePosition: 'outside',
            paddingLeft: '16px',
        },
    }),
);

const ResourceCenterCMSTabDescription: React.FC<{
    description: string[];
    version: string;
    isEnterprise?: boolean;
    downloadLink: string;
    downloadList?: Array<IDownloadItem<string>>;
    releaseNotes: string;
}> = ({
    description, version, isEnterprise, downloadLink, downloadList, releaseNotes,
}) => {
    const classes = useStyles();
    return (
        <div>
            <ul
                className={classes.itemList}
                data-cy="download-card-description">
                {description?.map((listItem, index) => (
                    <li key={index}>
                        <UiText
                            className={classes.itemDescription}
                            display="inline">
                            {listItem}
                        </UiText>
                    </li>
                ))}
            </ul>
            <ResourceCenterCMSCardFooter
                studioVersion={version}
                downloadList={downloadList}
                isEnterprise={isEnterprise}
                downloadLink={downloadLink}
                releaseNotes={releaseNotes}
            />
        </div>
    );
};

export default ResourceCenterCMSTabDescription;
