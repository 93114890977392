import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import React from 'react';
import { useIntl } from 'react-intl';

import DestructiveActionConfirmationDialog from './DestructiveActionConfirmationDialog';

type Props = IUiDialogHookCustomContent & {
    orgName: string;
    onLeaveOrgAsync: () => Promise<any>;
};

const LeaveOrganizationConfirmationDialogBody: React.FC<Props> = (props) => {
    const {
        orgName,
        onLeaveOrgAsync,
    } = props;

    const { formatMessage: translate } = useIntl();

    return (
        <DestructiveActionConfirmationDialog
            {...props}
            message={translate({ id: 'CLIENT_LEAVE_ORG_DIALOG_MESSAGE' }, { 0: orgName })}
            actionButtonLabel={translate({ id: 'CLIENT_LEAVE_ORG_DIALOG_ACTION' })}
            textToConfirm={orgName}
            onDestructiveActionAsync={onLeaveOrgAsync}
        />
    );
};

export default LeaveOrganizationConfirmationDialogBody;
