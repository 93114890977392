import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type {
    IFile,
    IPagination,
} from '@experiences/interfaces';
import { OrgConsentEvent } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import { getFormattedDate } from '@experiences/util';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApButton,
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridWrapper,
    PortalIcon,
} from '@uipath/portal-shell-react';
import FileSaver from 'file-saver';
import capitalize from 'lodash/capitalize';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import {
    type ConsentUserRecord,
    exportUserRecords,
    getConsentUserRecords,
} from '../../services/identity/ConsentService';
import { accountGlobalId } from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    container: { width: '100%' },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const DATETIME_FORMAT = 'ddd, MMM D, YYYY HH:mm';

export const ConsentUserRecords = ({ recurrenceInDays }: { recurrenceInDays?: number | null }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();
    const { logEvent } = useTelemetryHelper();

    const [ searchParams, setSearchParams ] = useSearchParams();
    const consentId = searchParams.get('consentId');

    const partitionGlobalId = useSelector(accountGlobalId);

    const [ pagination, setPagination ] = useState<IPagination>({
        top: 25,
        skip: 0,
        searchTerm: '',
        sortBy: 'Date',
        sortOrder: 'desc',
    });
    const [ downloadInProgress, setDownloadInProgress ] = useState(false);

    const close = useCallback(() => {
        searchParams.delete('consentId');
        setSearchParams(searchParams);
    }, [ searchParams, setSearchParams ]);

    const {
        data, isLoading, mutate: mutateConsentUserRecords,
    } = useSWR(consentId ? {
        consentId,
        partitionGlobalId,
    } : null, (props) => getConsentUserRecords({
        ...props,
        pagination,
    }));

    useEffect(() => {
        mutateConsentUserRecords();
    }, [ pagination, mutateConsentUserRecords ]);

    const onGridChange = useCallback(({
        filter, sort, pageIndex, pageSize, searchTerm,
    }: any) => {
        const newPaginationState: IPagination = {
            searchTerm: searchTerm ?? '',
            top: pageSize ?? 25,
            skip: (pageIndex ?? 0) * (pageSize ?? 25),
            sortBy: capitalize(sort?.[0]?.field) || 'Date',
            sortOrder: sort?.[0]?.direction === 'asc' ? 'asc' : 'desc',
        };

        setPagination(newPaginationState);
    }, [ ]);

    const getExpirationDate = useCallback((lastUpdateDate: string) => {
        const expirationDate = new Date(lastUpdateDate);
        expirationDate.setDate(expirationDate.getDate() + (recurrenceInDays ?? 0));
        return getFormattedDate(expirationDate, DATETIME_FORMAT);
    }, [ recurrenceInDays ]);

    const saveConsentFile = useCallback(
        (file: IFile) => {
            // The '\ufeff' appended in front of the data allows Excel to process JA characters correctly
            // It will not affect processing of English characters
            const auditData = new Blob([ '\ufeff' + file.data ], { type: 'application/vnd.ms-excel;charset=utf-8' });
            FileSaver.saveAs(auditData, file.filename);
        },
        [],
    );

    const exportRecords = useCallback(async () => {
        try {
            if (!downloadInProgress && consentId && partitionGlobalId) {
                setDownloadInProgress(true);
                logEvent(OrgConsentEvent.Export, { consentId });
                createNotification(translate({ id: 'CLIENT_DOWNLOAD_IN_PROGRESS' }), notificationType.INPROGRESS);
                const auditFile = await exportUserRecords({
                    partitionGlobalId,
                    consentId,
                });
                saveConsentFile(auditFile);
            }
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        } finally {
            setDownloadInProgress(false);
        }
    }, [
        downloadInProgress,
        createNotification,
        translate,
        partitionGlobalId,
        saveConsentFile,
        setErrorMessage,
        getErrorMessage,
        consentId,
        logEvent,
    ]);

    return (
        <UiStack
            className={classes.container}
            direction="column"
            p={SpacingToken.S}
            gap={SpacingToken.S}>

            <UiStack
                align="center"
                justify="between"
                p={SpacingToken.Micro}
            >
                <UiText variant={FontVariantToken.fontSizeLBold}>
                    {translate({ id: 'CLIENT_CONSENT_USER_RECORDS' }, { count: data?.totalCount ?? 0 })}
                </UiText>
                <UiStack gap={SpacingToken.XS}>
                    <ApButton
                        label={translate({ id: 'CLIENT_USER_EXPORT_BUTTON' })}
                        variant="tertiary"
                        loading={downloadInProgress}
                        disabled={downloadInProgress}
                        data-cy="export-button"
                        onClick={() => exportRecords()}>
                        <PortalIcon
                            slot="start-icon"
                            name="download" />
                    </ApButton>
                    <IconButton
                        onClick={close}
                        aria-label={translate({ id: 'CLIENT_CLOSE' })}
                        data-cy="close-button">
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                </UiStack>
            </UiStack>

            <ApDataGridWrapper<ConsentUserRecord>
                data={data?.results ?? []}
                loading={isLoading}
                selectable={false}
                onChange={onGridChange}
                refresh={() => mutateConsentUserRecords()}
                dataCy='user-records-grid'>

                <ApDataGridColumn
                    property="userEmail"
                    title={translate({ id: 'CLIENT_USER' })}
                    sortable
                />
                <ApDataGridColumn<ConsentUserRecord>
                    property="date"
                    title={translate({ id: 'CLIENT_DATE' })}
                    sortable={false}
                    render={(c) => (
                        <UiText className={classes.ellipsis}>
                            {getFormattedDate(c.lastUpdateTime, DATETIME_FORMAT)}
                        </UiText>
                    )}
                />
                <ApDataGridColumn
                    property="consentAccepted"
                    title={translate({ id: 'CLIENT_STATUS' })}
                    sortable
                    render={(c: ConsentUserRecord) => (
                        <Chip
                            size='small'
                            className={`${c.consentAccepted ? 'success' : 'error'}-mini`}
                            label={translate({ id: `CLIENT_CONSENT_${c.consentAccepted ? 'ACCEPTED' : 'DECLINED'}` })} />
                    )}
                />
                <ApDataGridColumn<ConsentUserRecord>
                    property="expiryDate"
                    title={translate({ id: 'CLIENT_EXPIRY_DATE' })}
                    sortable
                    render={(c: ConsentUserRecord) => (
                        <UiText className={classes.ellipsis}>
                            {recurrenceInDays == null ? translate({ id: 'CLIENT_NONE' }) : getExpirationDate(c.lastUpdateTime)}
                        </UiText>
                    )}
                />

                <ApDataGridFooter
                    length={data?.totalCount ?? 0}
                    pageSizes={[ 10, 25, 50 ]}
                />

            </ApDataGridWrapper>

        </UiStack>
    );
};

