import type { IPagination } from '@experiences/interfaces';

import {
    axiosDelete,
    get,
    getFile,
    post,
    put,
} from '../utility/Requests.default';

const consentsBaseUrl = `${process.buildConfigs.identityApiBaseRoute}/Consent`;

interface ConsentContent {
    title: string;
    content: string;
    acceptRadioContent: string;
    declineRadioContent: string;
}

interface ConsentBase {
    recurrenceInDays: number | null;
    content: ConsentContent;
    isActive: boolean;
}

export interface Consent extends ConsentBase {
    id: string;
    partitionId: string;
    isDeleted?: boolean;
    creationTime: Date;
    lastModificationTime?: Date | null;
    deletionTime?: Date | null;

    createdBy?: string;
    userRecordCount?: number;
}

interface UserRecordsResponse {
    totalCount: number;
    results: ConsentUserRecord[];
}

export interface ConsentUserRecord {
    id: string; // this is user ID but I map it to ID for the grid
    partitionId: number;
    consentId: string;
    createdOn: string;
    userName: string;
    userEmail: string;
    lastUpdateTime: string;
    consentAccepted: string;
}

interface CreateConsentPayload extends ConsentBase {}
interface UpdateConsentPayload extends ConsentBase {
    userReconsentNeeded: boolean;
}

export async function getConsents({ partitionGlobalId }: { partitionGlobalId: string }) {
    return await get<Consent>(`${consentsBaseUrl}/${partitionGlobalId}`);
}

export async function getConsentUserRecords({
    consentId, partitionGlobalId, pagination,
}: { consentId: string; partitionGlobalId: string; pagination: IPagination }) {
    return await get<UserRecordsResponse>(`${consentsBaseUrl}/${partitionGlobalId}/${consentId}/UserConsents`, { pagination });
}

export async function exportUserRecords({
    consentId, partitionGlobalId,
}: { consentId: string; partitionGlobalId: string }) {
    return await getFile(`${consentsBaseUrl}/${partitionGlobalId}/${consentId}/Download`);
}

export async function createNewConsent({
    partitionGlobalId, body,
}: { partitionGlobalId: string; body: CreateConsentPayload }) {
    return await post<CreateConsentPayload>(`${consentsBaseUrl}/${partitionGlobalId}`, { body });
}

export async function updateConsent({
    partitionGlobalId, consentId, body,
}: { partitionGlobalId: string; consentId: string; body: UpdateConsentPayload }) {
    return await put<UpdateConsentPayload>(`${consentsBaseUrl}/${partitionGlobalId}/${consentId}`, { body });
}

export async function deleteConsent({
    partitionGlobalId, consentId,
}: { partitionGlobalId: string; consentId: string }) {
    return await axiosDelete(`${consentsBaseUrl}/${partitionGlobalId}/${consentId}`);
}
