import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { LicensingRobotServicesLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { ReactComponent as RobotIcon } from '../../../images/robotUpgradeTrial.svg';
import { userGlobalId } from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        root: {
            display: 'flex',
            height: '100%',
        },
        pageSubHeader: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
        },
        pageSubHeaderHighlight: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorLogo,
        },
        content: {
            display: 'flex',
            margin: '0px 0px 32px 0px',
        },
        highlightIcon: {
            float: 'right',
            margin: '-8px -8px 0px 0px',
        },
        cardContainer: { width: '100%' },
        cardHeader: {
            display: 'flex',
            flexDirection: 'row',
        },
        cardContent: { padding: '28px 22px 22px 28px' },
        highlightedItemsContainer: { margin: '1em 0px' },
        highlightedItems: { display: 'flex' },
        doneIcon: {
            fontSize: '24px',
            color: theme.palette.semantic.colorSuccessIcon,
        },
        highlightedItem: {
            paddingLeft: '10px',
            fontWeight: 500,
        },
        highlightCardContainer: {
            width: '100%',
            borderColor: theme.palette.semantic.colorLogo,
            boxShadow: theme.shadows[1],
        },
        button: {
            margin: '0px 8px',
            minWidth: '176px',
        },
        downloadAndCopyStudio: { marginTop: '16px' },
        buttonDefault: {
            fontWeight: 600,
            textTransform: 'none',
            height: '32px',
            '& span': { height: '20px' },
        },
        copyButton: { marginLeft: '12px' },
    }),
}));

const description: string[] = [
    'CLIENT_UPGRADE_BOX_POINT_1',
    'CLIENT_UPGRADE_BOX_POINT_2',
    'CLIENT_UPGRADE_BOX_POINT_3',
];

const UpgradeBoxComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const userId = useSelector(userGlobalId);
    const getLocalizedLink = useDocumentationLinks();

    const highlightedHeader = (
        <span className={classes.pageSubHeaderHighlight}>
            {translate({ id: 'CLIENT_UPGRADE_BOX_HEADER_HIGHLIGHT' })}
        </span>
    );

    return (
        <div className={classes.content}>
            <Card
                className={classes.highlightCardContainer}
                variant="outlined"
                data-cy="upgrade-box">
                <CardContent className={classes.cardContent}>
                    <RobotIcon className={classes.highlightIcon} />
                    <div className={classes.cardHeader}>
                        <UiText
                            className={classes.pageSubHeader}
                            data-cy="upgrade-box-title">
                            {translate({ id: 'CLIENT_UPGRADE_BOX_HEADER' }, { 0: highlightedHeader })}
                        </UiText>
                    </div>
                    <div className={classes.highlightedItemsContainer}>
                        {description.map((listItem, index) => (
                            <div
                                key={index}
                                className={classes.highlightedItems}>
                                <span className={clsx('material-icons-outlined', classes.doneIcon)}>
                                    done
                                </span>
                                <UiText
                                    className={classes.highlightedItem}
                                    display="inline"
                                    data-cy="upgrade-box-points">
                                    {translate({ id: listItem })}
                                </UiText>
                            </div>
                        ))}
                    </div>
                    <div className={classes.downloadAndCopyStudio}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                portalTelemetry.trackTrace({
                                    message: `Resource Center CMS Studio Download: ${userId} requested enterprise trial`,
                                    severityLevel: SeverityLevel.Info,
                                });
                                navigate(getRoute(RouteNames.ResourceCenterCMSUpgradeToEnterprise));
                            }}
                            className={classes.buttonDefault}
                            data-cy="download-card-start-trial-button"
                        >
                            {translate({ id: 'CLIENT_START_TRIAL' })}
                        </Button>
                        <Button
                            variant="outlined"
                            className={clsx(classes.buttonDefault, classes.copyButton)}
                            href={getLocalizedLink({ articleSlug: LicensingRobotServicesLink })}
                            rel="noopener noreferrer"
                            target="_blank"
                            data-cy="download-card-learn-more"
                        >
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default UpgradeBoxComponent;
