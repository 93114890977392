import { UiText } from '@experiences/ui-common';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import ResourceDownloadLinkComponent from './ResourceDownloadLinkComponent';

const useStyles = makeStyles(() => ({ ...createStyles({ subtitle: { fontWeight: 500 } }) }));

const ResourceCardFooterComponent: React.FC<{
    studioVersion: string;
    studioType: string;
    downloadLink: string;
    releaseLink?: string;
}> = ({
    studioVersion, studioType, downloadLink, releaseLink,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div>
            {studioVersion && (
                <div style={{ marginTop: '5px' }}>
                    <UiText
                        className={classes.subtitle}
                        display="inline"
                        data-cy="resource-center-studio-version">
                        {`${translate({ id: 'CLIENT_VERSION' })} ${studioVersion}`}
                    </UiText>
                    {releaseLink && (
                        <Link
                            href={String(releaseLink)}
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{ marginLeft: '5px' }}>
                            <UiText
                                className={classes.subtitle}
                                display="inline">
                                {translate({ id: 'CLIENT_VIEW_RELEASE' })}
                            </UiText>
                        </Link>
                    )}
                </div>
            )}
            <div className="downloadAndCopyStudio">
                <ResourceDownloadLinkComponent
                    link={downloadLink}
                    type={studioType} />
            </div>
        </div>
    );
};

export default ResourceCardFooterComponent;
