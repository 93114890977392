import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApRadioButton,
    ApRadioGroup,
} from '@uipath/portal-shell-react';
import React from 'react';

import { UiText } from '../UiText';

export interface UiRadioGroupProps {
    label: string;
    required?: boolean;
    value: string;
    onChange: (value: string) => void;
    options: Array<{ label: string; description?: string; value: string; 'data-cy'?: string; disabled?: boolean }>;
    disabled?: boolean;
}

export const UiRadioGroup: React.FC<UiRadioGroupProps> = ({
    label, required, value, onChange, options, disabled,
}) => (
    <div>
        {/* TODO: revisit this, ApRadioGroup should indent the items -- fix in Apollo. */}
        <UiText
            color="var(--color-foreground-de-emp)"
            variant={FontVariantToken.fontSizeMBold}>
            {`${label}${required ? ' *' : ''}`}
        </UiText>
        <ApRadioGroup
            style={{ marginLeft: 14 }}
            value={value}
            disabled={disabled}
            onValueChanged={(event) => onChange(event.detail)}
            orientation={'horizontal' as any}>
            {options.map((option) => (
                <ApRadioButton
                    key={option.value}
                    label={option.label}
                    value={option.value}
                    disabled={option.disabled}
                    data-cy={option['data-cy']}
                />
            ))}
        </ApRadioGroup>
    </div>
);
