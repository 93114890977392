import { OrgConsentEvent } from '@experiences/telemetry';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import isEqual from 'lodash/isEqual';
import type React from 'react';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type { Consent } from '../../services/identity/ConsentService';
import {
    createNewConsent,
    getConsents,
    updateConsent,
} from '../../services/identity/ConsentService';
import { accountGlobalId } from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { TimeUnits } from './ConsentConstants';
import { ConsentContentChangesDialogBody } from './ConsentContentChangesDialogBody';
import { ConsentUnsavedChangesDialogBody } from './ConsentUnsavedChangesDialogBody';

interface IConsentFormData {
    title: string;
    content: string;
    acceptRadioContent: string;
    declineRadioContent: string;
    recurrence: {
        isEnabled: string;
        duration: number;
        unit: TimeUnits;
    };
}

const DEFAULT_FORM: IConsentFormData = {
    title: '',
    content: '',
    acceptRadioContent: 'I acknowledge and consent to this communication',
    declineRadioContent: 'I decline to consent',
    recurrence: {
        isEnabled: 'false',
        duration: 1,
        unit: TimeUnits.MONTHS,
    },
};

const useConsentFormViewModel = (
    onError: (error: any) => Promise<void> | void,
) => {
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const createNotification = useUiSnackBar();
    const partitionGlobalId = useSelector(accountGlobalId);
    const createDialog = useShowDialog();
    const { id } = useParams() as { id: string };
    const { logEvent } = useTelemetryHelper();
    const [ loading, setLoading ] = useState(true);

    const {
        data: consent, isLoading: consentLoading,
    } = useSWR(id ? { partitionGlobalId } : null, getConsents);

    const methods = useForm<IConsentFormData>({
        mode: 'onChange',
        defaultValues: DEFAULT_FORM,
    });

    const {
        handleSubmit,
        formState: {
            isDirty, isValid,
        },
        setError,
        clearErrors,
        getValues,
        setValue,
        reset,
    } = methods;

    const daysToTimeUnits = useCallback((days: number) => {
        if (days % 365 === 0) {
            return {
                duration: days / 365,
                unit: TimeUnits.YEARS,
            };
        }
        if (days % 30 === 0) {
            return {
                duration: days / 30,
                unit: TimeUnits.MONTHS,
            };
        }
        if (days % 7 === 0) {
            return {
                duration: days / 7,
                unit: TimeUnits.WEEKS,
            };
        }
        return {
            duration: days,
            unit: TimeUnits.DAYS,
        };
    }, []);

    const setFormToExistingConsent = useCallback((consentDetails?: Consent) => {
        if (!id || !consentDetails?.content) {
            setLoading(false);
            return;
        }
        setLoading(true);
        reset();
        setValue('title', consentDetails.content.title);
        setValue('content', consentDetails.content.content);
        setValue('acceptRadioContent', consentDetails.content.acceptRadioContent);
        setValue('declineRadioContent', consentDetails.content.declineRadioContent);
        setValue('recurrence.isEnabled', String(!!consentDetails.recurrenceInDays));

        const recurrence = daysToTimeUnits(consentDetails.recurrenceInDays ?? 1);
        setValue('recurrence.duration', recurrence.duration);
        setValue('recurrence.unit', recurrence.unit);

        setLoading(false);
    }, [ reset, setValue, daysToTimeUnits, id ]);

    useEffect(() => {
        setFormToExistingConsent(consent);
    }, [ consent, setFormToExistingConsent ]);

    const toDays = useCallback(({
        duration, unit, isEnabled,
    }: { duration: number; unit: TimeUnits; isEnabled: string }) => {
        if (isEnabled === 'false') {
            return null;
        }
        switch (unit) {
            case TimeUnits.DAYS:
                return duration;
            case TimeUnits.WEEKS:
                return duration * 7;
            case TimeUnits.MONTHS:
                return duration * 30;
            case TimeUnits.YEARS:
                return duration * 365;
            default:
                return duration;
        }
    }, []);

    const onSubmit = useCallback(async (data: IConsentFormData) => {
        try {
            const body = {
                isActive: false,
                content: {
                    title: data.title,
                    content: data.content,
                    acceptRadioContent: data.acceptRadioContent,
                    declineRadioContent: data.declineRadioContent,
                },
                recurrenceInDays: toDays(data.recurrence),
            };

            if (id && consent) {
                let proceed = !consent.userRecordCount || consent.userRecordCount < 1;
                let reconsent = false;
                if (!proceed) {
                    proceed = await createDialog({
                        title: translate({ id: 'CLIENT_CONSENT_CONTENT_CHANGES_TITLE' }),
                        icon: 'error',
                        customDialogContent: ConsentContentChangesDialogBody,
                        customDialogContentProps: {
                            userRecordCount: consent.userRecordCount,
                            formReset: () => setFormToExistingConsent(consent),
                            setFormReconsent: (value: boolean) => {
                                reconsent = value;
                                logEvent(OrgConsentEvent.Reconsent, { consentId: consent.id });
                            },
                        },
                    });
                }
                if (proceed) {
                    await updateConsent({
                        partitionGlobalId,
                        consentId: id,
                        body: {
                            ...body,
                            userReconsentNeeded: reconsent,
                            isActive: consent.isActive,
                        },
                    });
                    createNotification(
                        translate({ id: 'CLIENT_ORG_CONSENT_SAVE_SUCCESS' }),
                        notificationType.SUCCESS
                    );
                    logEvent(OrgConsentEvent.Save, {
                        consentId: id,
                        type: 'edit',
                    });
                    navigate(getRoute(RouteNames.UserConsent));
                }
            } else {
                await createNewConsent({
                    partitionGlobalId,
                    body,
                });
                createNotification(
                    translate({ id: 'CLIENT_ORG_CONSENT_SAVE_SUCCESS' }),
                    notificationType.SUCCESS
                );
                logEvent(OrgConsentEvent.Save, {
                    consentId: id,
                    type: 'create',
                });
                navigate(getRoute(RouteNames.UserConsent));
            }
        } catch (error) {
            onError(error);
        }
    }, [
        createNotification,
        getRoute,
        navigate,
        onError,
        translate,
        id,
        partitionGlobalId,
        createDialog,
        consent,
        setFormToExistingConsent,
        toDays,
        logEvent,
    ]);

    const disableSubmit = useMemo(
        () => !isDirty || !isValid || consentLoading,
        [ isDirty, isValid, consentLoading ]
    );

    const filterNonNumber = useCallback((value: string) => {
        const NON_DIGIT = '/[^0-9]/g';
        let numString = value.replace(NON_DIGIT, '');
        numString = isNaN(parseInt(numString)) ? '' : numString;
        return numString;
    }, []);

    const isValidTimeRange = useCallback((num: string, units: TimeUnits) => {
        const numInt = parseInt(num);
        if (
            isNaN(numInt) ||
            (numInt && (
                (numInt > 365 * 10 && units === TimeUnits.DAYS)
                || (numInt > 52 * 10 && units === TimeUnits.WEEKS)
                || (numInt > 12 * 10 && units === TimeUnits.MONTHS)
                || (numInt > 1 * 10 && units === TimeUnits.YEARS)
            ))
        ) {
            return false;
        }
        return true;
    }, []);

    const handleInteger = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const name = 'recurrence.duration';
            const { value } = event.target;
            if (value?.trim().length === 0 || value?.trim() === '0' || !value?.trim().match(/[0-9]/g)) {
                setError(name, { type: 'min' });
            } else {
                clearErrors(name);
            }
            const numResult = filterNonNumber(value);
            const units = getValues('recurrence.unit') as TimeUnits;
            if (!isValidTimeRange(numResult, units)) {
                setError(name, { type: 'max' });
            }
            const valueChanged = !isEqual(numResult, getValues(name));
            setValue(name, Number(numResult), { shouldDirty: valueChanged });
        },
        [ filterNonNumber, getValues, isValidTimeRange, setValue, setError, clearErrors ],
    );

    const handleUnitOfTime = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const name = 'recurrence.duration';
            const numResult = String(getValues('recurrence.duration'));
            clearErrors(name);
            const units = event.target.value as TimeUnits;
            if (!isValidTimeRange(numResult, units)) {
                setError(name, { type: 'max' });
            }
        },
        [ clearErrors, getValues, setError, isValidTimeRange ],
    );

    const timeUnits = useMemo(() => ({
        [TimeUnits.DAYS]: 'CLIENT_DAY',
        [TimeUnits.WEEKS]: 'CLIENT_WEEK',
        [TimeUnits.MONTHS]: 'CLIENT_MONTH',
        [TimeUnits.YEARS]: 'CLIENT_TIME_UNIT_YEAR',
    }), [ ]);

    const cancel = useCallback(async () => {
        // Proceed means discard changes and navigate away
        let proceed = !isDirty;
        if (!proceed) {
            proceed = await createDialog({
                title: translate({ id: 'CLIENT_CONSENT_UNSAVED_CHANGES_TITLE' }),
                icon: 'warning',
                customDialogContent: ConsentUnsavedChangesDialogBody,
                customDialogContentProps: {},
            });
        }
        if (proceed) {
            logEvent(OrgConsentEvent.Cancel, {
                consentId: id,
                type: id ? 'edit' : 'create',
            });
            navigate(getRoute(RouteNames.UserConsent));
        }
    }, [
        createDialog,
        getRoute,
        isDirty,
        navigate,
        translate,
        logEvent,
        id,
    ]);

    return {
        methods: {
            ...methods,
            handleSubmit: handleSubmit(onSubmit),
        },
        disableSubmit,
        handleInteger,
        handleUnitOfTime,
        timeUnits,
        cancel,
        loading: consentLoading || loading,
        consent,
    };
};

export default useConsentFormViewModel;
