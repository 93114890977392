import type { IUserLicenses } from '@experiences/interfaces';
import type { FilterManager } from '@uipath/apollo-angular-elements';

import type { IUserCIS } from '../../../common/interfaces/cis/user';

export enum ProfileTabs {
    MEMBERS = 'members',
    GROUP_MEMBERSHIPS = 'group-memberships',
    LICENSES = 'licenses',
    ACCESS = 'access',
    INFO = 'info',
}

export type IUserWithLicenses = IUserCIS & Partial<IUserLicenses>;

export interface IProfileMembersFiltersContext {
    filterManager?: FilterManager<IProfileMembersDto>;
}

export interface IProfileMembersDto {
    name: string;
}

export type ProfileLicense = { id: string; groupName?: string; groupId?: string };
