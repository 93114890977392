import { GlobalStyles } from '@experiences/theme';
import {
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens, { FontVariantToken } from '@uipath/apollo-core';
import {
    ApButton,
    ApRichTextEditor,
    ApTooltip,
} from '@uipath/portal-shell-react';
import clsx from 'clsx';
import { sanitize } from 'dompurify';
import { marked } from 'marked';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import UiForm from '../common/UiForm';
import useConsentFormViewModel from './ConsentFormViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        verticalContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flex: 1,
            justifyContent: 'space-between',
            maxHeight: 'calc(100vh - 136px)',
        },
        scrollableContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'auto',
        },
        splitContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            flex: 1,
        },
        form: {
            padding: '18px 40px 0px 20px',
            minWidth: '500px',
        },
        formSectionHeader: {
            color: theme.palette.semantic.colorForegroundEmp,
            lineHeight: Tokens.FontFamily.FontBrandMLineHeight,
        },
        formSettingsHeader: {
            marginTop: Tokens.Spacing.SpacingM,
            padding: `${Tokens.Padding.PadXl} 0px`,
        },
        formRecurrenceLabel: { marginBottom: Tokens.Padding.PadL },
        formField: { marginBottom: Tokens.Padding.PadL },
        formRadioGroup: {
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: Tokens.Padding.PadXxl,
            marginBottom: Tokens.Padding.PadS,
        },
        formViewContainer: {
            flex: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: Tokens.Spacing.SpacingM,
            minWidth: '560px',
            boxShadow: 'inset 100vw 100vh rgba(0, 0, 0, 0.3)',
        },
        formView: {
            backgroundColor: theme.palette.semantic.colorBackground,
            padding: `${Tokens.Spacing.SpacingL} ${Tokens.Padding.PadXxxl}`,
            borderRadius: '3px',
            maxHeight: '90%',
            maxWidth: '90%',
            minWidth: 'max(480px, 80%)',
            minHeight: '80%',
            display: 'flex',
            flexDirection: 'column',
        },
        formPreviewPlaceholder: { color: `${theme.palette.semantic.colorForegroundLight} !important` },
        formPreviewTitle: {
            padding: Tokens.Padding.PadL,
            margin: `${Tokens.Spacing.SpacingL} 0px`,
            color: theme.palette.semantic.colorForeground,
        },
        formPreviewDisclaimer: {
            color: theme.palette.semantic.colorForeground,
            padding: Tokens.Padding.PadL,
        },
        formPreviewDivider: { margin: `${Tokens.Padding.PadL} 0px` },
        formPreviewBody: {
            maxHeight: '600px',
            overflowY: 'auto',
            flex: 1,
            fontSize: Tokens.FontFamily.FontMSize,
            lineHeight: Tokens.FontFamily.FontMLineHeight,
            fontWeight: Tokens.FontFamily.FontMWeight,
            padding: Tokens.Padding.PadL,

            '& table': {
                borderCollapse: 'collapse',
                borderSpacing: 0,
                overflow: 'scroll',
                tableLayout: 'fixed',
                width: 'max-content',
                margin: `${Tokens.Spacing.SpacingXl} 0px`,
            },

            '& th, td': {
                border: `1px solid ${theme.palette.semantic.colorBorder}`,
                width: '72px',
                minWidth: '72px',
                verticalAlign: 'top',
                textAlign: 'start',
                padding: `${Tokens.Padding.PadM} ${Tokens.Padding.PadL}`,
                position: 'relative',
                outline: 'none',
            },

            '& th': {
                backgroundColor: theme.palette.semantic.colorBackgroundGray,
                textAlign: 'start',
            },

            '& blockquote': {
                margin: 0,
                marginLeft: Tokens.Spacing.SpacingM,
                fontSize: Tokens.FontFamily.FontMSize,
                color: theme.palette.semantic.colorForegroundDeEmp,
                borderLeft: `4px solid ${theme.palette.semantic.colorBackgroundGray}`,
                paddingLeft: Tokens.Padding.PadXxxl,
            },
        },
        formPreviewRadioGroup: { margin: `${Tokens.Padding.PadS} 0px ${Tokens.Padding.PadL} ${Tokens.Padding.PadL}` },
        formPreviewLabel: { alignItems: 'flex-start' },
        formPreviewLabelTypography: { marginTop: '11px' },
        formPreviewActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        timeoutSettings: {},
        timeoutControl: {
            display: 'flex',
            gap: Tokens.Padding.PadL,
            alignItems: 'center',
        },
        timeoutDuration: { maxWidth: '80px' },
        timeoutUnits: {},
        timeoutUnit: {},
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: Tokens.Padding.PadL,
            marginTop: Tokens.Padding.PadL,
            marginRight: Tokens.Spacing.SpacingM,
        },
        footerDivider: { margin: `${Tokens.Spacing.SpacingS} ${Tokens.Spacing.SpacingM} ${Tokens.Spacing.SpacingMicro} ${Tokens.Spacing.SpacingM}` },
    }),
}));

const ConsentForm: React.FC<{
    onError: (error: any) => Promise<void> | void;
}> = ({ onError }) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const [ previewRadioValue, setPreviewRadioValue ] = useState('');

    const {
        methods: {
            register,
            handleSubmit,
            formState: {
                errors, isSubmitting,
            },
            control,
            watch,
        },
        disableSubmit,
        handleInteger,
        handleUnitOfTime,
        timeUnits,
        cancel,
        loading,
        consent,
    } = useConsentFormViewModel(
        onError,
    );

    const showRecurrenceSettings = watch('recurrence.isEnabled') === 'true';

    const content = watch('content') ?? consent?.content?.title ?? '';
    const title = watch('title') ?? consent?.content?.title ?? '';
    const acceptRadioContent = watch('acceptRadioContent') ?? consent?.content?.acceptRadioContent ?? '';
    const declineRadioContent = watch('declineRadioContent') ?? consent?.content?.declineRadioContent ?? '';

    const parsedContent = useMemo(() => sanitize(marked.parse(
        // eslint-disable-next-line no-misleading-character-class
        content.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, '')
    ) as string), [ content ]);

    const getErrorHelperText = useCallback((fieldErrors: any, fieldLabelKey: string, length: number) => {
        if (fieldErrors?.type === 'required') {
            return translate(
                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                { 0: translate({ id: fieldLabelKey }) },
            );
        }
        if (fieldErrors?.type === 'maxLength') {
            return translate(
                { id: 'CLIENT_MAX_LENGTH_ERROR_SPECIFIC' },
                {
                    field: translate({ id: fieldLabelKey }),
                    num: length,
                },
            );
        }
        return undefined;
    }, [ translate ]);

    return (
        <div className={classes.verticalContainer}>
            <div className={classes.scrollableContainer}>
                <div className={classes.splitContainer}>
                    <UiForm
                        onSubmit={handleSubmit}
                        className={classes.form}>
                        <UiText
                            variant={FontVariantToken.fontSizeMBold}
                            className={classes.formSectionHeader}>
                            {translate({ id: 'CLIENT_CONSENT_MESSAGE_CONTENT' })}
                        </UiText>
                        <TextField
                            className={classes.formField}
                            label={translate({ id: 'CLIENT_CONSENT_TITLE' })}
                            placeholder={translate({ id: 'CLIENT_CONSENT_TITLE_PLACEHOLDER' })}
                            variant='outlined'
                            fullWidth
                            size='small'
                            error={!!errors.title}
                            required
                            inputProps={{
                                'data-cy': 'consent-title',
                                ...register('title', {
                                    required: true,
                                    maxLength: 80,
                                }),
                            }}
                            helperText={getErrorHelperText(errors.title, 'CLIENT_CONSENT_TITLE', 80)}
                            disabled={loading}
                        />
                        <Controller
                            control={control}
                            name="content"
                            rules={{ required: true }}
                            render={({ field }) => (
                                <ApRichTextEditor
                                    label={translate({ id: 'CLIENT_CONSENT_CONTENT' })}
                                    placeholder={translate({ id: 'CLIENT_CONSENT_CONTENT_PLACEHOLDER' })}
                                    required
                                    initialContent={field.value}
                                    error={!!errors.content}
                                    helperText={
                                        errors.content
                                            ? translate(
                                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                                { 0: translate({ id: 'CLIENT_CONSENT_CONTENT' }) },
                                            ) : undefined
                                    }
                                    onMarkdownChange={ev => {
                                        field.onChange(ev.detail);
                                    }}
                                    loading={loading}
                                    disabled={loading}
                                    maxHeight='480px'
                                />
                            )}
                        />
                        <TextField
                            label={translate({ id: 'CLIENT_CONSENT_RADIO_BUTTON_1' })}
                            className={classes.formField}
                            variant='outlined'
                            fullWidth
                            size='small'
                            error={!!errors.acceptRadioContent}
                            required
                            disabled={loading}
                            helperText={getErrorHelperText(errors.acceptRadioContent, 'CLIENT_CONSENT_RADIO_BUTTON_1', 200)
                                ?? translate({ id: 'CLIENT_CONSENT_RADIO_BUTTON_1_HELPER_TEXT' })}
                            inputProps={{
                                'data-cy': 'consent-radio-1',
                                ...register('acceptRadioContent', {
                                    required: true,
                                    maxLength: 200,
                                }),
                            }}
                        />
                        <TextField
                            label={translate({ id: 'CLIENT_CONSENT_RADIO_BUTTON_2' })}
                            className={classes.formField}
                            variant='outlined'
                            fullWidth
                            disabled={loading}
                            size='small'
                            error={!!errors.declineRadioContent}
                            required
                            helperText={getErrorHelperText(errors.declineRadioContent, 'CLIENT_CONSENT_RADIO_BUTTON_2', 200)
                                ?? translate({ id: 'CLIENT_CONSENT_RADIO_BUTTON_2_HELPER_TEXT' })}
                            inputProps={{
                                'data-cy': 'consent-radio-2',
                                ...register('declineRadioContent', {
                                    required: true,
                                    maxLength: 200,
                                }),
                            }}
                        />
                        <UiText
                            variant={FontVariantToken.fontSizeMBold}
                            className={clsx(classes.formSectionHeader, classes.formSettingsHeader)}>
                            {translate({ id: 'CLIENT_SETTINGS' })}
                        </UiText>
                        <Controller
                            control={control}
                            name="recurrence.isEnabled"
                            rules={{ required: true }}
                            disabled={loading}
                            render={({ field }) => (
                                <>
                                    <UiText
                                        id="recurrence-label"
                                        variant={FontVariantToken.fontSizeMBold}
                                        className={classes.formRecurrenceLabel}>
                                        {translate({ id: 'CLIENT_CONSENT_RECURRENCE' })}
                                    </UiText>
                                    <RadioGroup
                                        value={field.value}
                                        onChange={e => field.onChange(e.target.value)}
                                        data-cy="recurrence-radio-buttons"
                                        aria-labelledby='recurrence-label'
                                        className={classes.formRadioGroup}
                                    >
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio color="primary" />}
                                            label={translate({ id: 'CLIENT_CONSENT_RECURRENCE_ONCE' })}
                                            disabled={loading}
                                        />
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio color="primary" />}
                                            label={translate({ id: 'CLIENT_CONSENT_RECURRENCE_REPEAT' })}
                                            disabled={loading}
                                        />
                                    </RadioGroup>
                                </>
                            )}
                        />
                        {
                            showRecurrenceSettings && (
                                <div className={classes.timeoutSettings}>
                                    <div className={classes.timeoutControl}>
                                        <UiText>
                                            {translate({ id: 'CLIENT_CONSENT_RECURRENCE_EVERY' })}
                                        </UiText>
                                        <Controller
                                            name="recurrence.duration"
                                            control={control}
                                            rules={{
                                                required: true,
                                                min: 1,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    autoComplete="off"
                                                    error={!!errors.recurrence?.duration}
                                                    inputProps={{
                                                        'data-cy': 'recurrence-duration-textfield',
                                                        min: 1,
                                                    }}
                                                    onChange={event => {
                                                        handleInteger(event);
                                                    }}
                                                    id="recurrenceDuration"
                                                    type='number'
                                                    className={classes.timeoutDuration}
                                                    variant="outlined"
                                                    disabled={loading}
                                                />
                                            )}
                                        />
                                        <UiSelect
                                            control={control}
                                            name="recurrence.unit"
                                            options={timeUnits}
                                            className={classes.timeoutUnit}
                                            dataCy="recurrence-unit-select"
                                            inputLabel=""
                                            disabled={loading}
                                            error={!!errors.recurrence?.duration}
                                            onChange={handleUnitOfTime}
                                        />
                                    </div>
                                    <FormHelperText
                                        error={!!errors.recurrence?.duration}
                                        data-cy="timeout-error-text"
                                    >
                                        {
                                            (errors.recurrence?.duration?.type === 'required' &&
                                            translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                                        (errors.recurrence?.duration?.type === 'min' &&
                                            translate({ id: 'CLIENT_CONSENT_MIN_RECURRENCE' })) ||
                                        (errors.recurrence?.duration?.type === 'max' &&
                                            translate({ id: 'CLIENT_CONSENT_MAX_RECURRENCE' })) || ''
                                        }
                                    </FormHelperText>
                                </div>
                            )
                        }
                    </UiForm>
                    <div
                        data-cy="consent-form-view"
                        className={classes.formViewContainer}>
                        <div className={classes.formView}>
                            <ApTooltip title={translate({ id: 'CLIENT_CONSENT_DISCLAIMER_TOOLTIP' })}>
                                <UiText className={classes.formPreviewDisclaimer}>
                                    {translate({ id: 'CLIENT_CONSENT_PREVIEW_DISCLAIMER' })}
                                </UiText>
                            </ApTooltip>
                            <Divider className={classes.formPreviewDivider} />
                            <UiText
                                variant={FontVariantToken.fontSizeH4Bold}
                                className={clsx({
                                    [classes.formPreviewTitle]: true,
                                    [classes.formPreviewPlaceholder]: !title,
                                })}>
                                {title || translate(
                                    { id: 'CLIENT_CONSENT_PREVIEW_SPECIFIC_PLACEHOLDER' },
                                    { field: translate({ id: 'CLIENT_CONSENT_TITLE' }) }
                                )}
                            </UiText>
                            <div
                                className={clsx({
                                    [classes.formPreviewBody]: true,
                                    [classes.formPreviewPlaceholder]: !parsedContent,
                                })}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={
                                    {
                                        __html: parsedContent || translate(
                                            { id: 'CLIENT_CONSENT_PREVIEW_SPECIFIC_PLACEHOLDER' },
                                            { field: translate({ id: 'CLIENT_CONSENT_CONTENT' }) }
                                        ),
                                    }
                                }
                            />
                            <Divider className={classes.formPreviewDivider} />
                            <RadioGroup
                                value={previewRadioValue}
                                onChange={e => setPreviewRadioValue(e.target.value)}
                                data-cy="recurrence-radio-buttons"
                                aria-labelledby='recurrence-label'
                                className={classes.formPreviewRadioGroup}
                            >
                                <FormControlLabel
                                    value="true"
                                    control={<Radio color="primary" />}
                                    label={acceptRadioContent || translate(
                                        { id: 'CLIENT_CONSENT_PREVIEW_SPECIFIC_PLACEHOLDER' },
                                        { field: translate({ id: 'CLIENT_CONSENT_RADIO_BUTTON_1' }) }
                                    )}
                                    className={classes.formPreviewLabel}
                                    componentsProps={{
                                        typography: {
                                            className: clsx({
                                                [classes.formPreviewLabelTypography]: true,
                                                [classes.formPreviewPlaceholder]: !acceptRadioContent,
                                            }),
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio color="primary" />}
                                    label={declineRadioContent || translate(
                                        { id: 'CLIENT_CONSENT_PREVIEW_SPECIFIC_PLACEHOLDER' },
                                        { field: translate({ id: 'CLIENT_CONSENT_RADIO_BUTTON_2' }) }
                                    )}
                                    className={classes.formPreviewLabel}
                                    componentsProps={{
                                        typography: {
                                            className: clsx({
                                                [classes.formPreviewLabelTypography]: true,
                                                [classes.formPreviewPlaceholder]: !declineRadioContent,
                                            }),
                                        },
                                    }}
                                />
                            </RadioGroup>
                            <div className={classes.formPreviewActions}>
                                <ApButton
                                    variant="primary"
                                    disabled={!previewRadioValue}
                                    label={translate({ id: 'CLIENT_SUBMIT' })}
                                    onClick={() => setPreviewRadioValue('')}
                                    data-cy="consent-preview-submit"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Divider className={classes.footerDivider} />
                <div className={classes.footer}>
                    <ApButton
                        variant="secondary"
                        onClick={cancel}
                        label={translate({ id: 'CLIENT_CANCEL' })}
                        data-cy="consent-cancel"
                    />
                    <ApButton
                        variant='primary'
                        onClick={handleSubmit}
                        loading={isSubmitting}
                        disabled={disableSubmit || isSubmitting}
                        data-cy='consent-save-draft'
                        label={translate({ id: 'CLIENT_SAVE' })}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConsentForm;
