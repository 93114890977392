import { Region } from '@experiences/constants';
import { OrgSelfServeMigrationEvent } from '@experiences/telemetry';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import {
    getDateFromNow,
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import Fade from '@mui/material/Fade';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApButton,
    ApStepper,
    ApStepperStep,
} from '@uipath/portal-shell-react';
import type { FC } from 'react';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

import * as RouteNames from '../../../../common/constants/RouteNames';
import type { IMigrationRequest } from '../../../../common/interfaces/gws';
import {
    MigrationEndWindowDays,
    MigrationStartWindowDays,
} from '../../../../common/interfaces/gws';
import { scheduleOrganizationMove } from '../../../../services/global-workflow-service/OrgMigration';
import {
    accountLogicalName,
    organizationRegion,
} from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import UiPageContainer from '../../../common/UiPageContainer/UiPageContainer';
import { OrgMigrationNotAvailableDialog } from './OrgMigrationNotAvailableDialog';
import { OrgMigrationStepConfirm } from './OrgMigrationStepConfirm';
import { OrgMigrationStepSelectRegion } from './OrgMigrationStepSelectRegion';
import { OrgMigrationStepSelectTime } from './OrgMigrationStepSelectTime';
import type { IOrgMigrationFormData } from './types';

const useStyles = makeStyles(theme => ({
    mainDisplay: {
        display: 'flex',
        width: '100%',
    },
    header: { marginTop: '40px' },
    standardLayout: { flex: 3 },
    stepper: { flex: 1 },
    content: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '800px',
        marginBottom: '64px',
    },
    footer: {
        position: 'fixed',
        bottom: '0px',
        right: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        height: '64px',
        backgroundColor: theme.palette.semantic.colorBackground,
        zIndex: 1,
        width: '100%',
    },
    button: { minWidth: '120px' },
}));

const steps = [
    {
        component: OrgMigrationStepSelectRegion,
        label: 'CLIENT_SELECT_REGION',
    },
    {
        component: OrgMigrationStepSelectTime,
        label: 'CLIENT_MIGRATION_PREFERRED_TIME',
    },
    {
        component: OrgMigrationStepConfirm,
        label: 'CLIENT_CONFIRM_SCHEDULED_MOVE',
    },
];

export const OrgMigrationPage: FC = () => {

    const [ activeStep, setActiveStep ] = useState(0);
    const [ initialStepDone, setInitialStepDone ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const createDialog = useShowDialog();
    const currentRegion = useSelector(organizationRegion);
    const getRoute = useRouteResolver();
    const accountName = useSelector(accountLogicalName);
    const { logEvent } = useTelemetryHelper();

    const moveStepper = useCallback((isForward?: boolean) => {
        setInitialStepDone(true);
        setActiveStep(prevStep => (isForward ? prevStep + 1 : prevStep - 1));
    }, []);

    const methods = useForm<IOrgMigrationFormData>({
        mode: 'onChange',
        shouldUnregister: false,
        defaultValues: {
            hour: '00:00 - 04:00',
            sourceRegion: currentRegion,
            destinationRegion: Region.None,
        },
    });

    const {
        formState: { isValid }, setError, getValues,
    } = methods;

    const handleFailure = useCallback(async () => {
        await createDialog({
            title: translate({ id: 'CLIENT_MIGRATION_NOT_AVAILABLE' }),
            icon: 'error',
            unclosable: false,
            showCancel: false,
            customDialogContent: OrgMigrationNotAvailableDialog,
        });
    }, [ createDialog, translate ]);

    const handleCancel = useCallback(async () => {
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_CANCEL_REQUEST' }),
            body: translate({ id: 'CLIENT_CANCEL_MIGRATION' }),
            icon: 'warning',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
        });
        if (proceed) {
            navigate(generatePath(RouteNames.OrganizationSettingsAdvanced, { accountName }));
        }
    }, [ accountName, createDialog, navigate, translate ]);

    const onAdvance = useCallback(() => {
        if (activeStep === 0) {
            const newRegion = getValues('destinationRegion');
            if (newRegion === Region.None) {
                setError('destinationRegion', { type: 'required' });
                return;
            }
            logEvent(OrgSelfServeMigrationEvent.SelectNewRegion, {
                ServerRegion: currentRegion,
                TargetServerRegion: newRegion,
            });
        } else if (activeStep === 1) {
            const details = { PreferredWindow: getValues('customHour') || getValues('hour') };
            logEvent(OrgSelfServeMigrationEvent.SelectMigrationWindow, details);
        }
        moveStepper(true);
    }, [ activeStep, currentRegion, moveStepper, logEvent, setError, getValues ],
    );

    const onStartMigration = useCallback(async () => {
        setLoading(true);
        try {
            const hour = getValues('customHour') || getValues('hour');

            const scheduleStartDate = getDateFromNow(MigrationStartWindowDays, true).toISOString();
            const scheduleEndDate = getDateFromNow(MigrationEndWindowDays, true).toISOString();

            const request: IMigrationRequest = {
                sourceRegion: getValues('sourceRegion'),
                destinationRegion: getValues('destinationRegion'),
                timeOfDayStartIntervalTicks: parseInt(hour.split(' - ')[0]) * 60 * 60 * 1e7,
                timeOfDayEndIntervalTicks: parseInt(hour.split(' - ')[1]) * 60 * 60 * 1e7 || 24 * 60 * 60 * 1e7, // 00 -> 24
                scheduleStartDate,
                scheduleEndDate,
            };
            logEvent(OrgSelfServeMigrationEvent.RequestMigration, {
                ServerRegion: getValues('sourceRegion'),
                TargetServerRegion: getValues('destinationRegion'),
                PreferredWindow: hour,
            });

            await scheduleOrganizationMove(request);
            navigate(getRoute(RouteNames.OrganizationSettingsAdvanced));
            setLoading(false);
        } catch (error) {
            await handleFailure();
            setLoading(false);
        }
    }, [ getRoute, getValues, handleFailure, logEvent, navigate ]);

    return (
        <UiPageContainer
            tenantShellHidden
            position="center"
            maxWidth="1200px"
            header={
                <UiText
                    className={classes.header}
                    variant={FontVariantToken.fontSizeH4Bold}>
                    {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_TITLE' })}
                </UiText>
            }>

            <div className={classes.mainDisplay}>
                <div className={classes.stepper}>
                    <ApStepper
                        activeStep={activeStep}
                        vertical
                        data-cy="org-migration-stepper">
                        {steps.map((step, index) => (
                            <ApStepperStep
                                label={translate({ id: step.label })}
                                key={index} />
                        ))}
                    </ApStepper>
                </div>

                <div className={classes.standardLayout}>
                    <FormProvider {...methods}>
                        {steps.filter((_, index) => index === activeStep)
                            .map(step => {
                                if (!initialStepDone) {
                                    return (
                                        <div
                                            key={activeStep}
                                            className={classes.content}>
                                            <step.component />
                                        </div>
                                    );
                                }
                                return (
                                    <Fade
                                        in
                                        timeout={300}
                                        key={activeStep}>
                                        <div className={classes.content}>
                                            <step.component />
                                        </div>
                                    </Fade>
                                );
                            })}
                    </FormProvider>
                </div>
            </div>

            <UiStack
                gap={SpacingToken.XS}
                pr={SpacingToken.M}
                className={classes.footer}>
                <ApButton
                    variant='tertiary'
                    onClick={handleCancel}
                    data-cy="org-migration-cancel-button"
                    label={translate({ id: 'CLIENT_CANCEL' })} />
                {activeStep > 0 && (
                    <ApButton
                        variant='secondary'
                        onClick={() => moveStepper(false)}
                        disabled={loading}
                        className={classes.button}
                        label={translate({ id: 'CLIENT_PREVIOUS' })}
                        data-cy="org-migration-back-button" />
                )}
                {activeStep < (steps.length - 1) && (
                    <ApButton
                        variant="primary"
                        onClick={onAdvance}
                        className={classes.button}
                        label={translate({ id: 'CLIENT_NEXT' })}
                        data-cy="org-migration-next-button" />
                )}
                {activeStep === (steps.length - 1) && (
                    <ApButton
                        variant="primary"
                        onClick={onStartMigration}
                        className={classes.button}
                        disabled={!isValid}
                        loading={loading}
                        label={translate({ id: 'CLIENT_SCHEDULE_MIGRATION' })}
                        data-cy="org-migration-start-button" />
                )}
            </UiStack>
        </UiPageContainer>
    );
};

export default OrgMigrationPage;
