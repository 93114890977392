import { AccountLicense } from '@experiences/constants';
import type {
    IConsumptionPoolUsage,
    ITabEntitlementUsage,
    ITabProductAllocation,
} from '@experiences/interfaces';
import {
    BorderLinearProgress,
    UiText,
} from '@experiences/ui-common';
import { getShortNumber } from '@experiences/util';
import WarningIcon from '@mui/icons-material/Warning';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { hasSapClientDescriptionOverride } from './subcomponents/LicensingHelpers';

const useStyles = makeStyles(theme =>
    createStyles({
        container: { margin: '-12px' },
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: '12px',
            height: '100%',
        },
        poolContent: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '12px',
            paddingTop: '12px',
            height: '100%',
            justifyContent: 'space-between',
        },
        titleForBar: { fontSize: '16px' },
        progressBar: { flex: '1 0 75%' },
        textForBar: {
            fontSize: '16px',
            verticalAlign: 'bottom',
            display: 'flex',
            alignItems: 'center',
        },
        barAndText: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        description: { color: theme.palette.semantic.colorForegroundDeEmp },
        item: { padding: '12px 0 !important' },
        yellowIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            marginLeft: '8px',
            fontSize: '24px',
        },
        licenseContent: {
            textAlign: 'center',
            whiteSpace: 'nowrap',
            flex: '1 0 25%',
            marginLeft: '17px',
        },
    }),
);

export const HomePageLicenseAllocations: React.FC<{
    products: ITabProductAllocation[];
    entitlementUsages: ITabEntitlementUsage[];
}> = ({
    products, entitlementUsages,
}) => {
    const isSapClientDescriptionOverride = useCallback((productCode: string): boolean => hasSapClientDescriptionOverride(productCode), []);

    const classes = useStyles();
    const {
        formatNumber, formatMessage: translate,
    } = useIntl();

    const isOverallocated = useCallback((product: ITabProductAllocation): boolean => product.allocated > product.total, []);
    const isPoolOverallocated =
    useCallback((poolUsage: IConsumptionPoolUsage): boolean => poolUsage.consumedQuantity > poolUsage.totalQuantity, []);

    return (
        <Grid
            container
            spacing={3}
            className={classes.container}>
            {products.map(product => (
                <Grid
                    key={product.code}
                    item
                    lg={6}
                    xs={12}
                    classes={{ item: classes.item }}
                    data-cy="licenses-grid-item">
                    <div className={classes.content}>
                        <UiText
                            className={classes.titleForBar}
                            data-cy="usage-chart-headers">
                            {translate({ id: `CLIENT_USAGE_HEADER_${product.code}` })}
                        </UiText>
                        <div className={classes.barAndText}>
                            <div
                                className={classes.progressBar}
                                data-cy="usage-chart-linear-progress-bar">
                                {!product.hideProgress && <BorderLinearProgress
                                    consumed={product.allocated}
                                    total={product.total}
                                    ariaDescribedBy={isOverallocated(product) ? `usage-chart-over-allocation-${product.code.toLowerCase()}` : undefined} />}
                                <UiText className={classes.description}>
                                    {product.code === 'CV' &&
                    translate({
                        id:
                        product.subscriptionPlan && AccountLicense[product.subscriptionPlan] === AccountLicense.COMMUNITY
                            ? `CLIENT_USAGE_DESCRIPTION_COMMUNITY_${product.code}`
                            : (isSapClientDescriptionOverride(product.code)
                                ? `CLIENT_USAGE_DESCRIPTION_SAP_${product.code}`
                                : `CLIENT_USAGE_DESCRIPTION_${product.code}`),
                    })}
                                </UiText>
                            </div>
                            <div className={classes.licenseContent}>
                                <UiText
                                    className={classes.textForBar}
                                    data-cy="usage-chart-allocated-of-total">
                                    <span>
                                        {product.showOnlyTotal
                                            ? getShortNumber(product.total)
                                            : translate(
                                                { id: 'CLIENT_ALLOCATED_OF_TOTAL' },
                                                {
                                                    0: formatNumber(product.allocated),
                                                    1: getShortNumber(product.total),
                                                },
                                            )}
                                    </span>
                                    {isOverallocated(product) && (
                                        <Tooltip
                                            title={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                            aria-label={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                        >
                                            <WarningIcon
                                                className={classes.yellowIcon}
                                                id={`usage-chart-over-allocation-${product.code.toLowerCase()}`}
                                                data-cy="usage-chart-over-allocation"
                                                tabIndex={0}
                                            />
                                        </Tooltip>
                                    )}
                                </UiText>
                            </div>
                        </div>
                    </div>
                </Grid>
            ))}
            {entitlementUsages.length > 0 && entitlementUsages.map(entitlementUsage => (
                <Grid
                    key={entitlementUsage.entitlement}
                    item
                    lg={6}
                    xs={12}
                    classes={{ item: classes.item }}
                    data-cy="licenses-grid-item">
                    <div
                        className={classes.content}
                        data-cy={`entitlement-usage-${entitlementUsage.entitlement.toLowerCase()}`}>
                        <UiText
                            className={classes.titleForBar}
                            data-cy="entitlementType">
                            {translate({ id: `CLIENT_USAGE_HEADER_${entitlementUsage.entitlement.toUpperCase()}` })}
                        </UiText>
                        {entitlementUsage.poolSummary.length > 0 && entitlementUsage.poolSummary.map(poolUsage => (
                            <div
                                key={`consumption-pool-usage-${poolUsage.poolName.toLowerCase()}`}
                                className={classes.poolContent}>
                                <div
                                    className={classes.titleForBar}
                                    data-cy={`consumption-pool-usage-name-${poolUsage.poolName.toLowerCase()}`}>
                                    <UiText
                                        className={classes.description}
                                        data-cy="consumptionPoolName">
                                        {translate({ id: `CLIENT_USAGE_HEADER_${poolUsage.poolName.toUpperCase().replace('.', '_')}` })}
                                    </UiText>
                                </div>
                                <div
                                    data-cy="pool-quantity"
                                    className={classes.barAndText}>
                                    <div
                                        className={classes.progressBar}
                                        data-cy="usage-chart-pool-linear-progress-bar">

                                        <BorderLinearProgress
                                            consumed={poolUsage.consumedQuantity}
                                            total={poolUsage.totalQuantity}
                                            ariaDescribedBy={isPoolOverallocated(poolUsage) ? `usage-chart-over-allocation-${poolUsage.poolName.toLowerCase()}` : undefined} />
                                    </div>
                                    <div className={classes.licenseContent}>
                                        <UiText
                                            className={classes.textForBar}
                                            data-cy={`consumption-pool-allocation-${poolUsage.poolName.toLowerCase()}-total`}>
                                            {isPoolOverallocated(poolUsage) && (
                                                <Tooltip
                                                    title={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                                    aria-label={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                                >
                                                    <WarningIcon
                                                        className={classes.yellowIcon}
                                                        id={`usage-chart-over-allocation-${poolUsage.poolName.toLowerCase()}`}
                                                        data-cy="usage-chart-over-allocation" />
                                                </Tooltip>
                                            )}
                                            <span>
                                                {translate(
                                                    { id: 'CLIENT_ALLOCATED_OF_TOTAL' },
                                                    {
                                                        0: getShortNumber(poolUsage.consumedQuantity),
                                                        1: getShortNumber(poolUsage.totalQuantity),
                                                    })}
                                            </span>
                                        </UiText>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};
