import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import useMarketoUpdateOnDownload from '../../../common/hooks/useMarketoUpdateOnDownload';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IDownloadItem } from '../../../common/interfaces/resource';
import { userGlobalId } from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        studioVersionItem: {
            justifyContent: 'space-between',
            minHeight: 'auto',
            height: '35px',
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorHover} !important` },
        },
        titleRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px 0',
        },
        versionHeader: {
            width: '110px',
            overflow: 'visible',
            fontWeight: 600,
        },
        binaryTypeHeader: {
            width: '82px',
            overflow: 'visible',
            fontWeight: 600,
        },
        iconsContent: {
            height: '23px',
            display: 'flex',
            flexDirection: 'row',
        },
        versionIcon: { marginRight: '10px' },
        copyVersionIcon: {
            cursor: 'pointer',
            paddingTop: '2px',
        },
        multiple: {
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
        },
        versionText: {
            width: '120px',
            overflow: 'visible',
        },
        phantomDiv: { width: '50px' },
        versionSize: {
            width: '83px',
            display: 'flex',
            flexDirection: 'row',
        },
        sizeItem: { marginRight: '5px' },
    }),
}));

type Action = 'download' | 'copy';

const ResourceCenterCMSSupportedVersions: React.FC<{
    downloadList?: Array<IDownloadItem<string>>;
}> = ({ downloadList }) => {
    const classes = useStyles();
    const userId = useSelector(userGlobalId);
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const onStudioDownload = useMarketoUpdateOnDownload();

    const getHrefs = (downloadLink: string[], binaryType: string[]) => {
        const hrefs: { [key: string]: string } = {};
        binaryType.forEach((v, i) => {
            hrefs[v] = downloadLink[i];
        });
        return hrefs;
    };

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
    const [ downloadHrefs, setDownloadHrefs ] = useState<object>([] as any);
    const [ downloadListGrouped, setDownloadListGrouped ] = useState<Array<IDownloadItem<string[]>>>([]);
    const [ menuAction, setMenuAction ] = useState<Action | null>(null);

    const handleDownloadMenu = useCallback((event: React.MouseEvent<HTMLElement>, hrefs: object, action: Action) => {
        setMenuAction(action);
        setDownloadHrefs(hrefs);
        setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    useEffect(() => {
        if (downloadList && downloadList.length > 0) {
            const sortedDownloadList = groupBy(
                orderBy(downloadList.filter(item => item != null), [ 'versionId', 'binaryType' ], [ 'desc', 'asc' ]),
                'versionId',
            );
            const groupedDownloadList = Object.keys(sortedDownloadList).map(versionId => ({
                downloadLink: sortedDownloadList[versionId].map(item => item.downloadLink),
                versionId,
                binaryType: sortedDownloadList[versionId].map(item => item.binaryType),
            }));
            setDownloadListGrouped(groupedDownloadList);
        }
    }, [ downloadList ]);

    return (
        <div>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    horizontal: -100,
                    vertical: 'bottom',
                }}
                disableAutoFocusItem
            >
                {Object.entries(downloadHrefs).map(([ key, href ]) => {
                    switch (menuAction) {
                        case 'download':
                            return (
                                <MenuItem
                                    component={Link}
                                    key={key}
                                    href={href}
                                    onClick={() => {
                                        onStudioDownload(href);
                                        handleCloseMenu();
                                        portalTelemetry.trackTrace({
                                            message: `Resource Center CMS Studio Download: ${userId} downloaded ${href}`,
                                            severityLevel: SeverityLevel.Info,
                                        });
                                    }}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    data-cy={`download-menu-option-${key}`}
                                >
                                    {`${key} ${translate({ id: `CLIENT_BINARY_TYPE_VERSION` })}`}
                                </MenuItem>
                            );
                        case 'copy':
                            return (
                                <MenuItem
                                    key={key}
                                    href={href}
                                    onClick={() => {
                                        createNotification(translate({ id: 'CLIENT_LINK_COPIED' }), notificationType.SUCCESS);
                                        handleCloseMenu();
                                    }}
                                    rel="noopener noreferrer"
                                >
                                    <CopyToClipboard text={href}>
                                        <div>
                                            {`${key} ${translate({ id: `CLIENT_BINARY_TYPE_VERSION` })}`}
                                        </div>
                                    </CopyToClipboard>
                                </MenuItem>
                            );
                        default:
                            break;
                    }
                })}
            </Menu>
            <div className={classes.titleRow}>
                <UiText
                    className={classes.versionHeader}
                    data-cy="download-card-version-header">
                    {translate({ id: 'CLIENT_VERSION' })}
                </UiText>
                <UiText
                    className={classes.binaryTypeHeader}
                    data-cy="download-card-binary-type-header">
                    {translate({ id: 'ClIENT_BINARY_TYPE' })}
                </UiText>
                <div className={classes.phantomDiv} />
            </div>
            {downloadListGrouped.map(({
                downloadLink, versionId, binaryType,
            }, index) => (
                <div key={index}>
                    <MenuItem
                        key={index}
                        className={classes.studioVersionItem}
                        disableGutters
                        data-cy="download-card-other-versions-item"
                    >
                        <UiText
                            className={classes.versionText}
                            data-cy="download-card-other-versions-item-title">
                            {versionId}
                        </UiText>
                        <div className={classes.versionSize}>
                            <UiText
                                key={index}
                                className={classes.sizeItem}
                                data-cy="download-card-other-versions-binary-type-title">
                                {binaryType.join(', ')}
                            </UiText>
                        </div>
                        <div>
                            {downloadLink.length > 1 ? (
                                <div className={classes.iconsContent}>
                                    <Tooltip
                                        arrow
                                        title={translate({ id: 'CLIENT_DOWNLOAD_STUDIO' })}>
                                        <div
                                            className={clsx(classes.versionIcon, classes.icon, classes.multiple)}
                                            onClick={e => {
                                                handleDownloadMenu(e, getHrefs(downloadLink, binaryType), 'download');
                                            }}
                                            data-cy="download-card-other-versions-download-button"
                                        >
                                            <SaveAltIcon />
                                        </div>
                                    </Tooltip>
                                    <Tooltip
                                        arrow
                                        title={translate({ id: 'CLIENT_COPY_STUDIO_TOOLTIP_TEXT' })}>
                                        <div
                                            className={clsx(classes.copyVersionIcon, classes.icon)}
                                            onClick={e => {
                                                handleDownloadMenu(e, getHrefs(downloadLink, binaryType), 'copy');
                                            }}
                                            data-cy="download-card-other-versions-copy-button"
                                        >
                                            <LinkOutlinedIcon />
                                        </div>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div className={classes.iconsContent}>
                                    <Tooltip
                                        arrow
                                        title={translate({ id: 'CLIENT_DOWNLOAD_STUDIO' })}>
                                        <Link
                                            href={downloadLink[0]}
                                            rel="noopener noreferrer"
                                            onClick={() => {
                                                onStudioDownload(downloadLink[0]);
                                                portalTelemetry.trackTrace({
                                                    message: `Resource Center CMS Studio Download: ${userId} downloaded ${downloadLink[0]}`,
                                                    severityLevel: SeverityLevel.Info,
                                                });
                                            }}
                                            className={clsx(classes.versionIcon, classes.icon)}
                                            data-cy="download-card-other-versions-download-button"
                                        >
                                            <SaveAltIcon />
                                        </Link>
                                    </Tooltip>
                                    <Tooltip
                                        arrow
                                        title={translate({ id: 'CLIENT_COPY_STUDIO_TOOLTIP_TEXT' })}>
                                        <div>
                                            <CopyToClipboard text={downloadLink[0]}>
                                                <LinkOutlinedIcon
                                                    classes={{ root: clsx(classes.copyVersionIcon, classes.icon) }}
                                                    onClick={() => createNotification(translate({ id: 'CLIENT_LINK_COPIED' }), notificationType.SUCCESS)}
                                                    data-cy="download-card-other-versions-copy-button"
                                                />
                                            </CopyToClipboard>
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    </MenuItem>
                    {index < downloadListGrouped.length - 1 && <Divider />}
                </div>
            ))}
        </div>
    );
};

export default ResourceCenterCMSSupportedVersions;
