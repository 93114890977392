import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

import { notificationType } from '../../../common/constants/Constant';
import useMarketoUpdateOnDownload from '../../../common/hooks/useMarketoUpdateOnDownload';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IMappedResources } from '../../../common/interfaces/resource';
import { mapOtherVersions } from '../ResourceUtil';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        studioVersionItem: {
            justifyContent: 'space-between',
            minHeight: 'auto',
            height: '35px',
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorHover} !important` },
            display: 'flex',
            textAlign: 'left',
            alignItems: 'center',
            position: 'relative',
            textDecoration: 'none',
            color: theme.palette.semantic.colorForegroundDeEmp,
            paddingTop: '6px',
            whiteSpace: 'nowrap',
            paddingBottom: '6px',
            lineHeight: Tokens.FontFamily.FontMLineHeight,
            boxSizing: 'border-box',
            overflow: 'hidden',
        },
        titleRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px 0',
        },
        versionHeader: {
            width: '100px',
            overflow: 'visible',
            fontWeight: 600,
        },
        windowsHeader: {
            width: '72px',
            overflow: 'visible',
            fontWeight: 600,
        },
        iconsContent: {
            height: '23px',
            display: 'flex',
            flexDirection: 'row',
        },
        versionIcon: { marginRight: '10px' },
        copyVersionIcon: {
            cursor: 'pointer',
            paddingTop: '2px',
        },
        multiple: {
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
        },
        versionText: {
            width: '120px',
            overflow: 'visible',
        },
        phantomDiv: { width: '50px' },
    }),
}));

interface IWindowsHref {
    x64: string;
    x86: string;
}
const SupportedVersionsComponent = ({ state }: { state: IMappedResources }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const onStudioDownload = useMarketoUpdateOnDownload();

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
    const [ downloadHrefs, setDownloadHrefs ] = useState<IWindowsHref>({} as any);

    const handleDownloadMenu = useCallback((event: React.MouseEvent<HTMLElement>, hrefs: IWindowsHref) => {
        setDownloadHrefs(hrefs);
        setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <div>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    horizontal: -100,
                    vertical: 'bottom',
                }}
                disableAutoFocusItem
            >
                {Object.entries(downloadHrefs).map(([ key, href ]) => (
                    <MenuItem
                        component={Link}
                        key={key}
                        href={href}
                        onClick={() => {
                            onStudioDownload(href);
                            handleCloseMenu();
                        }}
                        rel="noopener noreferrer"
                    >
                        {translate({ id: `CLIENT_${key}_VERSION` })}
                    </MenuItem>
                ))}
            </Menu>
            <div className={classes.titleRow}>
                <UiText className={classes.versionHeader}>
                    {translate({ id: 'CLIENT_VERSION' })}
                </UiText>
                <UiText className={classes.windowsHeader}>
                    {translate({ id: 'CLIENT_WINDOWS' })}
                </UiText>
                <div className={classes.phantomDiv} />
            </div>
            {state.supportedVersions
                .map(s => mapOtherVersions(s, state.baseUri, true))
                .map(({
                    x64Href, x86Href, version,
                }, index) => (
                    <>
                        <div
                            key={index}
                            className={classes.studioVersionItem}
                            data-cy="resource-center-other-version-item">
                            <UiText
                                data-cy="resource-center-other-version-item-text"
                                className={classes.versionText}>
                                {`${translate({ id: 'CLIENT_VERSION' })} ${version}`}
                            </UiText>
                            <div style={{ width: '83px' }}>
                                <UiText data-cy="resource-center-windows-text">
                                    {translate({ id: `CLIENT_x86${x64Href ? '_x64' : ''}` })}
                                </UiText>
                            </div>
                            {x86Href && (
                                <div className={classes.iconsContent}>
                                    <Tooltip
                                        arrow
                                        title={translate({ id: 'CLIENT_DOWNLOAD_STUDIO' })}>
                                        {x64Href ? (
                                            <div
                                                className={clsx(classes.versionIcon, classes.icon, classes.multiple)}
                                                onClick={e => handleDownloadMenu(e, {
                                                    x64: x64Href,
                                                    x86: x86Href,
                                                })}
                                                data-cy="resource-center-other-version-item-download"
                                            >
                                                <SaveAltIcon />
                                            </div>
                                        ) : (
                                            <Link
                                                href={x86Href}
                                                rel="noopener noreferrer"
                                                onClick={() => onStudioDownload(x86Href)}
                                                className={clsx(classes.versionIcon, classes.icon)}
                                                data-cy="resource-center-other-version-item-download"
                                            >
                                                <SaveAltIcon />
                                            </Link>
                                        )}
                                    </Tooltip>
                                    <Tooltip
                                        arrow
                                        title={translate({ id: 'CLIENT_COPY_STUDIO_TOOLTIP_TEXT' })}>
                                        <div>
                                            <CopyToClipboard text={x86Href}>
                                                <LinkOutlinedIcon
                                                    classes={{ root: clsx(classes.copyVersionIcon, classes.icon) }}
                                                    onClick={() => createNotification(translate({ id: 'CLIENT_LINK_COPIED' }), notificationType.SUCCESS)}
                                                    data-cy="resource-center-other-version-item-copy"
                                                />
                                            </CopyToClipboard>
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                        {index < state.supportedVersions.length - 1 && <Divider />}
                    </>
                ))}
        </div>
    );
};

export default SupportedVersionsComponent;
