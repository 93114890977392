import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import type { UiRouteObject } from '../container/routeConfigs/UiRouteObject';
import { AdminRoutes } from './admin/AdminRoutes';
import InvalidUrl from './authentication/InvalidUrl';
import { HomeRoutes } from './home/HomeRoutes';

export const PrivateRootRoutes: UiRouteObject = {
    element: <UiSuspensefulOutlet />,
    children: [
        HomeRoutes,
        AdminRoutes,
        {
            path: '*',
            element: <InvalidUrl />,
        },
    ],
};
