import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    UserConsent,
    UserConsentCreate,
    UserConsentEdit,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import CheckGuard from '../../container/helpers/CheckGuard';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import { accountType as accountTypeSelector } from '../../store/selectors';
import { CreateEditOrgConsentPageComponent } from './CreateEditOrgConsentPageComponent';
import OrgConsentPageComponent from './OrgConsentPageComponent';

// TODO: Currently the routes are not using this provider. This is a placeholder for future use.
const OrgConsentProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const EnableOrgConsent = useFeatureFlagValue(Features.EnableAdminOrgConsent.name);

    const organizationName = useOrganizationName();
    const accountType = useSelector(accountTypeSelector);

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminUserConsent,
            name: translate({ id: 'CLIENT_USER_CONSENT' }),
        },
        {
            index: 2,
            link: routePaths.adminUserConsentEdit,
            name: translate({ id: 'CLIENT_USER_CONSENT_EDIT' }),
        },
        {
            index: 2,
            link: routePaths.adminUserConsentCreate,
            name: translate({ id: 'CLIENT_USER_CONSENT_CREATE' }),
        },
    ], [ organizationName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {CheckGuard(
            EnableOrgConsent && AccountLicense[accountType] <= AccountLicense.PRO,
            <>
                {children}
            </>
        )}
    </BreadcrumbProvider>;
};

export const OrgConsentRoutes: UiRouteObject = {
    path: routePaths.adminUserConsent,
    element: <OrgConsentProvider>
        <UiSuspensefulOutlet />
    </OrgConsentProvider>,
    children: [
        {
            index: true,
            path: routePaths.adminUserConsent,
            redirectUrls: [ UserConsent ],
            element: <OrgConsentPageComponent />,
        },
        {
            path: routePaths.adminUserConsentCreate,
            redirectUrls: [ UserConsentCreate ],
            element: <CreateEditOrgConsentPageComponent />,
        },
        {
            path: routePaths.adminUserConsentEdit,
            redirectUrls: [ UserConsentEdit ],
            element: <CreateEditOrgConsentPageComponent />,
        },
    ],
};
