import { AppsIcon } from '@experiences/images';
import { useLocalization } from '@experiences/locales';
import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
    createStyles,
    makeStyles,
    withStyles,
} from '@mui/styles';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import urljoin from 'url-join';

import { hyperLink } from '../../../common/constants/Constant';
import {
    DeliveryType,
    ProductType,
    useDocumentationLinks,
} from '../../../common/hooks/useDocumentationLink';
import type {
    IRuntime,
    IUiPathFileWithName,
} from '../../../common/interfaces/resource';
import { ReactComponent as MobileDownloadIcon } from '../../../images/mobileDownload.svg';
import { ReactComponent as ResourceGuideIcon } from '../../../images/resourceGuide.svg';
import { ReactComponent as RobotProvisionIcon } from '../../../images/robotProvision.svg';
import { ReactComponent as RuntimesIcon } from '../../../images/runtimes.svg';
import VideoGuide from '../VideoGuide';

const Accordion = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
        borderRadius: 0,
        boxShadow: 'none',
        '&:before': { display: 'none' },
        '&$expanded': { margin: 'auto' },
    },
    expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        padding: 'inherit',
        '&$expanded': { minHeight: 56 },
    },
    content: {
        alignItems: 'center',
        '&$expanded': { margin: '12px 0' },
    },
    expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        panelDefault: {
            borderRadius: 0,
            boxShadow: 'none',
            width: '100%',
        },
        sideBarHeader: {
            fontWeight: 600,
            fontSize: '16px',
        },
        divider: {
            width: '90%',
            margin: '0 auto',
        },
        sideBarEntry: {
            fontWeight: 600,
            fontSize: '14px',
        },
        expansionPanelDetails: {
            paddingTop: '0px',
            paddingBottom: '12px',
        },
        listRoot: {
            width: '100%',
            background: 'unset',
            boxShadow: 'unset',
        },
        listItemRoot: { paddingTop: '0px' },
        iconAdjustments: {
            width: '22px',
            height: '22px',
            marginRight: '10px',
        },
    }),
}));

const LinkComponent: React.FC<{ link: string; text: string; version?: string }> = ({
    link, text, version,
}) => {
    const language = useLocalization();
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const languageLink = useMemo(() => link.replace('{0}', language), [ language, link ]);

    return (
        <a
            className={classes.a}
            href={languageLink}
            onClick={() => {
                portalTelemetry.trackTrace({
                    message: `Clicked on ${languageLink}`,
                    severityLevel: SeverityLevel.Info,
                });
            }}
            target="_blank"
            rel="noopener noreferrer"
            data-cy="resouce-right-component-link"
        >
            <UiText color="primary">
                {version ? translate({ id: `CLIENT_${text}` }, { 0: version }) : translate({ id: `CLIENT_${text}` })}
            </UiText>
        </a>
    );
};

const SideBarPanel: React.FC<{
    id: string;
    header: string;
    icon: ReactNode;
    links?: Record<string, string>;
    items?: ReactNode;
}> = ({
    id, header, icon, links, items,
}) => {
    const classes = useStyles();

    const [ expanded, setExpanded ] = React.useState<string | false>('guides-panel');

    const handleChange = useCallback(
        (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        },
        [],
    );

    return (
        <div>
            <Accordion
                expanded={expanded === id}
                onChange={handleChange(id)}
                data-cy="SideBarPanel">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id={id}>
                    {icon}
                    <UiText
                        data-cy="SideBarPanelHeader"
                        className={classes.sideBarEntry}>
                        {header}
                    </UiText>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanelDetails}>
                    <List
                        disablePadding
                        className={classes.listRoot}
                        data-cy="SideBarPanelList">
                        {items ?? null}
                        {links
                            ? Object.entries(links).map(([ key, value ], index) => (
                                <ListItem
                                    key={index}
                                    className={classes.listItemRoot}>
                                    <ListItemText primary={<LinkComponent
                                        link={key}
                                        text={value} />} />
                                </ListItem>
                            ))
                            : null}
                    </List>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

const ResourceCenterSideBarComponent: React.FC<{
    baseUri: string;
    runtimeVersions: IRuntime[];
    enterpriseLatestVersion: string;
    otherProducts: IUiPathFileWithName[];
}> = ({
    baseUri, runtimeVersions, enterpriseLatestVersion, otherProducts,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ openIntroVideo, setOpenIntroVideo ] = React.useState(false);

    const language = useLocalization();
    const getLocalizedLink = useDocumentationLinks();

    const getVideo = () => <VideoGuide closeHandler={() => setOpenIntroVideo(false)} />;

    const guideLinks: Record<string, string> = useMemo(() => ({
        [hyperLink.CLOUD_PLATFORM_GUIDE]: 'CLOUD_PLATFORM_GUIDE',
        [getLocalizedLink({ product: ProductType.ORCHESTRATOR })]: 'TRAINING_ORCHESTRATOR',
        [getLocalizedLink({
            product: ProductType.STUDIO,
            delivery: DeliveryType.STANDALONE,
        })]: 'TRAINING_STUDIO',
        [getLocalizedLink({
            product: ProductType.ROBOT,
            delivery: DeliveryType.STANDALONE,
        })]: 'TRAINING_ROBOT',
        [getLocalizedLink({
            product: ProductType.ACTIVITIES,
            delivery: DeliveryType.OTHER,
            version: 'latest',
        })]: 'ACTIVITIES_GUIDE',
        [getLocalizedLink({ product: ProductType.AI_CENTER })]: 'AI_FABRIC',
        [hyperLink.AUTOMATION_HUB]: 'AUTOMATION_HUB',
        [hyperLink.AUTOMATION_STORE]: 'AUTOMATION_STORE',
        [hyperLink.AUTOMATION_OPS]: 'AUTOMATION_OPS',
        [hyperLink.COMPUTER_VISION]: 'COMPUTER_VISION',
        [hyperLink.UIPATH_APPS]: 'UIPATH_APPS',
        [hyperLink.UIPATH_ACTION_CENTER]: 'UIPATH_ACTION_CENTER',
        [hyperLink.DATA_SERVICE]: 'DATA_SERVICE',
        [hyperLink.UIPATH_REMOTE_RUNTIME_GUIDE]: 'UIPATH_REMOTE_RUNTIME',
        [getLocalizedLink({
            product: ProductType.MARKETPLACE,
            delivery: DeliveryType.AUTOMATION_CLOUD,
        })]: 'INTEGRATION_GUIDE',
        [hyperLink.TASK_CAPTURE]: 'TASK_CAPTURE',
    }), [ getLocalizedLink ]);

    const robotLinks: Record<string, string> = useMemo(() => ({ [hyperLink.AZURE_UIPATH_ROBOT]: 'AZURE_UIPATH_ROBOT' }), []);

    const learningToolsLinks: Record<string, string> = useMemo(() => ({
        [hyperLink.CONNECT]: 'CONNECT',
        [language === 'ja' ? hyperLink.JAPAN_FORUM : hyperLink.FORUM]: 'FORUM_LINK',
        [hyperLink.ACADEMY]: 'ACADEMY',
        [hyperLink.UIPATH_CERTIFICATIONS]: 'UIPATH_CERTIFICATIONS',
    }), [ language ]);

    const mobileDownloadLinks: Record<string, string> = useMemo(() => ({
        [hyperLink.ORCHESTRATOR_MOBILE_ANDROID]: 'ORCHESTRATOR_MOBILE_ANDROID',
        [hyperLink.ORCHESTRATOR_MOBILE_IOS]: 'ORCHESTRATOR_MOBILE_IOS',
    }), []);

    return (
        <div style={{ width: '100%' }}>
            {openIntroVideo ? getVideo() : null}
            <UiText className={classes.sideBarHeader}>
                {translate({ id: 'CLIENT_DOCUMENTATION_LINK' })}
            </UiText>
            <SideBarPanel
                id="guides-panel"
                header={translate({ id: 'CLIENT_GUIDES' })}
                icon={<ResourceGuideIcon className={clsx(classes.iconAdjustments, classes.svgIcon, classes.icon)} />}
                links={guideLinks}
                items={
                    <ListItem>
                        <a
                            className={classes.a}
                            href="#">
                            <ListItemText
                                primary={translate({ id: 'CLIENT_INTRODUCTION' })}
                                onClick={() => setOpenIntroVideo(true)}
                                primaryTypographyProps={{ color: 'primary' }}
                            />
                        </a>
                    </ListItem>
                }
            />
            <Divider className={classes.divider} />
            <SideBarPanel
                id="robot-provisioning-panel"
                header={translate({ id: 'CLIENT_ROBOT_PROVISION' })}
                icon={<RobotProvisionIcon className={clsx(classes.iconAdjustments, classes.svgIcon, classes.icon)} />}
                links={robotLinks}
            />
            <Divider className={classes.divider} />
            <SideBarPanel
                id="apps-panel"
                header={translate({ id: 'CLIENT_LEARNING_TOOLS' })}
                icon={<AppsIcon className={clsx(classes.iconAdjustments, classes.svgIcon, classes.icon)} />}
                links={learningToolsLinks}
            />
            <Divider className={classes.divider} />
            <SideBarPanel
                id="mobile-downloads-panel"
                header={translate({ id: 'CLIENT_MOBILE_DOWNLOADS' })}
                icon={<MobileDownloadIcon className={clsx(classes.iconAdjustments, classes.svgIcon, classes.icon)} />}
                links={mobileDownloadLinks}
            />
            <Divider className={classes.divider} />
            <SideBarPanel
                id="remote-runtime-panel"
                header={translate({ id: 'CLIENT_RUNTIMES' })}
                icon={<RuntimesIcon className={clsx(classes.iconAdjustments, classes.svgIcon)} />}
                items={runtimeVersions.map((runtime, index) => (
                    <ListItem key={index}>
                        <ListItemText
                            style={{ paddingTop: '0px' }}
                            primary={
                                <LinkComponent
                                    link={urljoin(baseUri, runtime.location, runtime.name)}
                                    text={runtime.resourceText}
                                    version={runtime.version}
                                />
                            }
                            secondary={translate(
                                { id: `CLIENT_COMPATIBLE_VERSION_MESSAGE` },
                                { 0: runtime.studioVersion || enterpriseLatestVersion },
                            )}
                        />
                    </ListItem>
                ))}
            />
            <Divider className={classes.divider} />
            <SideBarPanel
                id="other-products-panel"
                header={translate({ id: 'CLIENT_OTHER_PRODUCTS' })}
                icon={<DevicesOtherIcon className={clsx(classes.iconAdjustments, classes.icon)} />}
                items={otherProducts.map(
                    (file, index) =>
                        file.simpleName && (
                            <ListItem key={index}>
                                <ListItemText
                                    style={{ paddingTop: '0px' }}
                                    primary={
                                        <LinkComponent
                                            link={urljoin(
                                                baseUri,
                                                file['download-location'] === '/' ? '' : file['download-location'],
                                                file.name,
                                            )}
                                            text={file.simpleName}
                                            version={file.version}
                                        />
                                    }
                                />
                            </ListItem>
                        ),
                )}
            />
        </div>
    );
};

export default ResourceCenterSideBarComponent;
