import { AccountLicense } from '@experiences/constants';
import type {
    IConsumptionPoolUsage,
    ITabEntitlementUsage,
    ITabProductAllocation,
} from '@experiences/interfaces';
import {
    BorderLinearProgress,
    UiText,
} from '@experiences/ui-common';
import {
    getShortNumber,
    useShowDialog,
} from '@experiences/util';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSWRConfig } from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import { generateMLServiceKey } from '../../services/licensing/LicenseService';
import { licenseManagementAccountUrl } from '../../services/licensing/management/AccountService';
import { isAdminSelector } from '../../store/selectors';
import { hasSapClientDescriptionOverride } from './subcomponents/LicensingHelpers';

const useStyles = makeStyles(theme =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: '12px',
            height: '100%',
            justifyContent: 'space-between',
        },
        textContent: {
            display: 'flex',
            minHeight: '60px',
            justifyContent: 'space-between',
        },
        entry: {
            flex: 1,
            minWidth: 0,
        },
        header: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
        description: {
            display: 'flex',
            justifyContent: 'space-between',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        quantity: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        iconButton: { color: theme.palette.semantic.colorPrimary },
        apiKeyWithAction: {
            display: 'flex',
            marginTop: '8px',
        },
        apiKey: {
            display: 'flex',
            marginTop: '8px',
            overflow: 'hidden',
        },
        apiKeyText: { whiteSpace: 'nowrap' },
        apiKeyLine: {
            textOverflow: 'ellipsis',
            maxWidth: '480px',
        },
        productQuantityAndApiKeys: {
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
        },
        apiKeyActions: {
            marginLeft: '5px',
            display: 'flex',

            alignSelf: 'flex-end',
        },
        yellowIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            marginRight: '4px',
            height: '20px',
        },
        poolContent: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '12px',
            paddingTop: '12px',
            height: '100%',
            justifyContent: 'center',
        },
        poolTextContent: {
            display: 'flex',
            margin: '1px',
            alignItems: 'center',
            minHeight: '20px',
            justifyContent: 'space-between',
        },
        infoIcon: {
            color: theme.palette.semantic.colorInfoForeground,
            paddingLeft: '3px',
            margin: '1px',
        },
        noWrap: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
);

export const LicensingProductAllocations: React.FC<{
    products: ITabProductAllocation[];
    entitlementUsages: ITabEntitlementUsage[];
    loading: boolean;
}> = ({
    products, entitlementUsages, loading,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const createDialog = useShowDialog();
    const isAdmin = useSelector(isAdminSelector);
    const isSapClientDescriptionOverride = useCallback((productCode: string): boolean => hasSapClientDescriptionOverride(productCode), []);

    const [ keyToGenerate, setKeyToGenerate ] = useState<string | undefined>(undefined);

    const { mutate } = useSWRConfig();

    const generateNewMlKey = useCallback(async (keyType: string) => {
        setKeyToGenerate(keyType);
        await generateMLServiceKey(keyType);
        await mutate(`${licenseManagementAccountUrl}/available`);
    }, [ mutate ]);

    const showConfirmationDialog = useCallback(
        async (keyType?: string) => {
            if (!keyType) {
                return;
            }

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_CONFIRM_GENERATION_REQUEST' }),
                body: translate({ id: 'CLIENT_CONFIRM_GENERATION_TEXT' }),
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_GENERATE' }),
            });
            if (proceed) {
                await generateNewMlKey(keyType);
            }
        },
        [ createDialog, generateNewMlKey, translate ],
    );

    const isOverallocated = useCallback((product: ITabProductAllocation): boolean => product.allocated > product.total, []);
    const isPoolOverallocated =
    useCallback((poolUsage: IConsumptionPoolUsage): boolean => poolUsage.consumedQuantity > poolUsage.totalQuantity, []);
    return (
        <Grid
            container
            spacing={3}>
            {products.map(product => (
                <Grid
                    key={product.code}
                    item
                    xs={6}
                    data-cy="licenses-grid-item">
                    <div
                        tabIndex={0}
                        className={classes.content}
                        data-cy={`product-allocation-${product.code.toLowerCase()}`}>
                        <div className={classes.textContent}>
                            <div className={classes.entry}>
                                <UiText
                                    className={classes.header}
                                    data-cy="licenseType">
                                    {translate({ id: `CLIENT_USAGE_HEADER_${product.code}` })}
                                </UiText>
                                <UiText
                                    className={classes.description}
                                    data-cy={`license-description-${product.code.toLowerCase()}`}>
                                    {translate({
                                        id:
                                        product.subscriptionPlan && AccountLicense[product.subscriptionPlan] === AccountLicense.COMMUNITY
                                            ? `CLIENT_USAGE_DESCRIPTION_COMMUNITY_${product.code}`
                                            : (isSapClientDescriptionOverride(product.code)
                                                ? `CLIENT_USAGE_DESCRIPTION_SAP_${product.code}`
                                                : `CLIENT_USAGE_DESCRIPTION_${product.code}`),
                                    })}
                                </UiText>
                                {product.licenseType && (
                                    <div className={classes.apiKeyWithAction}>
                                        <div className={classes.apiKey}>
                                            <UiText
                                                className={classes.apiKeyText}
                                                data-cy={`licenses-api-key-label-${product.code.toLowerCase()}`}>
                                                {translate({ id: 'CLIENT_API_KEY' })}
                                                &nbsp;
                                            </UiText>
                                            {loading && product.licenseType === keyToGenerate ? (
                                                <CircularProgress
                                                    size={20}
                                                    style={{ marginLeft: '8px' }} />
                                            ) : (
                                                <Tooltip
                                                    arrow
                                                    title={product.mlServiceKey ?? ''}>
                                                    <UiText
                                                        className={clsx({
                                                            [classes.apiKeyLine]: true,
                                                            [classes.noWrap]: true,
                                                        })}
                                                        data-cy={`licenses-other-api-key-${product.code.toLowerCase()}`}
                                                    >
                                                        {product.mlServiceKey ?? translate({ id: 'CLIENT_NOT_GENERATED_YET' })}
                                                    </UiText>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <div className={classes.apiKeyActions}>
                                            {product.mlServiceKey && (
                                                <CopyToClipboard text={product.mlServiceKey}>
                                                    <Tooltip
                                                        title={translate({ id: 'CLIENT_COPY_API_KEY' })}
                                                        placement="top">
                                                        <IconButton>
                                                            <FileCopyOutlinedIcon
                                                                onClick={() => createNotification(translate({ id: 'CLIENT_COPIED_TO_CLIPBOARD' }), notificationType.SUCCESS)}
                                                                data-cy={`licenses-copy-key-${product.code.toLowerCase()}`}
                                                                color="primary"
                                                                fontSize="small"
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </CopyToClipboard>
                                            )}
                                            {isAdmin && (
                                                <>
                                                    <Tooltip
                                                        title={translate({ id: 'CLIENT_GENERATE_NEW' })}
                                                        placement="top">
                                                        <IconButton
                                                            className={classes.iconButton}
                                                            style={{ marginLeft: product.mlServiceKey ? '12px' : '0' }}
                                                            onClick={() => showConfirmationDialog(product.licenseType)}
                                                            data-cy={`licenses-generate-key-${product.code.toLowerCase()}`}
                                                        >
                                                            <AutorenewIcon
                                                                color="primary"
                                                                fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                data-cy="licenseNumber"
                                className={classes.productQuantityAndApiKeys}>
                                {!product.hideQuantity && (
                                    <UiText
                                        className={classes.quantity}
                                        data-cy={`product-allocation-${product.code.toLowerCase()}-total`}>
                                        {isOverallocated(product) && (
                                            <Tooltip
                                                title={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                                aria-label={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                            >
                                                <WarningIcon
                                                    className={classes.yellowIcon}
                                                    id={`usage-chart-over-allocation-${product.code.toLowerCase()}`}
                                                    data-cy="usage-chart-over-allocation"
                                                    tabIndex={0}
                                                />
                                            </Tooltip>
                                        )}
                                        <span>
                                            {getShortNumber(product.total)}
                                        </span>
                                    </UiText>
                                )}
                            </div>
                        </div>
                        {!product.hideProgress && <BorderLinearProgress
                            consumed={product.allocated}
                            total={product.total}
                            ariaDescribedBy={isOverallocated(product) ? `usage-chart-over-allocation-${product.code.toLowerCase()}` : undefined} />}
                    </div>
                </Grid>
            ))}
            {entitlementUsages.length > 0 && entitlementUsages.map(entitlementUsage => (
                <Grid
                    key={entitlementUsage.entitlement}
                    item
                    xs={6}
                    data-cy="licenses-grid-item">
                    <div
                        className={classes.content}
                        data-cy={`entitlement-usage-${entitlementUsage.entitlement.toLowerCase()}`}>
                        <UiText
                            className={classes.header}
                            data-cy="entitlementType">
                            {translate({ id: `CLIENT_USAGE_HEADER_${entitlementUsage.entitlement.toUpperCase()}` })}
                        </UiText>
                        {entitlementUsage.poolSummary.length > 0 && entitlementUsage.poolSummary.map(poolUsage => (
                            <div
                                key={`consumption-pool-usage-${poolUsage.poolName.toLowerCase()}`}
                                className={classes.poolContent}>
                                <div
                                    className={classes.poolTextContent}
                                    data-cy={`consumption-pool-usage-name-${poolUsage.poolName.toLowerCase()}`}>
                                    <div className={classes.entry}>
                                        <UiText
                                            className={classes.description}
                                            data-cy="consumptionPoolName">
                                            <div className={classes.poolTextContent}>
                                                {translate({ id: `CLIENT_USAGE_HEADER_${poolUsage.poolName.toUpperCase().replace('.', '_')}` })}
                                                {poolUsage.isBundled && (<Tooltip
                                                    data-cy={`consumption-pool-info-tooltip-${poolUsage.poolName.toLowerCase()}`}
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    title={translate(
                                                        { id: `CLIENT_USAGE_HEADER_${poolUsage.poolName.toUpperCase().replace('.', '_')}_DETAILS` },
                                                    )}
                                                    arrow
                                                >
                                                    <InfoIcon className={classes.infoIcon} />
                                                </Tooltip>)}
                                            </div>
                                        </UiText>
                                    </div>
                                    <div
                                        data-cy="licenseNumber"
                                        className={classes.productQuantityAndApiKeys}>
                                        <UiText
                                            className={classes.quantity}
                                            data-cy={`consumption-pool-allocation-${poolUsage.poolName.toLowerCase()}-total`}>
                                            {isPoolOverallocated(poolUsage) && (
                                                <Tooltip
                                                    title={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                                    aria-label={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                                >
                                                    <WarningIcon
                                                        className={classes.yellowIcon}
                                                        id={`usage-chart-over-allocation-${poolUsage.poolName.toLowerCase()}`}
                                                        data-cy="usage-chart-over-allocation"
                                                    />
                                                </Tooltip>
                                            )}
                                            <span>
                                                {translate(
                                                    { id: 'CLIENT_ALLOCATED_OF_TOTAL' },
                                                    {
                                                        0: getShortNumber(poolUsage.consumedQuantity),
                                                        1: getShortNumber(poolUsage.totalQuantity),
                                                    })}
                                            </span>
                                        </UiText>
                                    </div>
                                </div>
                                <BorderLinearProgress
                                    consumed={poolUsage.consumedQuantity}
                                    total={poolUsage.totalQuantity}
                                    ariaDescribedBy={isPoolOverallocated(poolUsage) ? `usage-chart-over-allocation-${poolUsage.poolName.toLowerCase()}` : undefined} />
                            </div>
                        ))}
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};
