import { useLocalization } from '@experiences/locales';
import { ResourceCenterEvent } from '@experiences/telemetry';
import isNull from 'lodash/isNull';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { updateMarketoWithDownloadEdition } from '../../services/ResourceCenterService';
import {
    accountGlobalId,
    accountLogicalName,
    profile,
} from '../../store/selectors';
import { StudioDownload } from '../../telemetry';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';

export default function useMarketoUpdateOnDownload() {
    const profileState = useSelector(profile);
    const accountId = useSelector(accountGlobalId);
    const logicalName = useSelector(accountLogicalName);
    const currentAccountLanguage = useLocalization();
    const { logEvent } = useTelemetryHelper();

    const getStudioEditionTypeFromHref = useCallback((href: string) => {
        const normalizedLink = href.replace('-x86', '');

        if (normalizedLink.includes('UiPathStudio.msi') || normalizedLink.includes('UiPathStudioCloud.msi')) {
            return 'Enterprise Cloud';
        } else if (normalizedLink.includes('UiPathStudioCloudTrial.msi')) {
            return 'Enterprise Trial';
        }
        return 'Community Edition';

    }, []);

    const onStudioDownload = useCallback(
        async (href: string) => {
            const editionType = getStudioEditionTypeFromHref(href);

            logEvent(ResourceCenterEvent.FeaturedDownload, {
                studioEdition: editionType,
                location: StudioDownload.ResourceCenter,
                ResourceURL: href,
            });

            if (profileState.accountUserDto?.firstName === 'admin') {
                logEvent(ResourceCenterEvent.MarketoFirstNameAdminCheck, { Edition: editionType });
            }

            if (profileState.accountUserDto?.lastName === 'admin') {
                logEvent(ResourceCenterEvent.MarketoLastNameAdminCheck, { Edition: editionType });
            }

            if (isNull(profileState.accountUserDto?.firstName) ||
                isNull(profileState.accountUserDto?.lastName) ||
                isNull(profileState?.emailId)) {
                logEvent(ResourceCenterEvent.MarketoEmptyNameCheck, {
                    IsFirstNameEmpty: isNull(profileState.accountUserDto?.firstName),
                    IsLastNameEmpty: isNull(profileState.accountUserDto?.lastName),
                    IsEmailIdEmpty: isNull(profileState?.emailId),
                    Edition: editionType,
                });
            }

            const payload = {
                userId: profileState.userGlobalId,
                firstName: profileState.accountUserDto?.firstName,
                lastName: profileState.accountUserDto?.lastName,
                email: profileState?.emailId,
                userLanguage: currentAccountLanguage,
                country: profileState.accountUserDto?.country,
                accountId,
                accountUrl: `${window.location.origin}/${logicalName}`,
                organizationName: profileState.accountUserDto?.companyName,
                edition: editionType,
            };

            await updateMarketoWithDownloadEdition(payload);
        },
        [
            getStudioEditionTypeFromHref,
            logEvent,
            profileState,
            currentAccountLanguage,
            accountId,
            logicalName,
        ],
    );

    return onStudioDownload;
}
