import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { TenantHome } from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import { useTenantIdContext } from '../../common/providers/TenantIdProvider';
import CheckGuard from '../../container/helpers/CheckGuard';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import { CheckAccessProvider } from './checkAccess/CheckAccessContextProvider';

const ManageAccessPageComponent = React.lazy(() => import('./ManageAccessPageComponent'));
const CheckAccessPageComponent = React.lazy(() => import('./checkAccess/CheckAccessPageComponent'));

const FeatureFlagGuardComponent: React.FC = () => {
    const EnableCheckAccess = useFeatureFlagValue(Features.EnableCheckAccess.name);

    return CheckGuard(EnableCheckAccess, <CheckAccessProvider>
        <CheckAccessPageComponent />
    </CheckAccessProvider>);
};

const ManageAccessProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const EnableTenantAdminControl = useFeatureFlagValue(Features.EnableTenantAdminControl.name);

    const organizationName = useOrganizationName();
    const { tenantName } = useTenantIdContext();

    const breadcrumbs = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminTenantHome,
            name: tenantName,
        },
        {
            index: 2,
            link: routePaths.adminTenantManageAccess,
            name: translate({ id: 'CLIENT_MANAGE_ACCESS' }),
        },
        {
            index: 3,
            link: routePaths.adminTenantManageAccessCheckAccess,
            name: translate({ id: 'CLIENT_CHECK_ACCESS' }),
        },
    ], [ organizationName, tenantName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadcrumbs}>
        {CheckGuard(EnableTenantAdminControl, <>
            {children}
        </>)}
    </BreadcrumbProvider>;
};

export const ManageAccessRoutes: UiRouteObject = {
    path: routePaths.adminTenantManageAccess,
    element: <ManageAccessProvider>
        <UiSuspensefulOutlet />
    </ManageAccessProvider>,
    children: [
        {
            index: true,
            redirectUrls: [ `${TenantHome}/manageAccess` ],
            element: <ManageAccessPageComponent />,
        },
        {
            path: routePaths.adminTenantManageAccessCheckAccess,
            element: <FeatureFlagGuardComponent />,
        },
    ],
};
