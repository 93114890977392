export interface IUiPathFile {
    type: string;
    name: string;
    version: string;
    'download-location': string;
}

export interface IUiPathFileWithName extends Omit<IUiPathFile, 'version'> {
    simpleName: string;
    version?: string;
}

export interface IUiPathVersions {
    version: string;
    'release-date': string;
    'support-level': string;
    'supported-patch-version': string;
    'end-mainstream-support': string;
    'end-extended-support': string;
    'download-location': string;
}

export interface IResource {
    baseUri: string;
    beta: IUiPathFile[];
    files: IUiPathFile[];
    'supported-versions': IUiPathVersions[];
    uiexplorer: IUiPathFile[];
}

export interface IRuntime {
    name: string;
    version: string;
    type: string;
    resourceText: string;
    studioVersion?: string;
    location: string;
}

export interface IMappedResources {
    baseUri: string;
    supportedVersions: IUiPathVersions[];
    runtimeVersions: IRuntime[];
    betaVersions: IUiPathFile[];
    otherProducts: IUiPathFileWithName[];
    enterpriseFileName: string;
    enterpriseLatestVersion: string;
    communityLatestVersion: string;
}

export enum Download_x64 {
    ENTERPRISE_STUDIO_NAME = 'UiPathStudio.msi',
    ENTERPRISE_CLOUD_STUDIO_NAME = 'UiPathStudioCloud.msi',
    ENTERPRISE_TRIAL_STUDIO_NAME = 'UiPathStudioCloudTrial.msi',
    COMMUNITY_STUDIO_NAME = 'UiPathStudioSetup.exe',
    ENTERPRISE_RUNTIMES = 'UiPathRemoteRuntime.msi',
}

export enum Download_x86 {
    ENTERPRISE_STUDIO_NAME = 'UiPathStudio-x86.msi',
    ENTERPRISE_CLOUD_STUDIO_NAME = 'UiPathStudioCloud-x86.msi',
    ENTERPRISE_TRIAL_STUDIO_NAME = 'UiPathStudioCloudTrial-x86.msi',
}

export interface ICMSDataContainer {
    containerSidebar: ICMSData;
    containerDownload: ICMSData;
    containerFeaturedDownload: ICMSData;
}

interface ICMSData {
    title: string;
    sidebarListCollection: ICMSDataItems<ISideBarCard>;
    downloadListCollection: ICMSDataItems<IDownloadCard>;
    featuredDownloadListCollection: ICMSDataItems<IFeaturedDownloadCard>;
}

interface ICMSDataItems<T> {
    items: T[];
}

export interface ISideBarCard {
    title: string;
    icon: string;
    link: string;
    description: string;
    displayCondition: IDisplayConditionItem;
}

export interface IDownloadCard {
    title: string;
    stableVersion: string;
    previewVersion: string;
    previewDescription: string[];
    stableDescription: string[];
    previewDownloadLink: string;
    stableDownloadLink: string;
    releaseNotes: string;
    displayCondition: IDisplayConditionItem;
    downloadCardListCollection: ICMSDataItems<IDownloadItem<string>>;
    highlighted: boolean;
}

export interface IFeaturedDownloadCard {
    title: string;
    version?: string;
    highlight: boolean;
    link?: string;
    featuredDownloadCardListCollection: ICMSDataItems<IFeaturedDownloadItem>;
    featuredDownloadCardSideListCollection: ICMSDataItems<IFeaturedDownloadItem>;
    displayCondition: IDisplayConditionItem;
}

export interface IDownloadItem<T> {
    versionId: string;
    downloadLink: T;
    binaryType: T;
}

interface IFeaturedDownloadItem {
    title: string;
    link: string;
    description: string;
}

interface IDisplayConditionItem {
    accountTypeList: string[];
}
