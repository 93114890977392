import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import { Home } from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import HomePageComponent from './HomePageComponent';

export const HomeRoutes: UiRouteObject = {
    path: routePaths.home,
    element: <UiSuspensefulOutlet />,
    children: [
        {
            index: true,
            redirectUrls: [ Home ],
            element: <HomePageComponent />,
        },
    ],
};
