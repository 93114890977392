import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { IErrorProps } from './UiErrorSupportContent';
import { UiErrorSupportContent } from './UiErrorSupportContent';
import { useGetErrorInfo } from './useErrorMessage';

function hasErrorCode(data: any, code: string) {
    const isDataArray = data && Array.isArray(data);
    return isDataArray && data.some((e: any) => e.code === code);
}

interface CustomErrorMessage {
    title?: string;
    message?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export function useAppError(flow?: 'invite' | 'signup' | 'login' | 'gov') {
    const { getErrorObject } = useGetErrorInfo();

    const useOnlineLicenseActivation = useFeatureFlagValue(Features.UseOnlineLicenseActivation.name);
    const errorTitleMessage = useOnlineLicenseActivation ? 'CLIENT_INVALID_LICENSE_CODE' : 'CLIENT_INVALID_LICENSE';
    const errorTextMessage = useOnlineLicenseActivation ? 'CLIENT_ACTIVATE_FAILED_GOV' : 'CLIENT_INVALID_LICENSE_MESSAGE';

    const [ renderError, setRenderError ] = useState(false);
    const [ errorProps, setErrorProps ] = useState<(Partial<IErrorProps> & { retry: () => void }) | undefined>(undefined);

    const clearError = useCallback(() => {
        setRenderError(false);
    }, []);

    const setError = useCallback(
        async (error: any, telemetryMessage: string, retry: () => void, customError?: CustomErrorMessage) => {
            const guid = uuidv4();
            const errorObject = await getErrorObject(error);

            if (customError) {
                setErrorProps({
                    retry,
                    guid,
                    title: customError.title,
                    message: customError.message,
                });
            } else if (flow === 'invite') {
                const status = error.response?.status;
                const data = error.response?.data?.errors ?? error.response?.data;

                const isDataString = data && typeof data === 'string';
                const isBadRequest = status && status === 400;

                const isMissingOrganizationError = isBadRequest && hasErrorCode(data, 'InvalidPartition');
                const isInviteNotFoundError = [ 401, 403 ].indexOf(status) > -1 || hasErrorCode(data, 'UserNotFound');
                const isInvalidIDPError = isBadRequest && isDataString && data.includes('Idp is specified to');

                setErrorProps({
                    retry,
                    ...(isInviteNotFoundError
                        ? {
                            title: 'CLIENT_USER_DOES_NOT_EXIST',
                            message: 'CLIENT_INVITE_MISSING_ERROR',
                            shouldLogout: true,
                        }
                        : isInvalidIDPError
                            ? {
                                title: 'CLIENT_INVALID_IDP_ERROR',
                                message: data,
                                shouldLogout: true,
                            }
                            : isMissingOrganizationError
                                ? {
                                    title: 'CLIENT_INVITE_NOT_EXIST_TITLE',
                                    message: 'CLIENT_INVITE_NOT_EXIST_TEXT',
                                    shouldLogout: true,
                                }
                                : { guid }),
                });
            } else if (flow === 'login') {
                setErrorProps({
                    retry,
                    guid,
                    title: 'CLIENT_ACCESS_DENIED_TEXT',
                    message: 'CLIENT_LOGIN_ERROR',
                });
            } else if (flow === 'gov') {
                const isInvalidLicense = error.response?.status === 422;

                setErrorProps({
                    retry,
                    guid,
                    title: isInvalidLicense ? errorTitleMessage : undefined,
                    message: isInvalidLicense ? errorTextMessage : undefined,
                });
            } else {
                setErrorProps({
                    retry,
                    guid,
                });
            }

            portalTelemetry.trackTrace(
                {
                    message: telemetryMessage,
                    severityLevel: SeverityLevel.Major,
                    properties: {
                        code: guid,
                        error: errorObject,
                    },
                },
            );

            setRenderError(true);
        },
        [ errorTextMessage, errorTitleMessage, flow, getErrorObject ],
    );

    const ErrorComponent = useMemo(
        () => <UiErrorSupportContent
            retry={errorProps?.retry ?? noop}
            error={errorProps ?? {}} />,
        [ errorProps ],
    );

    return {
        ErrorComponent,
        renderError,
        clearError,
        setError,
    };
}
