import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import TenantApiAccessGrid from './subcomponents/TenantApiAccessGrid';

const useStyles = makeStyles(theme =>
    createStyles({
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
        },
        mainHeading: { paddingBottom: '20px' },
    }),
);

const PrivacyAndSecuritySettingsComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    return (
        <>
            <UiText
                className={clsx(classes.heading, classes.mainHeading)}
                role="heading"
                aria-level={1}>
                {translate({ id: 'CLIENT_PRIVACY_AND_SECURITY' })}
            </UiText>
            <UiText
                className={clsx(classes.heading, classes.mainHeading)}
                role="heading"
                aria-level={2}>
                {translate({ id: 'CLIENT_ORCHESTRATOR_API_ACCESS' })}
            </UiText>
            <TenantApiAccessGrid />
        </>
    );
};

export default PrivacyAndSecuritySettingsComponent;
