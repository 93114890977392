import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { ThemeEvent } from '@experiences/telemetry';
import { useApolloTheme } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import {
    defaultTheme,
    getApolloThemeFromTheme,
    type ThemeType,
} from '@uipath/portal-shell-util';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useRef,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import useSetUserTheme from '../../../common/hooks/useSetUserTheme.default';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import darkThemeThumbnail from './assets/darkThemeThumbnail.svg';
import highContrastDarkThemeThumbnail from './assets/highContrastDarkThemeThumbnail.svg';
import highContrastLightThemeThumbnail from './assets/highContrastLightThemeThumbnail.svg';
import lightThemeThumbnail from './assets/lightThemeThumbnail.svg';
import ThemeRadioComponent from './ThemeRadioComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        formContainer: { maxWidth: '482px' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: Tokens.FontFamily.FontLSize,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
        },
        mainHeading: { paddingBottom: Tokens.Padding.PadXxl },
        defaultThemesWrapper: {
            display: 'flex',
            gap: Tokens.Padding.PadXxxl,
            alignItems: 'flex-start',
            marginBottom: '36px',
        },
        systemSettingsDescription: { marginLeft: Tokens.Spacing.SpacingXl },
        systemSyncControlLabel: { fontWeight: Tokens.FontFamily.FontWeightSemibold },
        radioGroup: { marginLeft: Tokens.Spacing.SpacingXl },
        section: { paddingBottom: Tokens.Spacing.SpacingM },
        subHeading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: Tokens.FontFamily.FontMSize,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
        },
    }),
);

interface IThemePreferencesData {
    theme: ThemeType;
}

const ProfileThemeSettingsComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { selectedThemeId } = useApolloTheme();
    const {
        control, setValue,
    } = useForm<IThemePreferencesData>({
        mode: 'onChange',
        defaultValues: { theme: selectedThemeId ?? defaultTheme },
    });

    useEffect(() => {
        if (selectedThemeId) {
            setValue('theme', selectedThemeId);
        }
    }, [ selectedThemeId, setValue ]);

    const setUserTheme = useSetUserTheme();
    const prevThemeRef = useRef<ThemeType>();
    const { logEvent } = useTelemetryHelper();

    const highContrastLightThemeFlag = useFeatureFlagValue(Features.EnableHighContrastLightTheme.name);
    const highContrastDarkThemeFlag = useFeatureFlagValue(Features.EnableHighContrastDarkTheme.name);

    const handleThemeSelect = useCallback((newTheme: ThemeType) => {
        if (!highContrastLightThemeFlag && newTheme === 'light-hc') {
            return;
        }

        if (!highContrastDarkThemeFlag && newTheme === 'dark-hc') {
            return;
        }

        logEvent(ThemeEvent.ChangeTheme, {
            ProductThemeOld: prevThemeRef.current,
            ProductThemeNew: newTheme,
        });
        setUserTheme(newTheme);
        prevThemeRef.current = newTheme;
    }, [ highContrastLightThemeFlag, highContrastDarkThemeFlag, logEvent, setUserTheme ]);

    return (
        <Controller
            control={control}
            name="theme"
            rules={{ required: true }}
            render={({ field }) => {
                const disableRadios = field.value === 'autoTheme';
                const theme = disableRadios ? getApolloThemeFromTheme(field.value, highContrastDarkThemeFlag) : field.value;

                return (
                    <Box>
                        <UiText
                            className={clsx(classes.heading, classes.mainHeading)}
                            role="heading"
                            aria-level={1}>
                            {translate({ id: 'CLIENT_THEME' })}
                        </UiText>
                        <UiText>
                            {translate({ id: 'CLIENT_THEME_SETTINGS_DESCRIPTION' })}
                        </UiText>
                        <FormControlLabel
                            control={<Checkbox
                                checked={field.value === 'autoTheme'}
                                color="primary"
                                data-cy="sync-with-system-checkbox"
                                onChange={event => {
                                    const themeToSet = event.target.checked ? 'autoTheme' : theme;
                                    field.onChange(themeToSet);
                                    handleThemeSelect(themeToSet as ThemeType);
                                }}
                            />}
                            label={<UiText className={classes.systemSyncControlLabel}>
                                { translate({ id: 'CLIENT_SYNC_WITH_SYSTEM' })}
                            </UiText>}
                        />
                        <UiText className={classes.systemSettingsDescription}>
                            {translate({ id: highContrastDarkThemeFlag ? 'CLIENT_SYNC_WITH_SYSTEM_DESCRIPTION_HC' : 'CLIENT_SYNC_WITH_SYSTEM_DESCRIPTION' })}
                        </UiText>
                        <br />

                        <RadioGroup
                            data-cy="profile-preferences-theme-radio-buttons"
                            aria-label={translate({ id: 'CLIENT_THEME_CONTROL_DESCRIPTION' })}
                            className={classes.radioGroup}
                            value={field.value}
                            onChange={event => {
                                field.onChange(event.target.value);
                                handleThemeSelect(event.target.value as ThemeType);
                            }}
                        >
                            <Box className={classes.defaultThemesWrapper}>
                                <ThemeRadioComponent
                                    theme="light"
                                    value={theme}
                                    disabled={disableRadios}
                                    imgSrc={lightThemeThumbnail}
                                    label={translate({ id: 'CLIENT_LIGHT_THEME' })} />
                                <ThemeRadioComponent
                                    theme="dark"
                                    value={theme}
                                    disabled={disableRadios}
                                    imgSrc={darkThemeThumbnail}
                                    label={translate({ id: 'CLIENT_DARK_THEME' })} />
                            </Box>
                            <Box className={classes.defaultThemesWrapper}>
                                { highContrastDarkThemeFlag
                                    ? (
                                        <>
                                            <ThemeRadioComponent
                                                theme="light-hc"
                                                value={theme}
                                                disabled={disableRadios}
                                                imgSrc={highContrastLightThemeThumbnail}
                                                label={translate({ id: 'CLIENT_LIGHT_HIGH_CONTRAST_THEME' })} />
                                            <ThemeRadioComponent
                                                theme="dark-hc"
                                                value={theme}
                                                disabled={disableRadios}
                                                imgSrc={highContrastDarkThemeThumbnail}
                                                label={translate({ id: 'CLIENT_DARK_HIGH_CONTRAST_THEME' })} />
                                        </>
                                    ) : (
                                        <ThemeRadioComponent
                                            theme="light-hc"
                                            value={theme}
                                            disabled={disableRadios}
                                            imgSrc={highContrastLightThemeThumbnail}
                                            label={translate({ id: 'CLIENT_LIGHT_HIGH_CONTRAST_THEME' })} />
                                    )}

                            </Box>
                        </RadioGroup>
                    </Box>
                );
            }}
        />

    );
};

export default ProfileThemeSettingsComponent;
