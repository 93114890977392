import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useGetRoutes } from '@experiences/util';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { type RouteObject } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import InvalidUrl from '../../component/authentication/InvalidUrl';
import HomePageComponent from '../../component/home/HomePageComponent';
import UpgradeToEnterpriseDialogComponent from '../../component/licensing/UpgradeToEnterpriseDialogComponent';
import NotificationPageComponent from '../../component/notifications/NotificationPageComponent';
import { AddUserGroupModal } from '../../component/notificationSettings/Admin/AddUserGroupModal';
import { OrgMigrationPage } from '../../component/organizationsettings/subcomponents/OrgMigration/OrgMigrationPage';
import ProfilePageComponent from '../../component/profile/ProfilePageComponent';
import CreatePersonalAccessTokenDrawerComponent from '../../component/profile/subcomponents/CreatePersonalAccessTokenDrawerComponent';
import ResourceCenter from '../../component/resourceCenter/ResourceCenter';
import ResourceCenterCMS from '../../component/resourceCenter/ResourceCenterCMS';
import { PrivateRootRoutes } from '../../component/RootRoutes';
import { isAdminSelector } from '../../store/selectors';
import CheckGuard from '../helpers/CheckGuard';
import {
    flattenRedirectUrls,
    recursiveRouteReplace,
} from './routeHelpers';
import type { UiRouteObject } from './UiRouteObject';
import { useEcommerceRoutes } from './useEcommerceRoutes';

const usePrivateShellRoutes = () => {
    const EnableResourceCenterCMS = !useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableNotificationSettings = useFeatureFlagValue(Features.EnableNotificationSettings.name);
    const EnableProfileNavigationSettings = useFeatureFlagValue(Features.EnableProfileNavigationSettings.name);
    const EnableThemeSettings = useFeatureFlagValue(Features.EnableHighContrastLightTheme.name);
    const EnableAccessibilitySettings = useFeatureFlagValue(Features.EnableAlertDuration.name);
    const EnabledScheduledOrgMigration = useFeatureFlagValue(Features.EnabledScheduledOrgMigration.name);
    const EnablePrivacyAndSecuritySettings = !useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableReferenceTokens = useFeatureFlagValue(Features.EnableReferenceTokens.name);

    const isAdmin = useSelector(isAdminSelector);
    const { isFreeOrCommunityRevamp } = useCheckLicense();
    const ecommerceRoutes = useEcommerceRoutes();

    const { setRoutes } = useGetRoutes();

    useEffect(() => {
        setRoutes([ { ...PrivateRootRoutes } ]);
    }, [ setRoutes ]);

    return useMemo(() => {
        const nestedRoutes = [
            ...(isAdmin ? [ { ...PrivateRootRoutes } ] : []),
            {
                path: RouteNames.Home,
                element: <HomePageComponent />,
            },
            {
                path: RouteNames.ResourceCenter,
                element: EnableResourceCenterCMS ? <ResourceCenterCMS /> : <ResourceCenter />,
                children: [
                    {
                        path: RouteNames.ResourceCenterCMSUpgradeToEnterprise,
                        element: <UpgradeToEnterpriseDialogComponent />,
                    },
                ],
            },
            {
                path: RouteNames.Profile,
                element: <ProfilePageComponent />,
            },
            {
                path: RouteNames.ThemeSettings,
                element:
                CheckGuard(EnableThemeSettings, <ProfilePageComponent />),
            },
            {
                path: RouteNames.AccessibilitySettings,
                element:
                CheckGuard(EnableAccessibilitySettings, <ProfilePageComponent />),
            },
            {
                path: RouteNames.NavigationSettings,
                element:
                CheckGuard(EnableProfileNavigationSettings, <ProfilePageComponent />),
            },
            {
                path: RouteNames.PrivacyAndSecuritySettings,
                element:
                CheckGuard(EnablePrivacyAndSecuritySettings, <ProfilePageComponent />),
            },
            {
                path: RouteNames.NotificationSettings,
                element: CheckGuard(EnableNotificationSettings, <ProfilePageComponent />),
                children: [
                    {
                        path: RouteNames.AddUserGroupProfile,
                        element: <AddUserGroupModal />,
                    },
                    { path: RouteNames.AddNotificationSettingsProfile },
                ],
            },
            {
                path: RouteNames.Notifications,
                element: <NotificationPageComponent />,
            },
            {
                path: RouteNames.PersonalAccessToken,
                element:
                CheckGuard(EnableReferenceTokens, <ProfilePageComponent />),
                children: [
                    {
                        path: RouteNames.AddPersonalAccessToken,
                        element: <CreatePersonalAccessTokenDrawerComponent />,
                    },
                ],
            },
            {
                path: RouteNames.OrganizationMigration,
                element:
                CheckGuard(!isFreeOrCommunityRevamp && EnabledScheduledOrgMigration, <OrgMigrationPage />),
            },
            ...(isAdmin ? ecommerceRoutes : []),
            {
                path: '*', // Fallback for invalid urls
                element: <InvalidUrl />,
            },
        ] satisfies UiRouteObject[];

        const flattenedRoutes = flattenRedirectUrls(nestedRoutes);
        const fullRoutes = [
            ...nestedRoutes,
            ...flattenedRoutes,
        ] satisfies UiRouteObject[];
        const relativeRoutes = recursiveRouteReplace(fullRoutes, RouteNames.PortalPrefix);
        return relativeRoutes as RouteObject[];
    }, [
        isAdmin,
        EnableResourceCenterCMS,
        EnableThemeSettings,
        EnableAccessibilitySettings,
        EnableProfileNavigationSettings,
        EnablePrivacyAndSecuritySettings,
        EnableNotificationSettings,
        EnableReferenceTokens,
        isFreeOrCommunityRevamp,
        EnabledScheduledOrgMigration,
        ecommerceRoutes,
    ]);
};

export default usePrivateShellRoutes;
