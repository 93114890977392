import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useEffect,
    useMemo,
} from 'react';
import type { Options } from 'react-youtube';
import YouTube from 'react-youtube';

const useStyles = makeStyles(theme =>
    createStyles({
        closeIcon: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundInvDeEmp,
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
    }),
);

const DefaultYoutubeVideoId = 'S8vyutaQgZ8';

const VideoGuide: React.FC<{
    closeHandler?: () => void;
    start?: number;
    end?: number;
    youtubeVideoId?: string;
}> = ({
    closeHandler, start, end, youtubeVideoId = DefaultYoutubeVideoId,
}) => {
    const classes = useStyles();

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event && (event.key === 'Escape' || event.key === 'Esc')) {
                event.preventDefault();
                if (closeHandler) {
                    closeHandler();
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress, false);

        return () => {
            document.removeEventListener('keydown', handleKeyPress, false);
        };
    }, [ closeHandler ]);

    const youtubeOptions: Options = useMemo(
        () => ({
            height: '400px',
            width: '700px',
            playerVars: {
                autoplay: 1,
                modestbranding: 1,
                iv_load_policy: 3,
                enablejsapi: 1,
                origin: window.location.origin,
                widget_referrer: window.location.href,
                rel: 0,
                start: start ? start : 0,
                end,
            },
        }),
        [ start, end ],
    );

    return (
        <Backdrop
            open
            style={{ zIndex: 1300 }}>
            <div className={classes.content}>
                <YouTube
                    videoId={youtubeVideoId}
                    opts={youtubeOptions} />
                <CloseIcon
                    className={classes.closeIcon}
                    onClick={closeHandler} />
            </div>
        </Backdrop>
    );
};

export default VideoGuide;
