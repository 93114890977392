import type { ILabelModel } from '@experiences/interfaces';
import type { PropsWithChildren } from 'react';
import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { useTenantIdContext } from '../../../../common/providers/TenantIdProvider';
import {
    getLabel,
    tagsUrl,
} from '../../../../services/orchestrator/TagsService.default';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../../store/selectors';

const TenantTagsPropertiesContext = createContext<ILabelModel | undefined>(undefined);

export const useTenantTagsPropertiesContext = () => React.useContext(TenantTagsPropertiesContext);

export const TenantTagsPropertiesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { tenantName } = useTenantIdContext();
    const { id } = useParams<{ id: string; editType: string }>();

    const accountId = useSelector(accountGlobalId);
    const logicalName = useSelector(accountLogicalName);

    const { data: propertyData } = useSWR(
        (id && tenantName) || (process.buildConfigs.showForMSI && accountId && id)
            ? {
                url: `${tagsUrl}/keyValue`,
                accountLogicalName: logicalName,
                tenantName,
                id,
                selectedAccountId: accountId,
            }
            : null,
        getLabel,
        { suspense: true },
    );

    return <TenantTagsPropertiesContext.Provider value={propertyData}>
        {children}
    </TenantTagsPropertiesContext.Provider>;
};
