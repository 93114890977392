import { AccountLicense } from '@experiences/constants';
import type { AccountType } from '@experiences/interfaces';
import urljoin from 'url-join';

import type {
    IResource,
    IRuntime,
    IUiPathFile,
    IUiPathFileWithName,
    IUiPathVersions,
} from '../../common/interfaces/resource';
import {
    Download_x64,
    Download_x86,
} from '../../common/interfaces/resource';

function getSimpleName(name: string) {
    switch (name) {
        case 'UiPath.DiagnosticTool.zip':
            return 'DIAGNOSTIC_TOOL';
        case 'UiPathInsightsInstaller.exe':
            return 'INSIGHTS_INSTALLER';
        case 'UiPathOrchestrator.msi':
            return 'ORCHESTRATOR_DOWNLOAD';
        case 'UiPathActionCenter.msi':
            return 'UIPATH_ACTION_CENTER_DOWNLOAD';
        case 'UiPath.MicrosoftOffice.Tools.zip':
            return 'OFFICE_REPAIR_TOOL';
        default:
            return '';
    }
}

export function mapOtherVersions(metadata: IUiPathVersions, baseUri: string, isEnabled: boolean) {
    const supportedPatchVersion = metadata['supported-patch-version'];
    const supportedPatchVersionFirst = supportedPatchVersion.split('.')[0];

    return {
        x64Href:
      supportedPatchVersion === '' || supportedPatchVersionFirst >= '21'
          ? urljoin(baseUri, metadata['download-location'], metadata['supported-patch-version'], 'UiPathStudio.msi')
          : undefined,
        x86Href: isEnabled
            ? supportedPatchVersion === '' || supportedPatchVersionFirst >= '21'
                ? urljoin(baseUri, metadata['download-location'], metadata['supported-patch-version'], 'UiPathStudio-x86.msi')
                : urljoin(baseUri, metadata['download-location'], metadata['supported-patch-version'], 'UiPathStudio.msi')
            : undefined,

        version: metadata['supported-patch-version'] || metadata.version.replace('-x86', ''),
    };
}

// TODO: move this logic to portal BE
export function mapResources(currentAccountType: AccountType, isOnPrem: boolean, resource?: IResource) {
    const generatedRuntimeVersions: IRuntime[] = [];
    const otherProducts: IUiPathFileWithName[] = [];
    const baseUri = resource?.baseUri ?? 'https://download.uipath.com';
    const supportedVersions: IUiPathVersions[] = [];
    let enterpriseFileName = '';
    let enterpriseLatestVersion = '';
    let communityLatestVersion = '';
    let betaVersions: IUiPathFile[] = [];

    if (resource) {
        resource.files.forEach((item: IUiPathFile) => {
            if (item.name === Download_x64.ENTERPRISE_STUDIO_NAME) {
                enterpriseLatestVersion = item.version;
                enterpriseFileName =
                AccountLicense[currentAccountType] === AccountLicense.TRIAL
                    ? Download_x64.ENTERPRISE_TRIAL_STUDIO_NAME
                    : Download_x64.ENTERPRISE_CLOUD_STUDIO_NAME;
            } else if (item.name === Download_x86.ENTERPRISE_STUDIO_NAME) {
                supportedVersions.push({
                    'download-location': '',
                    'end-extended-support': '',
                    'end-mainstream-support': '',
                    'release-date': '',
                    'support-level': '',
                    'supported-patch-version': '',
                    version: `${item.version}-x86`,
                });
            } else if (item.name === Download_x64.COMMUNITY_STUDIO_NAME) {
                communityLatestVersion = item.version;
            } else if (item.name === Download_x64.ENTERPRISE_RUNTIMES) {
                const enterpriseRuntime = {
                    name: item.name,
                    version: item.version,
                    type: 'enterprise',
                    resourceText: 'DOWNLOAD_UIPATH_REMOTE_RUNTIME',
                    location: item['download-location'] !== '/' ? item['download-location'] : '',
                };
                generatedRuntimeVersions.push(enterpriseRuntime);
            } else {
                otherProducts.push({
                    ...item,
                    simpleName: getSimpleName(item.name),
                });
            }
        });
        if (resource.uiexplorer) {
            resource.uiexplorer.forEach((item: IUiPathFile) => otherProducts.push({
                ...item,
                simpleName: 'UIEXPLORER',
            }));
        }

        if (resource['supported-versions']) {
            supportedVersions.push(...resource['supported-versions']);
            supportedVersions
                .filter(s => s.version !== 'x86')
                .forEach(item => {
                    // Skip non-working download link
                    // Can remove this line once we deprecate version
                    if (item.version === '2016.2') {
                        return;
                    }
                    const enterpriseVersionRuntime = {
                        name: Download_x64.ENTERPRISE_RUNTIMES,
                        version: item.version,
                        type: 'enterpriseVersion',
                        resourceText: 'DOWNLOAD_UIPATH_REMOTE_RUNTIME',
                        studioVersion: item['supported-patch-version'],
                        location: item['download-location'] + '/' + item['supported-patch-version'],
                    };
                    generatedRuntimeVersions.push(enterpriseVersionRuntime);
                });
        }
        if (resource.beta) {
            betaVersions = resource.beta;
            betaVersions.forEach(item => {
                if (item.name === Download_x64.ENTERPRISE_RUNTIMES) {
                    const enterpriseBetaRuntime = {
                        name: item.name,
                        version: item.version,
                        type: 'beta',
                        resourceText: 'DOWNLOAD_UIPATH_ENTERPRISE_BETA_RUNTIME',
                        location: item['download-location'],
                    };
                    generatedRuntimeVersions.push(enterpriseBetaRuntime);
                }
            });
        }

        otherProducts.push({
            simpleName: 'PLATFORM_CONFIG_TOOL',
            name: 'UiPath.Platform.Configuration.Tool.zip',
            'download-location': '/',
            version: undefined,
            type: 'enterpriseVersion',
        });

        if (!isOnPrem) {
            otherProducts.push({
                simpleName: 'TASK_CAPTURE_LATEST',
                name: 'task-capture-setup.exe',
                'download-location': '/TaskCapture',
                version: undefined,
                type: 'enterpriseVersion',
            });
        }

        otherProducts.push({
            simpleName: 'AUTOMATION_CLOUD_MIGRATION_TOOL',
            name: 'CloudMigration/UiPath.AutomationCloudMigrationTool.zip',
            'download-location': '/',
            version: undefined,
            type: 'enterpriseVersion',
        });

        otherProducts.push({
            simpleName: 'BROWSER_MIGRATION_TOOL',
            name: 'UiPathBrowserMigrationTool.zip',
            'download-location': '/',
            version: undefined,
            type: 'enterpriseVersion',
        });
    }

    return {
        baseUri,
        supportedVersions,
        runtimeVersions: generatedRuntimeVersions,
        betaVersions,
        otherProducts,
        enterpriseFileName,
        enterpriseLatestVersion,
        communityLatestVersion,
    };
}
