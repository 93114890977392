import { loadGlobalApp } from '@experiences/util';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Navigate,
    useParams,
} from 'react-router-dom';

import { PortalPrefix } from '../common/constants/RouteNames';
import { accountLogicalName } from '../store/selectors';

const OriginRouteComponent = () => {
    const accountNameSelector: string = useSelector(accountLogicalName);
    const { accountName } = useParams() as { accountName?: string };

    const account = accountName ?? accountNameSelector;

    useEffect(() => {
        if (account) {
            return;
        }

        // if account name is not present, global app must be
        // directly loaded with window.location.assign
        loadGlobalApp();
    }, [ account ]);

    return account ? <Navigate
        to={PortalPrefix.replace(':accountName', account)}
    /> : null;
};

export default OriginRouteComponent;
