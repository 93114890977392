import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import type { IDownloadCard } from '../../../common/interfaces/resource';
import { ReactComponent as VerifiedIcon } from '../../../images/verified.svg';
import ResourceCenterCMSTabDescription from './ResourceCenterCMSTabDescription';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        root: {
            display: 'flex',
            height: '100%',
        },
        pageSubHeader: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
        },
        content: {
            display: 'flex',
            margin: '0px 0px 32px 0px',
        },
        svgIcon: {
            alignSelf: 'flex-start',
            display: 'flex',
            '& .iconOrange': { stroke: theme.palette.semantic.colorForegroundHigh },
            '& .iconStar': { stroke: theme.palette.semantic.colorForegroundEmp },
            '& .iconLines': { fill: theme.palette.semantic.colorForeground },
            '& .iconOrangeFill': { fill: theme.palette.semantic.colorForegroundHigh },
            '& .iconBackground': { fill: theme.palette.semantic.colorBackgroundGray },
        },
        verifiedIcon: { flexGrow: 1 },
        cardContainer: {
            width: '100%',
            boxShadow: '2',
        },
        cardHeader: {
            display: 'flex',
            flexDirection: 'row',
        },
        cardContent: { padding: '28px 22px 22px 28px' },
    }),
}));

const ResourceCenterCMSDownloadCardComponent: React.FC<{
    card: IDownloadCard;
}> = ({ card }) => {
    const classes = useStyles();

    return (
        <div
            className={classes.content}
            data-cy="studio-card">
            <Card className={classes.cardContainer}>
                <CardContent className={classes.cardContent}>
                    <div className={classes.cardHeader}>
                        <UiText
                            className={classes.pageSubHeader}
                            data-cy="download-card-title">
                            {card.title}
                        </UiText>
                        <div className={classes.verifiedIcon} />
                        {card.highlighted && <VerifiedIcon className={classes.svgIcon} />}
                    </div>
                    <ResourceCenterCMSTabDescription
                        description={card.stableDescription}
                        version={card.stableVersion}
                        isEnterprise
                        downloadLink={card.stableDownloadLink}
                        downloadList={card.downloadCardListCollection.items}
                        releaseNotes={card.releaseNotes}
                    />
                </CardContent>
            </Card>
        </div>
    );
};

export default ResourceCenterCMSDownloadCardComponent;
