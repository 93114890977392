import type { ILabelModel } from '@experiences/interfaces';
import type { PropsWithChildren } from 'react';
import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { useTenantIdContext } from '../../../../common/providers/TenantIdProvider';
import {
    getLabel,
    tagsUrl,
} from '../../../../services/orchestrator/TagsService.default';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../../store/selectors';

const TenantTagsLabelContext = createContext<ILabelModel | undefined>(undefined);

export const useTenantTagsLabelContext = () => React.useContext(TenantTagsLabelContext);

export const TenantTagsLabelProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { tenantName } = useTenantIdContext();
    const { id } = useParams<{ id: string }>();

    const accountId = useSelector(accountGlobalId);
    const logicalName = useSelector(accountLogicalName);

    const { data: labelData } = useSWR(
        (id && tenantName) || (process.buildConfigs.showForMSI && accountId && id)
            ? {
                url: `${tagsUrl}/label`,
                accountLogicalName: logicalName,
                tenantName,
                id,
                selectedAccountId: accountId,
            }
            : null,
        getLabel,
        { suspense: true },
    );

    return <TenantTagsLabelContext.Provider value={labelData}>
        {children}
    </TenantTagsLabelContext.Provider>;
};
