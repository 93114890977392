import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import CheckGuard from '../../container/helpers/CheckGuard';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const AiTrustLayerComponent = React.lazy(() =>
    import('./AiTrustLayerComponent').then(module => ({ default: module.AiTrustLayerComponent }))
);

const AiTrustSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const EnableGovernanceModuleFederation = useFeatureFlagValue(Features.EnableGovernanceModuleFederation.name);

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminAiTrustLayer,
            name: translate({ id: 'CLIENT_AI_TRUST_LAYER' }),
        },
    ], [ organizationName, translate ]);

    return (
        <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
            {CheckGuard(EnableGovernanceModuleFederation, <>
                {children}
            </>)}
        </BreadcrumbProvider>
    );
};

export const AiTrustRoutes: UiRouteObject = {
    path: routePaths.adminAiTrustLayer,
    element: (
        <AiTrustSettingsProvider>
            <UiSuspensefulOutlet />
        </AiTrustSettingsProvider>
    ),
    children: [
        {
            path: routePaths.adminAiTrustLayerUsage,
            redirectUrls: [ routePaths.adminAiTrustLayer ],
            element: <AiTrustLayerComponent />,
        },
        {
            path: routePaths.adminAiTrustLayerAudit,
            element: <AiTrustLayerComponent />,
        },
        {
            path: routePaths.adminAiTrustLayerGovernance,
            // TODO: Legacy routes, delete in the future
            redirectUrls: [ '/admin/ai-trust-settings', '/admin/ai-trust-layer/settings' ],
            element: <AiTrustLayerComponent />,
        },
        {
            path: routePaths.adminAiTrustLayerA4E,
            element: <AiTrustLayerComponent />,
        },
        {
            path: routePaths.adminAiTrustLayerContextGrounding,
            element: <AiTrustLayerComponent />,
        },
    ],
};
