import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    TenantConfigureService,
    TenantServiceLogExport,
    TenantServices,
    TenantServicesAdd,
} from '../../../common/constants/RouteNames';
import { routePaths } from '../../../common/constants/routePaths';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../../common/providers/BreadcrumbProvider';
import { useTenantIdContext } from '../../../common/providers/TenantIdProvider';
import type { UiRouteObject } from '../../../container/routeConfigs/UiRouteObject';

const TenantServicesComponent = React.lazy(() => import('./TenantServicesComponent'));
const TenantServicesAddComponent = React.lazy(() => import('./TenantServicesAddComponent'));
const ConfigureTenantLicenseComponent = React.lazy(() => import('../subcomponents/ConfigureTenantLicenseComponent'));
const LogExportComponent = React.lazy(() => import('../subcomponents/LogExportComponent'));

const TenantServiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();
    const { tenantName } = useTenantIdContext();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminTenantHome,
            name: tenantName ?? translate({ id: 'CLIENT_TENANT' }),
        },
        {
            index: 2,
            link: routePaths.adminTenantServices,
            name: translate({ id: 'CLIENT_TENANTS' }),
        },
    ], [ organizationName, tenantName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

export const TenantServicesRoutes: UiRouteObject = {
    path: routePaths.adminTenant,
    element: <TenantServiceProvider>
        <UiSuspensefulOutlet />
    </TenantServiceProvider>,
    children: [
        {
            path: routePaths.adminTenantServices,
            redirectUrls: [ TenantServices ],
            element: <>
                <TenantServicesComponent />
                <UiSuspensefulOutlet />
            </>,
            children: [
                {
                    path: routePaths.adminTenantServicesAdd,
                    redirectUrls: [ TenantServicesAdd ],
                    element: <TenantServicesAddComponent />,
                },
                {
                    path: routePaths.adminTenantServicesLogExport,
                    redirectUrls: [ TenantServiceLogExport ],
                    element: <LogExportComponent />,
                },
                {
                    path: routePaths.adminTenantServicesConfigure,
                    redirectUrls: [ TenantConfigureService ],
                    element: <ConfigureTenantLicenseComponent />,
                },
                {
                    path: routePaths.adminTenantServicesConfigureService,
                    redirectUrls: [ `${TenantConfigureService}/:serviceType` ],
                    element: <ConfigureTenantLicenseComponent />,
                },
            ],
        },
    ],
};
