import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { Consent } from '../../services/identity/ConsentService';
import { ConsentCard } from './ConsentCard';

export const ConsentList = ({
    consents, onDeleted,
}: { consents: Consent[]; onDeleted: () => void }) => (
    <UiStack
        direction="column"
        align="start"
        gap={SpacingToken.M}>

        <UiText>
            <FormattedMessage
                id="CLIENT_USER_CONSENT_DETAILS"
                values={{
                    bold: chunks => <UiText
                        display='inline'
                        variant={FontVariantToken.fontSizeMBold}>
                        {chunks}
                    </UiText>,
                }}
            />
        </UiText>

        <UiStack
            wrap='wrap'
            pt={SpacingToken.S}
            gap={SpacingToken.XS}>
            {consents.map(consent => (
                <ConsentCard
                    key={consent.id}
                    consent={consent}
                    onDeleted={onDeleted}
                />
            ))}
        </UiStack>

    </UiStack>
);
