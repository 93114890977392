import { useLocalization } from '@experiences/locales';
import {
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import { useFilteredParams } from '@experiences/util';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    getContentfulResource,
    resourceCenterCMSUrl,
} from '../../services/ResourceCenterCMSService';
import { accountType } from '../../store/selectors';
import { UiPanel } from '../common/UiPanel/UiPanel';
import ResourceCenterCMSDownloadCard from './subcomponents/ResourceCenterCMSDownloadCard';
import ResourceCenterCMSFeaturedDownloadComponent from './subcomponents/ResourceCenterCMSFeaturedDownloadComponent';
import ResourceCenterCMSSideBarComponent from './subcomponents/ResourceCenterCMSSideBarComponent';
import ResourceCenterSearchComponent from './subcomponents/ResourceCenterSearchComponent';

const useStyles = makeStyles(theme => ({
    ...createStyles({
        root: {
            display: 'flex',
            height: '100%',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            margin: '16px 0px',
        },
        headerText: {
            fontWeight: 300,
            fontSize: '32px',
        },
        pageSubHeaderSecondary: {
            fontWeight: 400,
            fontSize: '20px',
            color: theme.palette.semantic.colorForeground,
            paddingRight: '5px',
        },
        secondaryHeaderContainer: {
            display: 'flex',
            alignItems: 'baseline',
            padding: '20px 0px',
        },
        showAllDownloads: {
            fontWeight: 400,
            fontSize: '14px',
            paddingLeft: '5px',
            cursor: 'pointer',
            color: theme.palette.semantic.colorPrimary,
            '&:hover': { textDecoration: 'underline' },
        },
    }),
}));

const ResourceCenterCMS: React.FC = () => {
    const classes = useStyles();
    const language = useLocalization();
    const [ bypassCache ] = useFilteredParams([ 'bypassCache' ]);
    const {
        data, isLoading,
    } = useSWR(
        {
            url: resourceCenterCMSUrl,
            language,
            bypassCache,
        },
        getContentfulResource,
    );
    const { formatMessage: translate } = useIntl();
    const [ showAllDownloads, setShowAllDownloads ] = useState(false);
    const currentAccountType = useSelector(accountType);

    if (isLoading) {
        return (
            <CircularProgress
                color="primary"
                size="60px"
                style={{
                    marginTop: '20%',
                    marginLeft: 'calc(50% - 30px)',
                }} />
        );
    }

    // TODO need designs for no data scenario / Contentful error
    if (!data?.containerSidebar) {
        return null;
    }

    return (
        <UiPanel
            header={{
                title: <ResourceCenterSearchComponent />,
                disableRightPadding: true,
            }}
            sideBar={
                <ResourceCenterCMSSideBarComponent
                    title={data.containerSidebar.title}
                    cards={data.containerSidebar.sidebarListCollection.items.filter(card =>
                        card?.displayCondition?.accountTypeList.includes(currentAccountType),
                    )}
                />
            }
        >
            <div className={classes.header}>
                <UiText
                    className={classes.headerText}
                    role="heading"
                    aria-level={1}>
                    {translate({ id: 'CLIENT_DOWNLOADS' })}
                </UiText>
            </div>
            <Grid
                container
                spacing={4}>
                <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}>
                    <div className={classes.secondaryHeaderContainer}>
                        <UiText className={classes.pageSubHeaderSecondary}>
                            {data.containerDownload.title}
                        </UiText>
                    </div>
                    <div>
                        <ResourceCenterCMSDownloadCard
                            cards={data.containerDownload.downloadListCollection.items.filter(card =>
                                card?.displayCondition?.accountTypeList.includes(currentAccountType),
                            )}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}>
                    <div className={classes.secondaryHeaderContainer}>
                        <UiText className={classes.pageSubHeaderSecondary}>
                            {data.containerFeaturedDownload.title}
                        </UiText>
                        <UiText
                            className={classes.showAllDownloads}
                            onClick={() => setShowAllDownloads(!showAllDownloads)}>
                            {!showAllDownloads ? translate({ id: 'CLIENT_SHOW_ALL' }) : translate({ id: 'CLIENT_HIDE_ALL' })}
                        </UiText>
                    </div>
                    <ResourceCenterCMSFeaturedDownloadComponent
                        cards={data.containerFeaturedDownload.featuredDownloadListCollection.items.filter(card =>
                            card?.displayCondition?.accountTypeList.includes(currentAccountType),
                        )}
                        showAllDownloads={showAllDownloads}
                    />
                </Grid>
            </Grid>
        </UiPanel>
    );
};

export default () => (
    <>
        <ResourceCenterCMS />
        <UiSuspensefulOutlet />
    </>
);
