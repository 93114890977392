import {
    EcommerceElementsWrapperComponent,
    EcommerceProvider,
} from '@experiences/ecommerce';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { EcommerceUpdateLicenseQuantity } from './EcommerceUpdateLicenseQuantity';

export const EcommerceUpdateLicenseQuantityWrapper: React.FC = () => {
    const location = useLocation();
    const subscriptionId = useMemo(() => location?.state?.subscriptionId, [ location?.state?.subscriptionId ]);

    return (<EcommerceProvider>
        <EcommerceElementsWrapperComponent>
            <EcommerceUpdateLicenseQuantity subscriptionId={subscriptionId} />
        </EcommerceElementsWrapperComponent>
    </EcommerceProvider>);
};
