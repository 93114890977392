import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import { TenantHome } from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import TenantProvider from '../../common/providers/TenantIdProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import { TenantAuditRoutes } from '../audit/AuditRoutes';
import { ManageAccessRoutes } from '../manageAccess/ManageAccessRoutes';
import { TenantTagsRoutes } from '../tags/TenantTagsRoutes';
import { VpnGatewayRoutes } from '../vpnGateway/VpnGatewayRoutes';
import { TenantLicenseRoutes } from './licenses/TenantLicenseRoutes';
import { TenantServicesRoutes } from './services/TenantServiceRoutes';
import { TenantSettingsRoutes } from './settings/TenantSettingsRoutes';

const TenantAdminHome = React.lazy(() => import('../tenants/home/TenantAdminHome'));

export const TenantAdminRoutes: UiRouteObject = {
    path: routePaths.adminTenant,
    element: <TenantProvider>
        <UiSuspensefulOutlet />
    </TenantProvider>,
    children: [
        {
            path: routePaths.adminTenantHome,
            redirectUrls: [ TenantHome ],
            element: <TenantAdminHome />,
        },
        TenantServicesRoutes,
        ManageAccessRoutes,
        TenantLicenseRoutes,
        TenantTagsRoutes,
        TenantAuditRoutes,
        VpnGatewayRoutes,
        TenantSettingsRoutes,
    ],
};
