import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { userGlobalId } from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...createStyles({
        searchBox: {
            width: '50%',
            backgroundColor: theme.palette.semantic.colorBackground,
        },
        searchIcon: { color: theme.palette.semantic.colorIconDefault },
    }),
}));

const supportPage = 'https://www.uipath.com/support';
const queryUrl = 'search-2020?hs_preview=qmzIoEOR-34941528204&q=';

export default function ResourceCenterSearchComponent() {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ searchValue, setSearchValue ] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const userId = useSelector(userGlobalId);

    return (
        <TextField
            className={classes.searchBox}
            variant="outlined"
            placeholder={translate({ id: 'CLIENT_SEARCH_PLACEHOLDER' })}
            InputProps={{ startAdornment: <SearchIcon className={classes.searchIcon} /> }}
            onChange={handleChange}
            onKeyPress={e => {
                if (e.key === 'Enter') {
                    portalTelemetry.trackTrace({
                        message: `Resource Center CMS Searchbar: ${userId} searched ${searchValue}`,
                        severityLevel: SeverityLevel.Info,
                    });
                    window.open(searchValue ? supportPage + queryUrl + searchValue : supportPage, '_blank');
                }
            }}
            data-cy="resource-center-search-bar"
        />
    );
}
