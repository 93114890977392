import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    EditTenantMigration,
    TenantMigration,
    TenantSettings,
} from '../../../common/constants/RouteNames';
import { routePaths } from '../../../common/constants/routePaths';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../../common/providers/BreadcrumbProvider';
import { useTenantIdContext } from '../../../common/providers/TenantIdProvider';
import type { UiRouteObject } from '../../../container/routeConfigs/UiRouteObject';
import { TenantMigrationDetailsDrawer } from '../migrate/TenantMigrationDetailsDrawer';

const TenantSettingsComponent = React.lazy(() => import('./TenantSettingsComponent'));
const TenantMigrationPage = React.lazy(() => import('../migrate/TenantMigrationPage'));

const TenantSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();
    const { tenantName } = useTenantIdContext();

    const breadcrumbs = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminTenantHome,
            name: tenantName,
        },
        {
            index: 2,
            link: routePaths.adminTenantSettings,
            name: translate({ id: 'CLIENT_SETTINGS' }),
        },
    ], [ organizationName, tenantName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadcrumbs}>
        {children}
    </BreadcrumbProvider>;
};

export const TenantSettingsRoutes: UiRouteObject = {
    path: routePaths.adminTenantSettings,
    element: <TenantSettingsProvider>
        <UiSuspensefulOutlet />
    </TenantSettingsProvider>,
    children: [
        {
            path: routePaths.adminTenantSettings,
            redirectUrls: [ TenantSettings ],
            element: <>
                <TenantSettingsComponent />
                <UiSuspensefulOutlet />
            </>,
            children: [
                {
                    path: routePaths.adminTenantSettingsMigrationEdit,
                    redirectUrls: [ EditTenantMigration ],
                    element: <TenantMigrationDetailsDrawer />,
                },
            ],
        },
        {
            path: routePaths.adminTenantSettingsMigration,
            redirectUrls: [ TenantMigration ],
            element: <TenantMigrationPage />,
        },
    ],
};
