import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import { routePaths } from '../../common/constants/routePaths';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import { OrgAdminRoutes } from '../organization/OrgAdminRoutes';
import { TenantAdminRoutes } from '../tenants/TenantAdminRoutes';

export const AdminRoutes: UiRouteObject = {
    path: routePaths.admin,
    element: <UiSuspensefulOutlet />,
    children: [
        OrgAdminRoutes,
        TenantAdminRoutes,
    ],
};
