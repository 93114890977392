import type {
    IPackage,
    IPlanDetails,
    ISkuPackageDetails,
    IUpdateLicenseQuantityForm,
} from '@experiences/ecommerce';
import { MONTHLY_PLAN_TYPE } from '@experiences/ecommerce';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { useCallback } from 'react';
import type { UseFormGetValues } from 'react-hook-form';

const useEcommerceUpdateLicenseQuantityViewModel = (
    getValues: UseFormGetValues<IUpdateLicenseQuantityForm>,
    productQuantitiesFromSubscription: { [p: string]: number },
    customSkuPackage: IPackage | undefined,
    currentSkuPackageDetails: ISkuPackageDetails,
    planDetails: IPlanDetails | undefined) => {
    const enableEcommerceAnnualRenewalDecrease = getFeatureFlagValue(Features.EnableEcommerceAnnualRenewalDecrease.name);

    const extractQuantities = useCallback((productCode: string) => {
        const { productQuantities } = getValues();
        const subscriptionQuantity = productQuantitiesFromSubscription[productCode] ?? 0;
        const formQuantity = productQuantities ? Number(productQuantities[productCode]) : 0;
        const difference = formQuantity - subscriptionQuantity;
        const customPackageProductQuantities = customSkuPackage ?
            customSkuPackage.productQuantities.find(p => p.code === productCode) : null;
        return {
            subscriptionQuantity,
            formQuantity,
            difference,
            customPackageProductQuantities,
        };
    }, [ customSkuPackage, getValues, productQuantitiesFromSubscription ]);

    const getMinAllowedQuantity = useCallback((productCode: string) => {
        if (!enableEcommerceAnnualRenewalDecrease) {
            return currentSkuPackageDetails.planType === MONTHLY_PLAN_TYPE
                ? customSkuPackage?.productQuantities.find(p => p.code === productCode)?.minQuantity ?? 0
                : productQuantitiesFromSubscription[productCode];
        }

        if (currentSkuPackageDetails.planType === MONTHLY_PLAN_TYPE || planDetails?.shouldAllowDecrease) {
            return customSkuPackage?.productQuantities.find(p => p.code === productCode)?.minQuantity ?? 0;
        }

        return productQuantitiesFromSubscription[productCode];

    },
    [
        currentSkuPackageDetails.planType,
        customSkuPackage?.productQuantities,
        enableEcommerceAnnualRenewalDecrease,
        planDetails?.shouldAllowDecrease,
        productQuantitiesFromSubscription,
    ]);

    return {
        getMinAllowedQuantity,
        extractQuantities,
    };
};

export default useEcommerceUpdateLicenseQuantityViewModel;
