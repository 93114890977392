import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    Outlet,
    useSearchParams,
} from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import type { Consent } from '../../services/identity/ConsentService';
import { getConsents } from '../../services/identity/ConsentService';
import { accountGlobalId } from '../../store/selectors';
import { UiResizablePageContainer } from '../common/UiPageContainer/UiResizablePageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import { ConsentList } from './ConsentList';
import { ConsentUserRecords } from './ConsentUserRecords';
import { NoConsents } from './NoConsents';

const OrgConsentPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const [ consents, setConsents ] = useState<Consent[]>([]);
    const organizationName = useOrganizationName();
    const partitionGlobalId = useSelector(accountGlobalId);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const consentId = searchParams.get('consentId');

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.UserConsent,
            name: translate({ id: 'CLIENT_USER_CONSENT' }),
        },
    ], [ organizationName, translate ]);

    const {
        data, isLoading, mutate,
    } = useSWR({ partitionGlobalId }, getConsents);

    useEffect(() => {
        if (data?.content) {
            setConsents([ data ]);
        } else {
            setConsents([]);
        }
    }, [ data ]);

    const onDeleted = useCallback(() => {
        mutate();
        consentId && setSearchParams(undefined);
    }, [ consentId, mutate, setSearchParams ]);

    return (
        <UiResizablePageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadCrumbLinks} />}
            drawer={<ConsentUserRecords recurrenceInDays={consents?.find(c => c.id === consentId)?.recurrenceInDays} />}
            showDrawer={!!consentId}
            loading={isLoading}
        >
            {consents && consents.length === 0 && <NoConsents />}
            {consents && consents.length > 0 && <ConsentList
                consents={consents}
                onDeleted={onDeleted} />}
        </UiResizablePageContainer>
    );
};

export default () => (
    <>
        <OrgConsentPageComponent />
        <Outlet />
    </>
);
