import {
    getRegionTranslationId,
    Region,
} from '@experiences/constants';
import { OrgSelfServeMigrationEvent } from '@experiences/telemetry';
import {
    SpacingToken,
    UiSelect,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { DataResidencyCloudLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import { getOrganizationServiceIds } from '../../../../common/constants/ServicesMapping';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import { getAvailableRegionsPerService } from '../../../../services/global-workflow-service/OrgMigration';
import {
    getOrganizationData,
    getSupportedRegions,
} from '../../../../services/organization/OrganizationService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import { UiMigrationServiceCard } from '../../../common/UiCard';
import UiCardGrid from '../../../common/UiCardGrid/UiCardGrid';
import type { IOrgMigrationFormData } from './types';

const useStyles = makeStyles(theme =>
    ({
        regionSource: { width: '320px' },
        regionTarget: { width: '320px' },
        infoLink: {
            fontSize: '14px',
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        arrowIcon: { marginTop: '42px' },
        icon: {
            height: '16px',
            width: '16px',
            marginLeft: '4px',
            justifyItems: 'center',
        },
        alert: { paddingRight: '16px' },
    }),
);

export const OrgMigrationStepSelectRegion: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLinks = useDocumentationLinks();

    const {
        watch,
        control,
        formState: { errors },
    } = useFormContext<IOrgMigrationFormData>();
    const { logEvent } = useTelemetryHelper();
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);

    const { data: organizationData } = useSWR({
        url: 'getOrganizationData',
        organizationGuid: partitionGlobalId,
    }, getOrganizationData);

    const { data: availableServices } = useSWR({
        organizationGuid: partitionGlobalId,
        accountName,
        url: 'getSupportedRegions',
    }, getSupportedRegions);

    const { data: availableRegions } = useSWR({
        accountName,
        url: 'getAvailableRegionsPerService',
    }, getAvailableRegionsPerService);

    const sourceRegion = watch('sourceRegion');
    const destinationRegion = watch('destinationRegion');

    const {
        organizationLevelRegions, organizationServices,
    } = useMemo(() => {
        const identityService = availableServices?.find(service => service.id === 'identity');
        const filteredServices = availableServices?.filter(service => getOrganizationServiceIds().includes(service.id));
        return {
            organizationLevelRegions: identityService?.supportedRegions ?? [],
            organizationServices: filteredServices ?? [],
        };
    }, [ availableServices ]);

    const currentServiceToRegionMap = useMemo(() => {
        const map: Record<string, string> = {};
        organizationData?.organizationServiceInstances.forEach(service => {
            map[service.serviceType] = service.region;
        });
        return map;
    }, [ organizationData ]);

    const allowedRegions = useMemo(
        () => (organizationLevelRegions ?? []).filter(x => x !== sourceRegion),
        [ organizationLevelRegions, sourceRegion ],
    );

    const regionOptions = useMemo(() =>
        Object.assign({}, ...(allowedRegions ?? []).map((x) =>
            ({ [Region[x]]: translate({ id: getRegionTranslationId(x) }) }))),
    [ allowedRegions, translate ]);

    const supportedServicesInNewRegion = useMemo(() => {
        if (!availableRegions || destinationRegion === Region.None) {
            return [];
        }
        return (availableRegions.availableRegionsPerService[destinationRegion] || []).map(x => x.service);
    }, [ availableRegions, destinationRegion ]);

    return (
        <UiStack
            direction='column'
            gap={SpacingToken.XL}>
            <UiStack gap={SpacingToken.S}>
                <TextField
                    label={translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_SOURCE_REGION' })}
                    value={translate({ id: getRegionTranslationId(sourceRegion) })}
                    variant="outlined"
                    data-cy="source-region"
                    className={classes.regionSource}
                />
                <ArrowRightAltIcon className={classes.arrowIcon} />
                <UiSelect
                    id="destinationRegion"
                    name="destinationRegion"
                    control={control}
                    isTranslated
                    inputLabel={translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_DESTINATION_REGION' })}
                    options={regionOptions}
                    error={!!errors.destinationRegion}
                    required
                    className={classes.regionTarget}
                    dataCy="region-select-field"
                />
            </UiStack>

            <UiStack
                direction='column'
                mt={SpacingToken.L}
                gap={SpacingToken.Micro}>
                <UiText variant={FontVariantToken.fontSizeLBold}>
                    {translate({ id: 'CLIENT_SERVICE_AVAILABLE' })}
                </UiText>
                <UiText variant={FontVariantToken.fontSizeM}>
                    {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_REGION_AVAILABILITY' })}
                </UiText>
                <UiStack>
                    <Link
                        className={classes.infoLink}
                        onClick={() => logEvent(OrgSelfServeMigrationEvent.ReadMore, { Location: 'org-migration-select-region-1' })}
                        target="_blank"
                        href={getLocalizedLinks({ articleSlug: DataResidencyCloudLink })}>
                        {translate({ id: 'CLIENT_READ_REGION_RESIDENCY' })}
                        <OpenInNewIcon className={classes.icon} />
                    </Link>
                </UiStack>
            </UiStack>

            <UiCardGrid
                maxCardWidth='330px'
                style={{ maxWidth: 'unset' }}
                position='left'>
                {organizationServices.map((service, index) => (
                    <UiMigrationServiceCard
                        key={`org-service-card-${index}`}
                        region={currentServiceToRegionMap[service.id] ?? sourceRegion}
                        serviceId={service.id}
                        newRegion={destinationRegion}
                        supportedServicesInNewRegion={supportedServicesInNewRegion}
                    />
                ))}
            </UiCardGrid>

            <PortalAlertBar
                status='info'
                cancelable={false}>
                <UiStack
                    className={classes.alert}
                    direction='column'>
                    <UiText>
                        {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_ALERT_1' })}
                    </UiText>
                    <Link
                        onClick={() => logEvent(OrgSelfServeMigrationEvent.ReadMore, { Location: 'org-migration-select-region-2' })}
                        target="_blank"
                        href={getLocalizedLinks({ articleSlug: DataResidencyCloudLink })}>
                        {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_ALERT_2' })}
                    </Link>
                    <br />
                    <UiText>
                        {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_ALERT_3' })}
                    </UiText>
                </UiStack>
            </PortalAlertBar>

        </UiStack>
    );
};
