import {
    portalTelemetry,
    ResourceCenterEvent,
    SeverityLevel,
} from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
    withStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import type { IFeaturedDownloadCard } from '../../../common/interfaces/resource';
import { userGlobalId } from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';

const Accordion = withStyles({
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '&:before': { display: 'none' },
        '&$expanded': { margin: 'auto' },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
    root: {
        padding: 'inherit',
        paddingRight: '16px',
        minHeight: 64,
        '&$expanded': { minHeight: 64 },
        '&:hover': { backgroundColor: theme.palette.semantic.colorBackgroundSecondary },
    },
    content: {
        margin: '0px',
        alignItems: 'center',
        '&$expanded': { margin: '0px' },
    },
    expanded: {},
}))(MuiAccordionSummary);

const useStyles = makeStyles((theme: Theme) => ({
    ...createStyles({
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: 64,
            padding: '12px',
            '&:hover': { backgroundColor: theme.palette.semantic.colorBackgroundSecondary },
            justifyContent: 'center',
        },
        cardContentHeader: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: 64,
            padding: '12px',
            justifyContent: 'center',
        },
        cardTitle: {
            fontWeight: 400,
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
        },
        cardVersion: {
            fontWeight: 400,
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        accordionDetail: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0px',
        },
        linkContainer: {
            margin: '0px 10px',
            display: 'flex',
            alignItems: 'center',
        },
        releaseIcon: { fontSize: '16px' },
        operatingSystem: {
            display: 'flex',
            '&:hover': { backgroundColor: theme.palette.semantic.colorBackgroundSecondary },
        },
        operatingSystemContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        subtitle: { fontWeight: 500 },
    }),
}));

const ResourceCenterCMSFeaturedDownloadGridComponent: React.FC<{
    cards: IFeaturedDownloadCard[];
    collapse: boolean;
}> = ({
    cards, collapse,
}) => {
    const classes = useStyles();
    const userId = useSelector(userGlobalId);
    const { logEvent } = useTelemetryHelper();

    return (
        <>
            {cards.map((card, index) => {
                const hasDropdownList = card.featuredDownloadCardListCollection.items.length > 0;
                const hasSideList = card.featuredDownloadCardSideListCollection.items.length > 0;
                return (
                    <div
                        key={index}
                        data-cy={`grid-row-featured-download-${card.title}`}>
                        {!collapse && index === 0 && <Divider />}
                        <Grid
                            item
                            xs={12}>
                            {hasDropdownList ? (
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className={classes.cardContentHeader}>
                                            <UiText
                                                className={classes.cardTitle}
                                                data-cy="card-title">
                                                {card.title}
                                            </UiText>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetail}>
                                        {card.featuredDownloadCardListCollection.items
                                            .filter(item => item != null)
                                            .map((downloadItem, dIndex) => (
                                                <Link
                                                    key={dIndex}
                                                    href={downloadItem.link}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{ textDecoration: 'none' }}
                                                    data-cy="dropdown-download-item"
                                                    onClick={() => {
                                                        portalTelemetry.trackTrace({
                                                            message: `Resource Center CMS Featured Download: ${userId} downloaded ${card.title}`,
                                                            severityLevel: SeverityLevel.Info,
                                                        });
                                                    }}
                                                >
                                                    <div className={classes.cardContent}>
                                                        <UiText
                                                            display="inline"
                                                            data-cy="download-item-title"
                                                            className={classes.subtitle}>
                                                            {downloadItem.title}
                                                        </UiText>

                                                        {downloadItem.description && (
                                                            <UiText data-cy="download-item-description">
                                                                {downloadItem.description}
                                                            </UiText>
                                                        )}
                                                    </div>
                                                </Link>
                                            ))}
                                    </AccordionDetails>
                                </Accordion>
                            ) : (
                                <>
                                    {hasSideList ? (
                                        <div className={classes.operatingSystem}>
                                            <div className={classes.cardContentHeader}>
                                                <UiText
                                                    className={classes.cardTitle}
                                                    data-cy="card-title">
                                                    {card.title}
                                                </UiText>
                                                {card.version && (
                                                    <UiText
                                                        className={classes.cardVersion}
                                                        data-cy="card-version">
                                                        {card.version}
                                                    </UiText>
                                                )}
                                            </div>
                                            <div className={classes.operatingSystemContainer}>
                                                {card.featuredDownloadCardSideListCollection.items
                                                    .filter(item => item != null)
                                                    .map((downloadItem, dIndex) => (
                                                        <Link
                                                            key={dIndex}
                                                            className={classes.linkContainer}
                                                            href={downloadItem.link}
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                            style={{ textDecoration: 'none' }}
                                                            data-cy="download-item"
                                                            onClick={() => {
                                                                portalTelemetry.trackTrace({
                                                                    // eslint-disable-next-line max-len
                                                                    message: `Resource Center CMS Featured Download: ${userId} downloaded ${downloadItem.title}`,
                                                                    severityLevel: SeverityLevel.Info,
                                                                });
                                                            }}
                                                        >
                                                            <UiText
                                                                display="inline"
                                                                data-cy="operating-system"
                                                                className={classes.subtitle}>
                                                                {downloadItem.title}
                                                            </UiText>
                                                            <span className={clsx('material-icons-outlined', classes.releaseIcon)}>
open_in_new
                                                            </span>
                                                        </Link>
                                                    ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <Link
                                            href={card.link}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            style={{ textDecoration: 'none' }}
                                            data-cy="download-item"
                                            onClick={() => {
                                                logEvent(ResourceCenterEvent.CardDownload, {
                                                    userId,
                                                    download: card.title,
                                                    ResourceURL: card.link,
                                                });
                                            }}
                                        >
                                            <div className={classes.cardContent}>
                                                <UiText
                                                    className={classes.cardTitle}
                                                    data-cy="card-title">
                                                    {card.title}
                                                </UiText>
                                                {card.version && (
                                                    <UiText
                                                        className={classes.cardVersion}
                                                        data-cy="card-version">
                                                        {card.version}
                                                    </UiText>
                                                )}
                                            </div>
                                        </Link>
                                    )}
                                </>
                            )}
                        </Grid>
                        <Divider />
                    </div>
                );
            })}
        </>
    );
};

export default ResourceCenterCMSFeaturedDownloadGridComponent;
