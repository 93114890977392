import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { EmailSettings } from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const OrganizationEmailSettingsRevampComponent = React.lazy(() =>
    import('../organizationsettings/email/OrganizationEmailSettingsRevampComponent'));

const MailSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminEmailSettings,
            name: translate({ id: 'CLIENT_MAIL_SETTINGS' }),
        },
    ], [ organizationName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

export const MailSettingsRoutes: UiRouteObject = {
    path: routePaths.adminEmailSettings,
    element: <MailSettingsProvider>
        <UiSuspensefulOutlet />
    </MailSettingsProvider>,
    children: [
        {
            index: true,
            redirectUrls: [ EmailSettings ],
            element: <OrganizationEmailSettingsRevampComponent />,
        },
    ],
};
