import { ContactTechnicalSupportLinks } from '@experiences/constants';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { useLocalizedLinks } from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        activationErrorMessage: { maxWidth: '417px' },
        closeButton: { marginLeft: '8px' },
        buttonContainer: {
            display: 'flex',
            marginTop: '32px',
            justifyContent: 'flex-end',
        },
        buttonText: { color: theme.palette.semantic.colorBackgroundRaised },
    }),
}));

const ErrorDialogBody: React.FC<IUiDialogHookCustomContent> = ({ closeDialog }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useLocalizedLinks();

    return (
        <>
            <UiText
                className={classes.activationErrorMessage}
                data-cy="something-went-wrong-message">
                {translate({ id: 'CLIENT_TRIAL_REQUESTED_FAILED' })}
            </UiText>
            <div className={classes.buttonContainer}>
                <Button
                    key="contactSupportButton"
                    variant="contained"
                    data-cy="contact-support-button"
                    onClick={() => closeDialog()}
                    href={
                        getLocalizedLink(ContactTechnicalSupportLinks) ??
            'https://www.uipath.com/company/contact-us/contact-technical-support'
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    {translate({ id: 'CLIENT_CONTACT_SUPPORT' })}
                </Button>
                <Button
                    className={classes.closeButton}
                    key="closeButton"
                    variant="outlined"
                    data-cy="close-button"
                    onClick={() => closeDialog()}
                >
                    {translate({ id: 'CLIENT_CLOSE' })}
                </Button>
            </div>
        </>
    );
};

export default ErrorDialogBody;
