import { TrialServiceEvent } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useSWRConfig } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { SubscriptionCodeToLearnMoreUrl } from '../../../common/constants/ServicesUrls';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { licenseManagementAccountConsumablesUrl } from '../../../services/licensing/management/AccountService';
import {
    requestTrialLicense,
    trialServicesSwrKey,
} from '../../../services/licensing/TrialPerSku';
import { accountGlobalId } from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import ErrorDialogBody from '../../common/ErrorDialogBody';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        learnMore: { marginLeft: '22.5px' },
        actionButton: {
            color: theme.palette.semantic.colorForegroundLink,
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            '&:hover': { textDecoration: 'underline' },
        },
    }),
}));

export const FreeTrialActionButtons: React.FC<{ subscriptionCode: string }> = ({ subscriptionCode }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();
    const createNotification = useUiSnackBar();
    const [ isLoading, setIsLoading ] = useState(false);
    const { logEvent } = useTelemetryHelper();

    const accountId = useSelector(accountGlobalId);

    const { mutate } = useSWRConfig();

    const activateTrialDialogBody = useCallback(
        (requestedSubscriptionCode: string) => (
            <div>
                <UiText>
                    {translate({ id: `CLIENT_ACTIVATE_TRIAL_DIALOG_${requestedSubscriptionCode}_P1` })}
                </UiText>
                <br />
                <UiText>
                    {translate({ id: `CLIENT_ACTIVATE_TRIAL_DIALOG_${requestedSubscriptionCode}_P2` })}
                </UiText>
                <br />
                <UiText>
                    <FormattedMessage
                        id="CLIENT_ACTIVATE_TRIAL_DIALOG_P3"
                        values={{
                            a: (msg: React.ReactNode[]) =>
                                (
                                    <a
                                        className={classes.a}
                                        href="https://www.uipath.com/legal/trust-and-security/legal-terms"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-cy="automation-docs-link"
                                    >
                                        {msg}
                                    </a>
                                ),
                        }}
                    />
                </UiText>
            </div>
        ),
        [ translate, classes ],
    );

    const activateTrialServiceDialog = useCallback(
        async (requestedSubscriptionCode: string) => createDialog({
            title: translate({ id: `CLIENT_REQUEST_${requestedSubscriptionCode}` }),
            body: activateTrialDialogBody(requestedSubscriptionCode),
            primaryButtonText: `${translate({ id: 'CLIENT_ACTIVATE_TRIAL' })}`,
            showCancel: true,
        }),
        [ activateTrialDialogBody, createDialog, translate ],
    );

    const requestTrial = useCallback(
        async (requestedSubscriptionCode: string) => {
            logEvent(TrialServiceEvent.StartTrial, { SubscriptionCode: requestedSubscriptionCode });
            const proceed = await activateTrialServiceDialog(requestedSubscriptionCode);
            if (proceed) {
                try {
                    setIsLoading(true);
                    createNotification(translate({ id: 'CLIENT_TRIAL_REQUESTED' }), notificationType.SUCCESS);
                    await requestTrialLicense(requestedSubscriptionCode);
                    await mutate({
                        url: licenseManagementAccountConsumablesUrl,
                        accountGlobalId: accountId,
                        includeTrialConsumables: true,
                    });
                    await mutate(trialServicesSwrKey);
                    logEvent(TrialServiceEvent.Confirmed, { SubscriptionCode: requestedSubscriptionCode });
                    setIsLoading(false);
                } catch (error) {
                    await createDialog({
                        title: translate({ id: 'CLIENT_OOPS_SOMETHING_WENT_WRONG' }),
                        icon: 'error',
                        unclosable: false,
                        showCancel: false,
                        customDialogContent: ErrorDialogBody,
                    });
                    setIsLoading(false);
                }
            }
        },
        [ accountId, activateTrialServiceDialog, createDialog, createNotification, logEvent, mutate, translate ],
    );
    return <>
        {!isLoading ? (
            <UiText
                data-cy={`start-${subscriptionCode}`}
                onClick={() => requestTrial(subscriptionCode)}
                className={classes.actionButton}>
                {translate({ id: 'CLIENT_START_TRIAL' })}
            </UiText>
        ) : (
            <CircularProgress size={24} />
        )}
        <a
            className={clsx(classes.actionButton, classes.learnMore)}
            target="_blank"
            rel="noreferrer"
            href={SubscriptionCodeToLearnMoreUrl[subscriptionCode]}
            data-cy="learn-more-link"
        >
            {translate({ id: 'CLIENT_LEARN_MORE' })}
        </a>
    </>;
};
