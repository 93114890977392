import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        title: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '16px',
        },
    }),
}));

export const FreeTrialsHeader: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    return <>
        <UiText className={classes.title}>
            {translate({ id: 'CLIENT_FREE_TRIALS' })}
        </UiText>
    </>;
};
