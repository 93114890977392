import { portalTelemetry } from '@experiences/telemetry';
import { replaceRouteParams } from '@experiences/util';
import React, { useMemo } from 'react';
import type {
    NavigateProps,
    Params,
    To,
} from 'react-router-dom';
import {
    Navigate,
    useLocation,
    useParams,
} from 'react-router-dom';

export const NavigateWithParams: React.FC<Omit<NavigateProps, 'to'> & {
    to: (To | ((params: Params) => To));
    customParams?: Record<string, any>;
    originalRoute?: string;
    logger?: boolean;
}> = ({
    to, state, customParams, originalRoute, logger, ...rest
}) => {
    const params = useParams();
    const location = useLocation();

    const fullParams = useMemo(() => ({
        ...params,
        ...customParams,
    }), [ customParams, params ]);

    const resolvedTo = useMemo(() => replaceRouteParams(
        typeof to === 'function'
            ? to(fullParams)
            : to,
        fullParams,
    ), [ fullParams, to ]);

    if (logger && originalRoute) {
        const message = `Navigate from ${originalRoute} to ${typeof resolvedTo === 'string' ? resolvedTo : resolvedTo.pathname}`;
        // eslint-disable-next-line no-console
        console.log(message);
        portalTelemetry.trackTrace({ message });
    }

    return <Navigate
        to={resolvedTo}
        state={state ?? location.state}
        {...rest}
    />;
};
