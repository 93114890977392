import { UiText } from '@experiences/ui-common';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import type { CellProps } from 'react-table';

import useCheckAuthTypes from '../../../auth/hooks/CheckAuthType';
import { getFriendlyName } from '../../../common/constants/ServicesMapping';
import useApiAccessInstanceModal from '../../../common/hooks/useApiAccessInstanceModal';
import type { ITenantService } from '../../../common/interfaces/tenant/tenant';
import { UiGrid } from '../../common/UiGrid';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';

const useStyles = makeStyles(theme =>
    createStyles({
        button: {
            color: theme.palette.primary.main,
            padding: '0px 8px',
        },
    }),
);

const TenantApiAccessGrid: React.FC = () => {
    const {
        data, isValidating, error,
    } = useTenantsContext();
    const { formatMessage: translate } = useIntl();
    const {
        ApiAccessInstanceModalComponent, setCurrentTenant,
    } = useApiAccessInstanceModal();
    const classes = useStyles();
    const {
        checkTokenAuthIsAzure, checkTokenAuthIsSAML,
    } = useCheckAuthTypes();

    const isLoggedInWithDirectory = useMemo(() => checkTokenAuthIsAzure || checkTokenAuthIsSAML,
        [ checkTokenAuthIsAzure, checkTokenAuthIsSAML ]);

    const gridData = useMemo(() => data.filter(tenantService => tenantService.serviceType === 'orchestrator'), [ data ]);

    const renderServiceCell = useCallback(({ row }: CellProps<ITenantService>) =>
        <UiText>
            {getFriendlyName(row.original.serviceType)}
        </UiText>,
    []);

    const renderAPIAccessCell = useCallback(({ row }: CellProps<ITenantService>) =>
        <Tooltip
            arrow
            title={isLoggedInWithDirectory ? translate({ id: 'CLIENT_API_ACCESS_DISABLED_AAD' }) : ''}>
            <span data-cy="tenant-api-access-grid-view-api-access-button-wrapper">
                <Button
                    data-cy="tenant-api-access-grid-view-api-access-button"
                    className={classes.button}
                    color="primary"
                    variant="text"
                    disabled={isLoggedInWithDirectory}
                    onClick={() => {
                        const name = row.original.tenant?.name;
                        if (name) {
                            setCurrentTenant(name);
                        }
                    }}
                >
                    {translate({ id: 'CLIENT_VIEW_API_ACCESS' })}
                </Button>
            </span>
        </Tooltip>,
    [ classes.button, isLoggedInWithDirectory, setCurrentTenant, translate ]);

    return (
        <>
            <ApiAccessInstanceModalComponent />
            <UiGrid<ITenantService>
                dataCy="tenant-api-access-grid"
                data={gridData}
                error={error}
                loading={isValidating}
                filters
                pagination
                tableHeight="500px"
                initialSort={[ { id: 'tenant.name' }, { id: 'serviceType' } ]}
                columns={[
                    {
                        accessor: 'tenant.name',
                        Header: translate({ id: 'CLIENT_TENANT' }),
                        width: 100,
                    },
                    {
                        accessor: 'serviceType',
                        Header: translate({ id: 'CLIENT_SERVICE' }),
                        width: 100,
                        Cell: renderServiceCell,
                    },
                    {
                        accessor: 'id',
                        Header: translate({ id: 'CLIENT_API' }),
                        width: 100,
                        Cell: renderAPIAccessCell,
                    },
                ]}
            />
        </>
    );
};

export default TenantApiAccessGrid;
