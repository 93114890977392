import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import urljoin from 'url-join';

import useIsOnPrem from '../../common/hooks/useIsOnPrem';
import type { IResource } from '../../common/interfaces/resource';
import { ReactComponent as CommunityStudioIcon } from '../../images/communityStudio.svg';
import { ReactComponent as EnterpriseStudioIcon } from '../../images/enterpriseStudio.svg';
import { ReactComponent as VerifiedIcon } from '../../images/verified.svg';
import {
    getLatestStudioVersion,
    resourceCenterUrl,
} from '../../services/ResourceCenterService';
import { accountType } from '../../store/selectors';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { mapResources } from './ResourceUtil';
import ResourceCardFooterComponent from './subcomponents/ResourceCardFooterComponent';
import ResourceRightComponent from './subcomponents/ResourceCenterSideBarComponent';
import SupportedVersionsComponent from './subcomponents/ResourceSupportedVersionsComponent';
import type { CommunityType } from './subcomponents/ResourceTabPanelComponent';
import { ResourceTabPanelComponent } from './subcomponents/ResourceTabPanelComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        root: {
            display: 'flex',
            height: '100%',
        },
        pageSubHeader: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
        },
        content: {
            display: 'flex',
            margin: '24px 0px 32px 0px',
        },
        svgIcon: {
            alignSelf: 'flex-start',
            display: 'flex',
            marginRight: '12px',
            '& .iconOrange': { stroke: theme.palette.semantic.colorForegroundHigh },
            '& .iconStar': { stroke: theme.palette.semantic.colorForegroundEmp },
            '& .iconLines': { fill: theme.palette.semantic.colorForeground },
            '& .iconOrangeFill': { fill: theme.palette.semantic.colorForegroundHigh },
            '& .iconBackground': { fill: theme.palette.semantic.colorBackgroundGray },
        },
        showOtherVersions: {
            margin: '12px 0px',
            cursor: 'pointer',
            color: theme.palette.semantic.colorPrimary,
            '&:hover': { textDecoration: 'underline' },
        },
        cardContent: { padding: '28px 22px 22px 28px' },
        enterpriseCard: {
            borderColor: theme.palette.semantic.colorBorderDeEmp,
            borderLeftWidth: '3px',
            borderLeftColor: theme.palette.semantic.colorLogo,
            borderRadius: '0px 3px 3px 0px',
            flex: '0 1 604px',
        },
        subtitle: { fontWeight: 500 },
    }),
}));

const ResourceCenter: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const isOnPrem = useIsOnPrem();

    const currentAccountType = useSelector(accountType);

    const [ showVersion, setShowVersion ] = useState(false);
    const [ currentTab, setCurrentTab ] = useState<CommunityType>('preview');

    const changeCurrentTab = useCallback(
        (event: React.ChangeEvent<{}>, newValue: CommunityType) => {
            setCurrentTab(newValue);
        },
        [ setCurrentTab ],
    );

    const { data } = useSWR<IResource, Error>(`${resourceCenterUrl}/getLatestVersion`, getLatestStudioVersion);
    const state = useMemo(() => mapResources(currentAccountType, isOnPrem, data), [ data, currentAccountType ]);

    return (
        <UiPanel
            sideBar={
                <ResourceRightComponent
                    baseUri={state.baseUri}
                    runtimeVersions={state.runtimeVersions}
                    enterpriseLatestVersion={state.enterpriseLatestVersion}
                    otherProducts={state.otherProducts}
                />
            }
        >
            <div>
                <UiText
                    className={classes.pageSubHeader}
                    role="heading"
                    aria-level={1}>
                    {translate({ id: 'CLIENT_DOWNLOAD_UIPATH_STUDIO' })}
                </UiText>
                <UiText style={{ fontSize: '14px' }}>
                    {translate({ id: 'CLIENT_CHOOSE_STUDIO_EDITION' })}
                </UiText>
            </div>
            {AccountLicense[currentAccountType] !== AccountLicense.COMMUNITY && (
                <>
                    <div
                        className={classes.content}
                        data-cy="enterprise-studio-card">
                        <Card
                            variant="outlined"
                            className={classes.enterpriseCard}>
                            <CardContent className={classes.cardContent}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                    <EnterpriseStudioIcon className={classes.svgIcon} />
                                    <UiText
                                        className={classes.pageSubHeader}
                                        style={{ marginBottom: '16px' }}>
                                        {translate({ id: 'CLIENT_ENTERPRISE_EDITION' })}
                                    </UiText>
                                    <div style={{ flexGrow: 1 }} />
                                    <VerifiedIcon
                                        className={classes.svgIcon}
                                        style={{ marginRight: '0px' }} />
                                </div>
                                <ul>
                                    <li>
                                        <UiText
                                            className={classes.subtitle}
                                            display="inline">
                                            {translate({ id: 'CLIENT_ENTERPRISE_DESCRIPTION_FIRST_POINT' })}
                                        </UiText>
                                    </li>
                                    <li>
                                        <UiText
                                            className={classes.subtitle}
                                            display="inline">
                                            {translate({ id: 'CLIENT_ENTERPRISE_DESCRIPTION_SECOND_POINT' })}
                                        </UiText>
                                    </li>
                                </ul>
                                <ResourceCardFooterComponent
                                    studioType="DOWNLOAD_ENTERPRISE"
                                    studioVersion={state.enterpriseLatestVersion}
                                    downloadLink={urljoin(state.baseUri, state.enterpriseFileName)}
                                />

                                <UiText
                                    data-cy="resource-center-other-versions-text"
                                    onClick={() => setShowVersion(!showVersion)}
                                    className={classes.showOtherVersions}>
                                    {showVersion
                                        ? translate({ id: 'CLIENT_HIDE_OTHER_VERSION' })
                                        : translate({ id: 'CLIENT_SHOW_OTHER_VERSION' })}
                                </UiText>
                                <Collapse in={showVersion}>
                                    <SupportedVersionsComponent state={state} />
                                </Collapse>
                            </CardContent>
                        </Card>
                    </div>
                </>
            )}
            {!DisableFeatureFedRamp && (
                <>
                    <Divider />
                    <div
                        className={classes.content}
                        data-cy="community-studio-card">
                        <Card style={{ boxShadow: 'none' }}>
                            <CardContent
                                className={classes.cardContent}
                                style={{ paddingTop: '12px' }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                    <CommunityStudioIcon className={classes.svgIcon} />
                                    <UiText className={classes.pageSubHeader}>
                                        {translate({ id: 'CLIENT_COMMUNITY_EDITION' })}
                                    </UiText>
                                </div>
                                <Tabs
                                    value={currentTab}
                                    onChange={changeCurrentTab}
                                    textColor="primary"
                                    indicatorColor="primary">
                                    <Tab
                                        value="preview"
                                        label={translate({ id: 'CLIENT_PREVIEW' })}
                                        data-cy="resource-center-preview-tab"
                                    />
                                    <Tab
                                        value="stable"
                                        label={translate({ id: 'CLIENT_STABLE' })}
                                        data-cy="resource-center-stable-tab" />
                                </Tabs>
                                <ResourceTabPanelComponent
                                    value={currentTab}
                                    state={state} />
                            </CardContent>
                        </Card>
                    </div>
                </>
            )}
        </UiPanel>
    );
};

export default ResourceCenter;
