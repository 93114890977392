import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { UiRouteObject } from '@experiences/util';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    ServicesUsage,
    TenantServicesUsageAllocation,
} from '../../../common/constants/RouteNames';
import { routePaths } from '../../../common/constants/routePaths';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../../common/providers/BreadcrumbProvider';
import { useTenantIdContext } from '../../../common/providers/TenantIdProvider';

const ServicesUsagePageComponent = React.lazy(() => import('../subcomponents/usage/ServicesUsagePageComponent'));
const ConfigureTenantLicensesComponent = React.lazy(() => import('../subcomponents/ConfigureTenantLicenseComponent'));

const TenantLicenseProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const orgName = useOrganizationName();
    const { tenantName } = useTenantIdContext();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: orgName,
        },
        {
            index: 1,
            link: routePaths.adminTenantHome,
            name: tenantName,
        },
        {
            index: 2,
            link: routePaths.adminTenantLicenses,
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ], [ orgName, tenantName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

export const TenantLicenseRoutes: UiRouteObject = {
    path: routePaths.adminTenantLicenses,
    element: <TenantLicenseProvider>
        <UiSuspensefulOutlet />
    </TenantLicenseProvider>,
    children: [
        {
            path: routePaths.adminTenantLicenses,
            redirectUrls: [ ServicesUsage ],
            element: <>
                <ServicesUsagePageComponent />
                <UiSuspensefulOutlet />
            </>,
            children: [
                {
                    path: routePaths.adminTenantLicensesEditAllocation,
                    redirectUrls: [ TenantServicesUsageAllocation ],
                    element: <ConfigureTenantLicensesComponent />,
                },
            ],
        },
    ],
};
