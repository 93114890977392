import { portalTelemetry } from '@experiences/telemetry';
import { AuthContext } from '@experiences/util';
import { useSignoutRedirect } from '@uipath/auth-react';
import React, {
    useCallback,
    useEffect,
    useRef,
} from 'react';

import useUserInfo from '../hooks/UserInfo';

const IdentityAuthProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const {
        token, email, country,
    } = useUserInfo();
    const signoutRedirect = useSignoutRedirect();
    const hasTrackStarted = useRef(false);
    const logout = useCallback(
        (returnUrl?: string) => {
            signoutRedirect({ post_logout_redirect_uri: returnUrl });
        },
        [ signoutRedirect ],
    );

    useEffect(() => {
        if (!hasTrackStarted.current && email && country) {
            portalTelemetry.setMetadata({ IsTestAccount: email?.split('@')[1] === 'uipath-qa.com' });
            portalTelemetry.trackLogin({ authenticationMethod: 'Auto' });
            portalTelemetry.trackApplicationStart({ UserCountry: country });
            hasTrackStarted.current = true;
        }
    }, [ country, email ]);

    return (
        <AuthContext.Provider
            value={{
                token,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default IdentityAuthProvider;

