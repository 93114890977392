import {
    SpacingToken,
    UiBackground,
    UiLogo,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import { UiStorage } from '@experiences/util';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            width: 375,
            overflow: 'auto',
            marginTop: 104,
            padding: 40,
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
            textAlign: 'center',
        },
        logo: { marginBottom: 20 },
    }),
);

const DIAGNOSTICS_FLAGS = 'diagnostics-flags';

export const Diagnostics: React.FC = () => {
    const classes = useStyles();
    const [ value, setValue ] = useState(false);

    useEffect(() => {
        const result = UiStorage.getItem(DIAGNOSTICS_FLAGS);
        setValue(result === 'true');
    }, []);

    const handleChange = useCallback((checked: boolean) => {
        UiStorage.setItem(DIAGNOSTICS_FLAGS, checked.toString());
        setValue(checked);
    }, []);

    return (
        <UiBackground center>
            <Paper className={classes.paper}>
                <UiLogo className={classes.logo} />
                <UiStack
                    align="center"
                    justify="between"
                    mb={SpacingToken.XL}>
                    <UiText
                        variant={FontVariantToken.fontSizeMBold}>
                            Enable Feature Flags Override
                    </UiText>
                    <Switch
                        checked={value}
                        onChange={(e, checked) => handleChange(checked)}
                        color="primary"
                    />
                </UiStack>
                <UiText
                    variant={FontVariantToken.fontSizeXs}>
                    You may need to refresh the page for changes to take effect.
                </UiText>
            </Paper>
        </UiBackground>
    );
};
