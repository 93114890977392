import {
    DocsLinks,
    SupportLinks,
} from '@experiences/constants';
import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { useLocalizedLinks } from '@experiences/util';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import type { ISideBarCard } from '../../../common/interfaces/resource';
import { userGlobalId } from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        sideBarContainer: {
            width: '100%',
            paddingTop: '6px',
        },
        sideBarHeader: {
            fontWeight: 600,
            fontSize: '20px',
        },
        wrapper: { padding: '20px 0px 20px 0px' },
        cardDefaults: {
            borderRadius: 0,
            boxShadow: 'none',
            padding: '10px 0px 10px 0px',
        },
        cardHeader: {
            color: 'blue',
            padding: '0px',
        },
        cardContent: { padding: '0px' },
        sidePanelColor: { backgroundColor: theme.palette.semantic.colorBackgroundSecondary },
        sidePanelCard: {
            flexDirection: 'column',
            height: 'auto',
        },
        iconAdjustments: {
            width: '24px',
            height: '24px',
            marginRight: '10px',
            fontStyle: 'outlined',
        },
        sidebarTitle: {
            fontWeight: 600,
            color: theme.palette.semantic.colorPrimary,
            '&:hover': { textDecoration: 'underline' },
        },
        sidebarDescription: {
            fontWeight: 400,
            color: theme.palette.semantic.colorForeground,
        },
    }),
}));

const SideBarCard: React.FC<{ title: string; icon: string; link: string; description: string }> = ({
    title,
    icon,
    link,
    description,
}) => {
    const classes = useStyles();
    const userId = useSelector(userGlobalId);
    return (
        <div data-cy={`side-bar-card-${title}`}>
            <Card className={clsx(classes.cardDefaults, classes.sidePanelColor, classes.sidePanelCard)}>
                <span
                    className={clsx(classes.iconAdjustments, classes.svgIcon, classes.icon, 'material-icons-outlined')}
                    data-cy="sidebar-card-icon"
                >
                    {icon}
                </span>
                <div>
                    <Link
                        href={link}
                        onClick={() => {
                            portalTelemetry.trackTrace({
                                message: `Resource Center CMS Sidebar: ${userId} clicked on ${title} link`,
                                severityLevel: SeverityLevel.Info,
                            });
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-cy="sidebar-card-link"
                    >
                        <UiText
                            className={classes.sidebarTitle}
                            display="inline"
                            data-cy="sidebar-card-title">
                            {title}
                        </UiText>
                    </Link>
                </div>
                <div>
                    <UiText
                        className={classes.sidebarDescription}
                        data-cy="sidebar-card-description">
                        {description}
                    </UiText>
                </div>
            </Card>
        </div>
    );
};

const ResourceCenterCMSSideBarComponent: React.FC<{
    title: string;
    cards: ISideBarCard[];
}> = ({
    title, cards,
}) => {
    const classes = useStyles();

    const getLocalizedLink = useLocalizedLinks();

    const cardLocalizedLink = useCallback((link: string) => {
        if (link.includes('docs.uipath')) {
            return getLocalizedLink(DocsLinks);
        }
        if (link.includes('/support')) {
            return getLocalizedLink(SupportLinks);
        }
    }
    , [ getLocalizedLink ]);

    return (
        <div className={classes.sideBarContainer}>
            <UiText className={classes.sideBarHeader}>
                {title}
            </UiText>
            <div className={classes.wrapper}>
                {cards.map((card, index) => (
                    <SideBarCard
                        key={index}
                        title={card.title}
                        icon={card.icon}
                        link={cardLocalizedLink(card.link) ?? card.link}
                        description={card.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default ResourceCenterCMSSideBarComponent;
