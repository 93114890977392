import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { releaseNote } from '../../../common/constants/Constant';
import downloadResources from '../../../common/constants/DownloadResources';
import type { IMappedResources } from '../../../common/interfaces/resource';
import ResourceCardFooterComponent from './ResourceCardFooterComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        communityDownloadText: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontSize: '12px',
            marginTop: '8px',
        },
        subtitle: { fontWeight: 500 },
    }),
);

export type CommunityType = 'preview' | 'stable';

export const ResourceTabPanelComponent: React.FC<{
    value: CommunityType;
    state: IMappedResources;
}> = ({
    value, state,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ downloadLink, previewStudioVersion ] = useMemo(() => {
        let downloadLink = downloadResources.getDownloadURL(downloadResources.COMMUNITY_STUDIO_PREVIEW_DOWNLOAD_LINK);
        let previewStudioVersion = '21.10.0-beta.5978';

        if (value === 'preview') {
            state.betaVersions.forEach((studio: any) => {
                if (studio.name === 'UiPathStudioCommunity.msi') {
                    if (studio['download-location']) {
                        downloadLink = `${state.baseUri}${studio['download-location']}/${studio.name}`;
                    } else {
                        downloadLink = `${state.baseUri}/${studio.name}`;
                    }
                    previewStudioVersion = studio.version;
                }
            });
        }

        return [ downloadLink, previewStudioVersion ];
    }, [ state, value ]);

    return (
        <div style={{ marginTop: '16px' }}>
            {value === 'preview' ? (
                <div>
                    <ul>
                        <li>
                            <UiText
                                className={classes.subtitle}
                                display="inline">
                                {translate({ id: 'CLIENT_PREVIEW_COMMUNITY_DESCRIPTION_FIRST_POINT' })}
                            </UiText>
                        </li>
                        <li>
                            <UiText
                                className={classes.subtitle}
                                display="inline">
                                {translate({ id: 'CLIENT_COMMUNITY_DESCRIPTION_SECOND_POINT' })}
                            </UiText>
                        </li>
                    </ul>
                    <ResourceCardFooterComponent
                        studioType="DOWNLOAD_PREVIEW"
                        studioVersion={previewStudioVersion}
                        downloadLink={downloadLink}
                    />
                    <UiText className={classes.communityDownloadText}>
                        {translate({ id: 'CLIENT_WINDOWS_64BIT_VERSION' })}
                    </UiText>
                </div>
            ) : (
                <div>
                    <ul>
                        <li>
                            <UiText
                                className={classes.subtitle}
                                display="inline">
                                {translate({ id: 'CLIENT_COMMUNITY_DESCRIPTION_FIRST_POINT' })}
                            </UiText>
                        </li>
                        <li>
                            <UiText
                                className={classes.subtitle}
                                display="inline">
                                {translate({ id: 'CLIENT_COMMUNITY_DESCRIPTION_SECOND_POINT' })}
                            </UiText>
                        </li>
                    </ul>
                    <ResourceCardFooterComponent
                        studioType="DOWNLOAD_STABLE"
                        studioVersion={state.communityLatestVersion}
                        downloadLink="https://download.uipath.com/UiPathStudioCommunity.msi"
                        releaseLink={releaseNote.communityStableReleaseNotes}
                    />
                    <UiText className={classes.communityDownloadText}>
                        {translate({ id: 'CLIENT_WINDOWS_64BIT_VERSION' })}
                    </UiText>
                </div>
            )}
        </div>
    );
};
