import { ExternalAppsTab } from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    ExternalApplicationAdd,
    ExternalApplicationAddScopes,
    ExternalApplicationAddScopesWithName,
    ExternalApplicationEdit,
    ExternalApplicationEditScopes,
    ExternalApplicationEditScopesWithName,
    ExternalApplications,
    ExternalApplicationsPAT,
    ExternalApplicationsPATSettings,
    ExternalAppRegistrations,
    ExternalAppRegistrationsDetails,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import { AddEditExternalAppsProvider } from './providers/ExternalAppsProvider';

const ExternalApps = React.lazy(() => import('./ExternalApps'));
const CreateEditExternalApplicationsPageComponent = React.lazy(() => import('./subcomponents/CreateEditExternalApplicationsPageComponent'));
const ExternalApplicationsScopesDrawerComponent = React.lazy(() => import('./subcomponents/ExternalApplicationsScopesDrawerComponent'));
const ExternalApplicationsPATSettingsDrawerComponent = React.lazy(() => import('./pat/ExternalApplicationsPATSettingsDrawerComponent'));
const ExternalApplicationRegistrationDetailsDrawerComponent = React.lazy(
    () => import('./appRegistration/ExternalApplicationRegistrationDetailsDrawerComponent')
);

const ExternalAppsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminExternalAppsOauth,
            name: translate({ id: 'CLIENT_EXTERNAL_APPLICATIONS' }),
        },
        {
            index: 1,
            link: routePaths.adminExternalAppsPat,
            name: translate({ id: 'CLIENT_EXTERNAL_APPLICATIONS' }),
        },
        {
            index: 1,
            link: routePaths.adminExternalAppsRegistrations,
            name: translate({ id: 'CLIENT_EXTERNAL_APPLICATIONS' }),
        },
    ], [ organizationName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

export const ExternalAppRoutes: UiRouteObject = {
    path: routePaths.adminExternalApps,
    element: <ExternalAppsProvider>
        <UiSuspensefulOutlet />
    </ExternalAppsProvider>,
    children: [
        {
            path: routePaths.adminExternalAppsOauth,
            redirectUrls: [ ExternalApplications ],
            element: <>
                <ExternalApps type={ExternalAppsTab.OAUTH} />
                <UiSuspensefulOutlet />
            </>,
        },
        {
            path: routePaths.adminExternalAppsOauthAdd,
            redirectUrls: [ ExternalApplicationAdd.replace(':type', 'add') ],
            element: <AddEditExternalAppsProvider type="add">
                <CreateEditExternalApplicationsPageComponent type="add" />
                <UiSuspensefulOutlet />
            </AddEditExternalAppsProvider>,
            children: [
                {
                    path: routePaths.adminExternalAppsOauthAddScopes,
                    redirectUrls: [ ExternalApplicationAddScopes.replace(':type', 'add') ],
                    element: <ExternalApplicationsScopesDrawerComponent type="add" />,
                },
                {
                    path: routePaths.adminExternalAppsOuathAddScopesName,
                    redirectUrls: [ ExternalApplicationAddScopesWithName.replace(':type', 'add') ],
                    element: <ExternalApplicationsScopesDrawerComponent type="add" />,
                },
            ],
        },
        {
            path: routePaths.adminExternalAppsOauthEdit,
            redirectUrls: [ ExternalApplicationEdit.replace(':type', 'edit') ],
            element: <AddEditExternalAppsProvider type="edit">
                <CreateEditExternalApplicationsPageComponent type="edit" />
                <UiSuspensefulOutlet />
            </AddEditExternalAppsProvider>,
            children: [
                {
                    path: routePaths.adminExternalAppsOuathEditScopes,
                    redirectUrls: [ ExternalApplicationEditScopes.replace(':type', 'edit') ],
                    element: <ExternalApplicationsScopesDrawerComponent type="edit" />,
                },
                {
                    path: routePaths.adminExternalAppsOauthEditScopesName,
                    redirectUrls: [ ExternalApplicationEditScopesWithName.replace(':type', 'edit') ],
                    element: <ExternalApplicationsScopesDrawerComponent type="edit" />,
                },
            ],
        },
        {
            path: routePaths.adminExternalAppsPat,
            redirectUrls: [ ExternalApplicationsPAT ],
            element: <>
                <ExternalApps type={ExternalAppsTab.PAT} />
                <UiSuspensefulOutlet />
            </>,
            children: [
                {
                    path: routePaths.adminExternalAppsPatSettings,
                    redirectUrls: [ ExternalApplicationsPATSettings ],
                    element: <ExternalApplicationsPATSettingsDrawerComponent />,
                },
            ],
        },
        {
            path: routePaths.adminExternalAppsRegistrations,
            redirectUrls: [ ExternalAppRegistrations ],
            element: <>
                <ExternalApps type={ExternalAppsTab.REG} />
                <UiSuspensefulOutlet />
            </>,
            children: [
                {
                    path: routePaths.adminExternalAppsRegistrationsDetails,
                    redirectUrls: [ ExternalAppRegistrationsDetails ],
                    element: <ExternalApplicationRegistrationDetailsDrawerComponent />,
                },
            ],
        },
    ],
};
