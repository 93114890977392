import { UiSessionId } from '@experiences/ui-common';
import { ApAlertPopup } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

export const CentralErrorHandlingContext = React.createContext<{
    showError: (error: any) => void;
}>({ showError: () => {} });

export const useCentralErrorSetter = () => {
    const { showError } = useContext(CentralErrorHandlingContext);
    return showError;
};

export const CentralErrorHandlingProvider: React.FC<{ children?: React.ReactNode }> = props => {
    const { formatMessage: translate } = useIntl();

    const [ error, setError ] = useState(null);
    const alertPopupRef = useRef<any>(null);
    const showError = useCallback(
        (e: any) => {
            setError(e);
        },
        [ setError ],
    );

    useEffect(() => {
        if (error && alertPopupRef?.current?.openDialog) {
            alertPopupRef.current.openDialog();
        }
    }, [ error ]);

    return (
        <>
            { error && (
                <ApAlertPopup
                    ref={alertPopupRef}
                    header={translate({ id: 'CLIENT_ERROR' })}
                    hidePrimaryAction
                    status="error"
                    onCancel={() => {
                        setError(null);
                    }}>
                    {error}
                    <UiSessionId style={{
                        marginTop: '20px',
                        alignItems: 'left',
                    }} />
                </ApAlertPopup>
            )}
            <CentralErrorHandlingContext.Provider
                value={{ showError }}
            >
                {props.children}
            </CentralErrorHandlingContext.Provider>
        </>
    );
};
