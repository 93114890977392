const formatHour = (hour: number): string => {
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    return `${formattedHour}:00`;
};

/**
 * This function generates a list of time ranges from 00:00 to 20:00, in 4-hour intervals.
 * @returns a list of time ranges
 */
export const generateTimeWindows = () => {
    const hours = Array.from({ length: 21 }, (_, i) => i);

    const hoursList = hours.map((hour) => {
        const nextHour = hour === 20 ? 0 : hour + 4;
        const nextTime = formatHour(nextHour);
        const curTime = formatHour(hour);

        return `${curTime} - ${nextTime}`;
    });

    return hoursList;
};
