import type {
    ICustomPlanForm,
    IEcommerceSuccess,
    IOrderConfirmation,
    IProduct,
    IProductBase,
    IProductPrice,
    IProductQuantityDifference,
    ISubscriptionUpdate,
    IUpdateLicenseQuantityForm,
    IUpdateSubscriptionDto,
} from '@experiences/ecommerce';
import {
    billingUrl,
    BuyProConfirmation,
    BuyProSuccess,
    ECOMMERCE_PRODUCT_CODES,
    EcommerceCheckoutOrderSummary,
    EcommerceProductQuantityInput,
    fetchPlanDetails,
    getOrderConfirmationDetails,
    INCREMENTAL_FLOW,
    INDIVIDUAL_PRODUCT_CODES,
    updateSubscription,
    useEcommerce,
    useEcommerceTelemetry,
} from '@experiences/ecommerce';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    en,
    useLocalization,
} from '@experiences/locales';
import { GlobalStyles } from '@experiences/theme';
import {
    UiCheckBoxLabel,
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    formatDate,
    useAuthContext,
    useNavigateWithParams,
    useProtectRouteFromMissingParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import Info from '@mui/icons-material/Info';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { useStripe } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import type { UseFormReturn } from 'react-hook-form';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { Licensing } from '../../common/constants/RouteNames';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import {
    getCurrentCycleProductsPricesInAllCurrencies,
    getPackagesConfigByCurrency,
    getProductsPricesInAllCurrencies,
} from '../../services/licensing/BillingService';
import { accountLogicalName } from '../../store/selectors';
import { UiPanel } from '../common/UiPanel/UiPanel';
import EcommerceBackToLicense from './subcomponents/EcommerceBackToLicense';
import EcommerceProductInfo from './subcomponents/EcommerceProductInfo';
import useEcommerceUpdateLicenseQuantityViewModel from './useEcommerceUpdateLicenseQuantityViewModel';

type enTranslationKeys = keyof typeof en;

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        borderlessTable: {
            borderCollapse: 'collapse',
            marginTop: '24px',
            width: '100%',
        },
        innerCellBorder: {
            height: '100%',
            borderBottom: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        },
        tableTitle: { color: theme.palette.semantic.colorForegroundEmp },
        fontSizeH4Bold: {
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '24px',
        },
        tableHeader: {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            textAlign: 'left',
        },
        tableHeaderQuantity: {
            float: 'right',
            marginRight: '60px',
        },
        productCell: {
            height: '100%',
            padding: '24px 0px',
            borderBottom: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        },
        twoColumns: {
            display: 'flex',
            justifyContent: 'center',
        },
        body: {
            marginLeft: '4px',
            marginTop: '4px',
        },
        column1: { width: '681px' },
        column2: {
            marginLeft: '30px',
            maxWidth: '431px',
        },
        orderSummary: {
            boxSizing: 'border-box',
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            borderRadius: '3px',
            display: 'flex',
            flexDirection: 'column',
        },
        orderSummaryTitle: {
            marginTop: '20px',
            marginLeft: '18px',
            color: theme.palette.semantic.colorForeground,
        },
        confirmAndPayButton: { width: '100%' },
        confirmAndPaySection: {
            width: '100%',
            marginTop: '26px',
        },
        legalText: { marginTop: '20px' },
        quantityDiv: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
        },
        quantityDifference: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '8px',
            marginRight: '9px',
            width: '3ch',
            color: theme.palette.semantic.colorSuccessText,
        },
        quantityAdded: { color: theme.palette.semantic.colorSuccessText },
        quantityRemoved: { color: theme.palette.semantic.colorErrorText },
        checkbox: {
            height: '42px',
            marginTop: '-9px',
        },
        checkboxLabel: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontSize: '12px',
            lineHeight: '16px',
            marginTop: '4px',
        },
        loading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
        },
        licenseDecreaseBanner: {
            marginTop: '13px',
            background: theme.palette.semantic.colorInfoBackground,
            border: `1px solid ${theme.palette.semantic.colorInfoForeground}`,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '7px',
            paddingRight: '7px',
            display: 'flex',
        },
        licenseDecreaseText: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '9.5px',
            color: theme.palette.semantic.colorForeground,
        },
        renewTerms: {
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '16px',
        },
        infoIcon: {
            width: '15px',
            height: '20px',
            color: theme.palette.semantic.colorPrimary,
        },
    }),
}));

export const EcommerceUpdateLicenseQuantity: React.FC<{
    subscriptionId?: string;
}> = ({ subscriptionId }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const currentAccountName = useSelector(accountLogicalName);
    const [ quantityDifferencePerProduct, setQuantityDifferencePerProduct ] =
        useState<{ [code: string]: IProductQuantityDifference }>({});
    const language = useLocalization();
    const currentAccountNameFromSelector = useSelector(accountLogicalName);
    const [ loading, setLoading ] = useState(false);
    const [ paymentOrderConfirmation, setPaymentOrderConfirmation ] = useState<IOrderConfirmation | undefined>();
    const [ stripeSubscriptionId, setStripeSubscriptionId ] = useState<string>();
    const [ isLicenseDecreaseExecuted, setIsLicenseDecreaseExecuted ] = useState<boolean | false>();
    const [ nextPaymentDate, setNextPaymentDate ] = useState<string>();
    const createDialog = useShowDialog();
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const logEcommerceEvent = useEcommerceTelemetry();
    const stripe = useStripe();
    const createNotification = useUiSnackBar();
    useProtectRouteFromMissingParams([ !subscriptionId ], Licensing);
    const enableEcommerceCurrentCyclePrices = getFeatureFlagValue(Features.EnableEcommerceCurrentCyclePrices.name);

    const sendEcommerceEvent = useCallback(
        (eventName: string, otherProperties?: any) => {
            logEcommerceEvent(eventName, {
                Flow: INCREMENTAL_FLOW,
                ...otherProperties,
            });
        },
        [ logEcommerceEvent ],
    );

    const [ updatedSubscriptionProducts, setUpdatedSubscriptionProducts ] =
        useState<IProduct[]>([]);
    const [ changes, setChanges ] = useState<{ productsToAdd: IProduct[]; productsToDecrease: IProduct[] }>({
        productsToAdd: [],
        productsToDecrease: [],
    });
    const { token } = useAuthContext();
    const {
        data: orderConfirmationData,
        isValidating: loadingOrderConfirmation,
    } = useSWR(
        {
            stripeSubscriptionId: subscriptionId,
            accountName: currentAccountName,
            accessToken: token,
            url: `${billingUrl}/subscription`,
        },
        getOrderConfirmationDetails,
    );
    const {
        data: packagesData,
        isValidating: loadingCustomPackageData,
    } = useSWR(
        {
            accountName: currentAccountName,
            currency: orderConfirmationData?.invoiceData?.currency,
            url: `${billingUrl}/packagesConfig`,
        },
        getPackagesConfigByCurrency,
    );

    const { data: productsPriceOptions } = useSWR(
        {
            accountName: currentAccountName,
            url: enableEcommerceCurrentCyclePrices
                ? `${billingUrl}/currentCycleProductsPricesInAllCurrencies`
                : `${billingUrl}/productsPricesInAllCurrencies`,
        },
        enableEcommerceCurrentCyclePrices
            ? getCurrentCycleProductsPricesInAllCurrencies
            : getProductsPricesInAllCurrencies
    );

    const productPricesList: IProductPrice[] = [];
    productsPriceOptions?.forEach(item => {
        const productPrice: IProductPrice = {
            planType: item.planType,
            code: item.code,
            price: item.prices[orderConfirmationData?.invoiceData?.currency.toUpperCase() ?? 'USD'],
        } as IProductPrice;
        productPricesList.push(productPrice);
    });

    const { currentSkuPackageDetails } = useEcommerce();

    const productQuantitiesFromSubscription = useMemo(() => {
        const productQuantities: { [code: string]: number } = {};
        if (orderConfirmationData) {
            orderConfirmationData.products?.forEach(productFromSubscription => {
                productQuantities[productFromSubscription.code] = productFromSubscription.quantity;
            });
        }
        INDIVIDUAL_PRODUCT_CODES.forEach(productCode => {
            if (!productQuantities[productCode]) {
                productQuantities[productCode] = 0;
            }
        });
        return productQuantities;
    }, [ orderConfirmationData ]);
    const useFormMethods = useForm<IUpdateLicenseQuantityForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            termsAndConditionsAccepted: true,
            productQuantities: productQuantitiesFromSubscription,
        },
    });
    const {
        control, reset, getValues, watch, handleSubmit,
    } = useFormMethods;

    const termsAndConditionsAccepted = watch('termsAndConditionsAccepted');
    const customSkuPackage = useMemo(() => packagesData?.packages.find(pkg => pkg.isCustomizable), [ packagesData ]);
    const loadingData = useMemo(() =>
        loadingCustomPackageData || !packagesData || loadingOrderConfirmation || !orderConfirmationData || !subscriptionId,
    [ loadingCustomPackageData, loadingOrderConfirmation, orderConfirmationData, packagesData, subscriptionId ]);
    const { data: planDetails } = useSWR(
        {
            accountName: currentAccountName,
            accessToken: token,
            url: `${billingUrl}/planDetails`,
        },
        fetchPlanDetails,
    );
    const {
        extractQuantities, getMinAllowedQuantity,
    } = useEcommerceUpdateLicenseQuantityViewModel(
        getValues,
        productQuantitiesFromSubscription,
        customSkuPackage,
        currentSkuPackageDetails,
        planDetails
    );

    const updateQuantityDifference = useCallback(() => {
        const updatedProducts = [] as IProduct[];
        const productsToAdd = [] as IProduct[];
        const productsToDecrease = [] as IProduct[];
        const quantityDifferenceMap: { [code: string]: IProductQuantityDifference } = {};
        ECOMMERCE_PRODUCT_CODES.forEach(productCode => {
            const {
                subscriptionQuantity, formQuantity, difference, customPackageProductQuantities,
            } = extractQuantities(productCode);
            if (customPackageProductQuantities) {
                updatedProducts.push({
                    quantity: formQuantity,
                    code: productCode,
                    maxQuantity: customPackageProductQuantities.maxQuantity,
                    minQuantity: customPackageProductQuantities.minQuantity,
                } as IProduct);
                if (difference > 0) {
                    productsToAdd.push({
                        quantity: formQuantity - subscriptionQuantity,
                        code: productCode,
                        maxQuantity: customPackageProductQuantities.maxQuantity,
                        minQuantity: customPackageProductQuantities.minQuantity,
                    } as IProduct);
                }
                if (difference < 0) {
                    productsToDecrease.push({
                        quantity: subscriptionQuantity - formQuantity,
                        code: productCode,
                        maxQuantity: customPackageProductQuantities.maxQuantity,
                        minQuantity: customPackageProductQuantities.minQuantity,
                    } as IProduct);
                }
            }
            quantityDifferenceMap[productCode] = {
                value: Math.abs(difference),
                sign: difference < 0 ? '-' : '+',
            };
        });
        setUpdatedSubscriptionProducts(updatedProducts);
        setQuantityDifferencePerProduct(quantityDifferenceMap);
        setChanges({
            productsToAdd,
            productsToDecrease,
        });
    }, [
        setQuantityDifferencePerProduct,
        setChanges,
        setUpdatedSubscriptionProducts,
        extractQuantities,
    ]);

    useEffect(() => {
        reset({
            productQuantities: productQuantitiesFromSubscription,
            termsAndConditionsAccepted: false,
        });
    }, [ productQuantitiesFromSubscription, reset ]);

    useEffect(() => {
        if (stripeSubscriptionId && paymentOrderConfirmation) {
            if (paymentOrderConfirmation.status === 'PAYMENT_PROCESSED' || paymentOrderConfirmation.status === 'PAYMENT_OPEN') {
                setLoading(false);
                navigate(getRoute(BuyProConfirmation), {
                    state: {
                        orderConfirmation: paymentOrderConfirmation,
                        isLicenseDecreaseExecuted,
                        nextPaymentDate,
                    },
                });
            } else if (paymentOrderConfirmation.status === 'PAYMENT_FAILED') {
                (async () => {
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_TITLE' }),
                        body: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY' }),
                        icon: 'warning',
                        showCancel: false,
                        primaryButtonText: translate({ id: 'CLIENT_OK' }),
                    });
                    if (proceed) {
                        setLoading(false);
                    }
                })();
            }
        }
    }, [
        paymentOrderConfirmation,
        stripeSubscriptionId,
        currentAccountName,
        getRoute,
        createDialog,
        translate,
        isLicenseDecreaseExecuted,
        nextPaymentDate,
        navigate,
    ]);

    const handleSCAPayment = useCallback(
        async (subscriptionDto: IUpdateSubscriptionDto) => {
            sendEcommerceEvent('Billing.AskForSCA');
            const result = await stripe!.confirmCardPayment(subscriptionDto.paymentIntentClientSecret,
                { payment_method: subscriptionDto.defaultPaymentMethodId });
            sendEcommerceEvent('Billing.FinishSCA',
                { OperationSuccessful: result.paymentIntent?.status === 'succeeded' });
            if (result.paymentIntent?.status === 'succeeded') {
                setIsLicenseDecreaseExecuted(subscriptionDto.isLicenseDecreaseExecuted);
                setNextPaymentDate(planDetails?.nextPayment);
                setStripeSubscriptionId(subscriptionDto.stripeSubscriptionId);
                setPaymentOrderConfirmation(subscriptionDto.orderConfirmation);
            } else {
                const errorMessage = result.error?.message ?? translate({ id: 'CLIENT_PAYMENT_FAILURE_GENERAL_ERROR' });
                createNotification(errorMessage, notificationType.ERROR);
            }
        }, [ sendEcommerceEvent, createNotification, planDetails, translate, stripe ],
    );

    const handleNonSCAPayment = useCallback((subscriptionDto: IUpdateSubscriptionDto) => {
        setIsLicenseDecreaseExecuted(subscriptionDto.isLicenseDecreaseExecuted);
        setNextPaymentDate(planDetails?.nextPayment);
        setStripeSubscriptionId(subscriptionDto.stripeSubscriptionId);
        setPaymentOrderConfirmation(subscriptionDto.orderConfirmation);
    }, [ planDetails ],
    );

    const getProductsAndQuantitiesDictionary = useCallback((productsAndQuantitiesList: IProduct[]) =>
        Object.assign({}, ...productsAndQuantitiesList.map((p) =>
            ({ [p.code]: p.quantity }))), []);

    const submit = useCallback(
        async () => {
            setLoading(true);

            if (!stripe) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                setLoading(false);
                return;
            }

            const { productQuantities } = getValues();
            const decreaseProductsAndQuantitiesDictionary = getProductsAndQuantitiesDictionary(changes.productsToDecrease);

            try {
                if (changes.productsToAdd.length > 0) {
                    sendEcommerceEvent('Billing.Pay', productQuantities);
                }
                if (changes.productsToDecrease.length > 0) {
                    logEcommerceEvent('Licenses.ScheduleDecrease', { DecreasedProducts: decreaseProductsAndQuantitiesDictionary });
                }
                const products = ECOMMERCE_PRODUCT_CODES.map(productCode => {
                    let formProductQuantity = 0;
                    if (productQuantities?.[productCode]) {
                        formProductQuantity = Number(productQuantities[productCode]);
                    }
                    return {
                        code: productCode,
                        quantity: formProductQuantity,
                    } as IProductBase;
                });

                const subscriptionDetails = { products } as ISubscriptionUpdate;
                const subDto = await updateSubscription(subscriptionDetails, currentAccountNameFromSelector, token);

                if (subDto.isLicenseIncreaseExecuted) {
                    if (subDto.paymentIntentStatus === 'succeeded' || subDto.paymentIntentStatus === 'processing') {
                        handleNonSCAPayment(subDto);
                    } else if (subDto.paymentIntentStatus === 'requires_action') {
                        await handleSCAPayment(subDto);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        sendEcommerceEvent('Billing.PayError', { Error: en['CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY'] });
                        await createDialog({
                            title: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_TITLE' }),
                            body: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY' }),
                            icon: 'warning',
                            showCancel: false,
                            primaryButtonText: translate({ id: 'CLIENT_OK' }),
                        });
                    }
                } else if (subDto.isLicenseDecreaseExecuted) {
                    const successState = {
                        ecommerceSuccess:
                            {
                                title: translate({ id: 'CLIENT_DECREASE_LICENSE_SUCCESS_DIALOG_TITLE' }),
                                line1: translate(
                                    { id: 'CLIENT_DECREASE_LICENSE_SUCCESS_DIALOG_CONTENT' },
                                    { nextPaymentDate: formatDate(planDetails?.endDate, language) },
                                ),
                                button: 'OK',
                            } as IEcommerceSuccess,
                    };
                    navigate(getRoute(BuyProSuccess, currentAccountName), { state: successState });
                    setLoading(false);
                }
            } catch (error: any) {
                setLoading(false);
                const eventErrorMessage = en[error.translationId as enTranslationKeys];
                const errorBodyMessage = (
                    <FormattedMessage
                        id={error.translationId}
                        values={{
                            p: (msg: React.ReactNode[]) => <p>
                                {msg}
                            </p>,
                            supportLink: <a
                                className={classes.a}
                                href="https://www.uipath.com/company/contact-us/cloud-platform-technical-support"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {translate({ id: 'CLIENT_HERE' })}
                            </a>,
                        }}
                    />
                );

                let errorDialogTitle = translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_TITLE' });
                if (error.translationId !== 'CLIENT_ERROR_LICENSE_DECREASE_SCHEDULE_FAILED') {
                    sendEcommerceEvent('Billing.PayError', {
                        productQuantities,
                        Error: eventErrorMessage,
                    });
                } else {
                    logEcommerceEvent('Licenses.ScheduleDecreaseError', { DecreasedProducts: decreaseProductsAndQuantitiesDictionary });
                    errorDialogTitle = translate({ id: 'CLIENT_SCHEDULE_LICENSE_DECREASE_TROUBLE' });
                }

                await createDialog({
                    title: errorDialogTitle,
                    body: errorBodyMessage,
                    icon: 'warning',
                    showCancel: false,
                    primaryButtonText: translate({ id: 'CLIENT_OK' }),
                });
            }
        },
        [
            stripe,
            getValues,
            getProductsAndQuantitiesDictionary,
            changes.productsToDecrease,
            changes.productsToAdd.length,
            currentAccountNameFromSelector,
            token,
            sendEcommerceEvent,
            logEcommerceEvent,
            handleNonSCAPayment,
            handleSCAPayment,
            createDialog,
            translate,
            planDetails?.endDate,
            language,
            navigate,
            getRoute,
            currentAccountName,
            classes.a,
        ],
    );

    return (
        <UiPanel
            navigationElement={
                <EcommerceBackToLicense />
            }
            data-cy="update-license-quantity-dialog"
        >
            {customSkuPackage && !loadingData ? (
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(submit)}>

                        <div className={classes.body}>
                            <div className={classes.twoColumns}>
                                <div className={classes.column1}>
                                    <UiText
                                        className={clsx(classes.tableTitle, classes.fontSizeH4Bold)}
                                        role="heading"
                                        aria-level={1}>
                                        {translate({ id: `CLIENT_UPDATE_LICENSE_QUANTITY` })}
                                    </UiText>
                                    <table className={classes.borderlessTable}>
                                        <colgroup>
                                            <col
                                                width="85%"
                                            />
                                            <col
                                                width="15%"
                                            />
                                        </colgroup>
                                        <tbody>

                                            <tr>
                                                <th
                                                    id="header_plan_overall_details"
                                                    scope="col" >
                                                    <UiText className={classes.tableHeader}>
                                                        {translate({ id: `CLIENT_LICENSE_TYPE` })}
                                                    </UiText>
                                                </th>
                                                <th
                                                    className={clsx(classes.tableHeader, classes.tableHeaderQuantity)}
                                                    id="header_package_quantity"
                                                    scope="col"
                                                >
                                                    <UiText className={classes.tableHeader}>
                                                        {translate({ id: `CLIENT_QUANTITY` })}
                                                    </UiText>
                                                </th>
                                            </tr>

                                            {ECOMMERCE_PRODUCT_CODES.map(productCode => (
                                                <tr
                                                    key={productCode}>
                                                    <th
                                                        scope="row"
                                                        id={`header_product_${productCode}`}
                                                        className={classes.productCell}>
                                                        <EcommerceProductInfo
                                                            productCode={productCode}
                                                            planType={currentSkuPackageDetails.planType} />
                                                    </th>

                                                    <td
                                                        headers={`header_product_${productCode}`}
                                                        className={clsx(
                                                            classes.innerCellBorder,
                                                        )}>
                                                        <div className={classes.quantityDiv}>
                                                            <EcommerceProductQuantityInput
                                                                productCode={productCode}
                                                                customSkuPackage={customSkuPackage}
                                                                loading={loadingData}
                                                                useFormMethods={(useFormMethods as never) as UseFormReturn<ICustomPlanForm>}
                                                                onChangeCallback={updateQuantityDifference}
                                                                minQuantity={getMinAllowedQuantity(productCode)}
                                                                isBasicInput
                                                            />
                                                            <div className={classes.quantityDifference} >
                                                                {quantityDifferencePerProduct[productCode]
                                                                    && quantityDifferencePerProduct[productCode]?.value !== 0 && (
                                                                    <div className={quantityDifferencePerProduct[productCode].sign === '+' ?
                                                                        classes.quantityAdded : classes.quantityRemoved}>
                                                                        {`${quantityDifferencePerProduct[productCode].sign}${quantityDifferencePerProduct[productCode].value}`}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <div className={classes.column2}>
                                    {productPricesList && (
                                        <EcommerceCheckoutOrderSummary
                                            {...currentSkuPackageDetails}
                                            products={updatedSubscriptionProducts}
                                            type="NEW_MONTHLY_BILL"
                                            taxLoading={false}
                                            currency={orderConfirmationData?.invoiceData?.currency ?? 'USD'}
                                            productPricesLoading={false}
                                            isFirstSubscription={false}
                                            productPrices={productPricesList}
                                            changes={changes}
                                            width="431px"
                                        />
                                    )}
                                    {changes.productsToDecrease.length !== 0 && planDetails?.endDate && (
                                        <div
                                            className={classes.licenseDecreaseBanner}
                                            data-cy="license-decrease-info-banner">
                                            <Info className={classes.infoIcon} />
                                            <div className={classes.licenseDecreaseText}>
                                                {translate({ id: 'CLIENT_CLIENT_LICENSE_DECREASES' },
                                                    { 0: formatDate(planDetails?.endDate, language) })}
                                            </div>
                                        </div>
                                    )}
                                    <Controller
                                        name="termsAndConditionsAccepted"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                className={classes.legalText}
                                                control={
                                                    <Checkbox
                                                        checked={field.value}
                                                        onChange={e => field.onChange(e.target.checked)}
                                                        color="primary"
                                                        data-cy="complete-invite-terms-conditions"
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <UiCheckBoxLabel
                                                        className={classes.checkboxLabel}
                                                        label="CLIENT_ECOMMERCE_INCREMENTAL_TERMS"
                                                        href={[ 'https://www.uipath.com/assets/downloads/online-purchase-terms' ]}
                                                        hrefText={[ 'CLIENT_TERMS_OF_USE_FOR_ONLINE_SOFTWARE' ]}
                                                    />
                                                }
                                                disabled={loadingData}
                                                data-cy="ecommerce-termsAndConditions"
                                            />
                                        )}
                                    />
                                    <div className={classes.confirmAndPaySection}>
                                        <UiProgressButton
                                            innerButtonClass={classes.confirmAndPayButton}
                                            loading={loading}
                                            disabled={
                                                (changes.productsToAdd.length === 0 && changes.productsToDecrease.length === 0)
                                                    || !termsAndConditionsAccepted || loading || loadingData
                                            }
                                            type="submit"
                                            variant="contained"
                                            data-cy="ecommerce-pay-button"
                                        >
                                            {changes.productsToAdd.length > 0
                                                ? translate({ id: 'CLIENT_CONFIRM_AND_PAY' })
                                                : translate({ id: 'CLIENT_CONFIRM_CHANGE' })}
                                        </UiProgressButton>
                                    </div>
                                    {(changes.productsToAdd.length > 0 || changes.productsToDecrease.length > 0) && (
                                        <div className={classes.renewTerms}>
                                            {translate({ id: `CLIENT_TERMS_OF_USE_RENEW_${currentSkuPackageDetails.planType}` })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            ) : (
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            )}
        </UiPanel>
    );
};
