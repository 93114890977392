import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import useMarketoUpdateOnDownload from '../../../common/hooks/useMarketoUpdateOnDownload';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { userGlobalId } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    ...GlobalStyles(theme),
    ...createStyles({
        buttonDefault: {
            fontWeight: 600,
            textTransform: 'none',
            height: '32px',
            '& span': { height: '20px' },
        },
        downloadButton: { minWidth: '200px' },
        copyButton: { marginLeft: '12px' },
        downloadAndCopyStudio: { marginTop: '16px' },
    }),
}));

const ResourceCenterCMSDownloadLink: React.FC<{
    downloadLink: string;
}> = ({ downloadLink }) => {
    const classes = useStyles();
    const userId = useSelector(userGlobalId);
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const onStudioDownload = useMarketoUpdateOnDownload();

    return (
        <div className={classes.downloadAndCopyStudio}>
            <a
                className={classes.a}
                href={downloadLink}
                rel="noopener noreferrer"
                onClick={() => {
                    portalTelemetry.trackTrace({
                        message: `Resource Center CMS Studio Download: ${userId} downloaded ${downloadLink}`,
                        severityLevel: SeverityLevel.Info,
                    });
                }}
                data-cy="download-card-primary-download-button-link"
                target="_blank"
            >
                <Button
                    variant="contained"
                    onClick={() => onStudioDownload(downloadLink)}
                    className={classes.buttonDefault}
                    data-cy="download-card-primary-download-button"
                >
                    {translate({ id: 'CLIENT_DOWNLOAD' })}
                </Button>
            </a>
            <CopyToClipboard text={downloadLink}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        portalTelemetry.trackTrace({
                            message: `Resource Center CMS Studio Download: ${userId} copied ${downloadLink}`,
                            severityLevel: SeverityLevel.Info,
                        });
                        createNotification(translate({ id: 'CLIENT_LINK_COPIED' }), notificationType.SUCCESS);
                    }}
                    className={clsx(classes.buttonDefault, classes.copyButton)}
                    data-cy="download-card-primary-copy-button"
                >
                    {translate({ id: 'CLIENT_COPY_LINK' })}
                </Button>
            </CopyToClipboard>
        </div>
    );
};

export default ResourceCenterCMSDownloadLink;
