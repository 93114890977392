import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import { Services } from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import { AiTrustRoutes } from '../aiTrustLayer/AiTrustRoutes';
import { AuditRoutes } from '../audit/AuditRoutes';
import { ExternalAppRoutes } from '../externalApps/ExternalAppRoutes';
import { AdminLicenseRoutes } from '../licensing/LicenseRoutes';
import { MailSettingsRoutes } from '../mailSettings/MailSettingsRoutes';
import { AdminSettingsRoutes } from '../organizationsettings/AdminSettingsRoutes';
import { OrgConsentRoutes } from '../orgConsent/OrgConsentRoutes';
import { SecurityRoutes } from '../securitySettings/SecurityRoutes';
import { TenantRoutes } from '../tenants/TenantRoutes';
import { AdminIdentitiesRoutes } from '../users/IdentitiesRoutes';

const OrganizationAdminHome = React.lazy(() => import('../organizationsettings/OrganizationAdminHome'));

export const OrgAdminRoutes: UiRouteObject = {
    path: routePaths.admin,
    element: <UiSuspensefulOutlet />,
    children: [
        {
            index: true,
            redirectUrls: [ Services ],
            element: <OrganizationAdminHome />,
        },
        AdminIdentitiesRoutes,
        AdminLicenseRoutes,
        SecurityRoutes,
        AuditRoutes,
        ExternalAppRoutes,
        MailSettingsRoutes,
        AdminSettingsRoutes,
        AiTrustRoutes,
        OrgConsentRoutes,
        TenantRoutes,
    ],
};
