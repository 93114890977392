import React from 'react';

import AddEditGroupAllocationComponent from '../../licensing/subcomponents/AddEditGroupAllocationComponent';
import EditUserAllocationComponent from '../../licensing/subcomponents/EditUserAllocationComponent';
import { useProfileComponentViewModel } from './ProfileComponentViewModel';

const ProfileEditLicenses = () => {
    const { isGroup } = useProfileComponentViewModel();

    if (isGroup) {
        return <AddEditGroupAllocationComponent type="edit" />;
    }
    return <EditUserAllocationComponent />;

};

export default ProfileEditLicenses;
