import { UserManager } from '@uipath/auth-react';
import {
    useCallback,
    useEffect,
} from 'react';

const RequestUserTokenCallback = () => {
    const callback = useCallback(async () => {
        const manager = new UserManager({} as any);
        await manager.signinSilentCallback();
    }, []);

    useEffect(() => {
        callback();
    }, [ callback ]);

    return null;
};

export default RequestUserTokenCallback;
