export const MaxUsernameLength = 256;
export const MaxFirstNameLength = 64;
export const MaxLastNameLength = 64;
export const ReservedUsernames = [ 'ServiceAccount_08da82db-70b7-4' ];

export enum UserType {
    User,
    Robot,
    DirectoryUser,
    DirectoryGroup,
    RobotAccount,
    DirectoryApplication,
}

export const USER_TYPE_ICON = {
    [UserType.User]: 'basic_auth_user',
    [UserType.Robot]: 'robot',
    [UserType.DirectoryUser]: 'directory_user',
    [UserType.DirectoryGroup]: 'directory_group',
    [UserType.RobotAccount]: 'robot',
    [UserType.DirectoryApplication]: 'directory_app',
};

// TODO: filters
// export const USER_TYPE_LABEL = {
//     [UserType.User]: 'CLIENT_LOCAL_ACCOUNT',
//     [UserType.Robot]: 'CLIENT_ROBOT',
//     [UserType.DirectoryUser]: 'CLIENT_MANAGED_ACCOUNT',
//     [UserType.DirectoryGroup]: 'CLIENT_DIRECTORY_GROUP',
//     [UserType.RobotAccount]: 'CLIENT_ROBOT',
//     [UserType.DirectoryApplication]: 'CLIENT_DIRECTORY_APPLICATION',
// };
