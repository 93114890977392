import { UiText } from '@experiences/ui-common';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import type { IDownloadItem } from '../../../common/interfaces/resource';
import ResourceCenterCMSDownloadLink from './ResourceCenterCMSDownloadLink';
import ResourceCenterCMSSupportedVersions from './ResourceCenterCMSSupportedVersions';

const useStyles = makeStyles(theme => ({
    ...createStyles({
        showOtherVersions: {
            margin: '0px 5px',
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: Tokens.FontFamily.FontNormal,
            lineHeight: '20px',
        },
        footerVersionContainer: {
            marginTop: '5px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        footerVersion: {
            marginRight: '5px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            lineHeight: '22px',
        },
        linkContainer: {
            marginLeft: '5px',
            display: 'flex',
            alignItems: 'center',
        },
        releaseNotes: {
            fontWeight: 400,
            color: theme.palette.semantic.colorPrimary,
            '&:hover': { textDecoration: 'underline' },
        },
        releaseIcon: { fontSize: '16px' },
    }),
}));

const ResourceCenterCMSCardFooter: React.FC<{
    studioVersion: string;
    downloadList?: Array<IDownloadItem<string>>;
    downloadLink: string;
    isEnterprise?: boolean;
    releaseNotes: string;
}> = ({
    studioVersion, downloadLink, downloadList, isEnterprise, releaseNotes,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const [ showVersion, setShowVersion ] = useState(false);

    return (
        <div>
            <div className={classes.footerVersionContainer}>
                {studioVersion && (
                    <UiText
                        className={classes.footerVersion}
                        display="inline"
                        data-cy="download-card-version">
                        {studioVersion}
                    </UiText>
                )}
                {isEnterprise && (
                    <Link
                        component='button'
                        underline='hover'
                        onClick={() => setShowVersion(!showVersion)}
                        className={classes.showOtherVersions}
                        data-cy="download-card-other-versions"
                    >
                        {showVersion
                            ? translate({ id: 'CLIENT_HIDE_OTHER_VERSION' })
                            : translate({ id: 'CLIENT_OTHER_VERSIONS' })}
                    </Link>
                )}
                {isEnterprise && <UiText>
                    {' '}
                    |
                    {' '}
                </UiText>}
                <Link
                    className={classes.linkContainer}
                    href={releaseNotes}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    data-cy="download-card-release-notes"
                >
                    <UiText
                        className={classes.releaseNotes}
                        display="inline">
                        {translate({ id: 'CLIENT_RELEASE_NOTES' })}
                    </UiText>
                    <span className={clsx('material-icons-outlined', classes.releaseIcon)}>
                        open_in_new
                    </span>
                </Link>
            </div>
            <div className="downloadAndCopyStudio">
                <ResourceCenterCMSDownloadLink downloadLink={downloadLink} />
            </div>
            {isEnterprise && (
                <Collapse in={showVersion}>
                    <ResourceCenterCMSSupportedVersions downloadList={downloadList} />
                </Collapse>
            )}
        </div>
    );
};

export default ResourceCenterCMSCardFooter;
