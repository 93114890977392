import { useQueryState } from '@experiences/util';
import type { PropsWithChildren } from 'react';
import React, {
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

import { getFriendlyName } from '../../../common/constants/ServicesMapping';
import { useTenantIdContext } from '../../../common/providers/TenantIdProvider';

export interface ICheckAccessPrincipal {
    identifier: string;
    displayName: string;
    email?: string;
}

interface ICheckAccessContext {
    principal?: ICheckAccessPrincipal;
    setPrincipal: React.Dispatch<React.SetStateAction<ICheckAccessPrincipal | undefined>>;
    roleId?: string;
    setRoleId: React.Dispatch<React.SetStateAction<string | undefined>>;
    selectedScope?: string;
    setSelectedScope: React.Dispatch<React.SetStateAction<string | undefined>>;
    scopedServiceName?: string;
}

const CheckAccessContext = createContext<ICheckAccessContext>({} as ICheckAccessContext);

export const useCheckAccessContext = () => useContext(CheckAccessContext);

export const CheckAccessProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const {
        services, tenantId,
    } = useTenantIdContext();

    // Selected principal
    const [ principal, setPrincipal ] = useQueryState<ICheckAccessPrincipal | undefined>('principal', undefined, undefined, true);

    // Selected Treeview state
    const [ selectedScope, setSelectedScope ] = useQueryState<string | undefined>('scope', undefined, undefined, true);

    // Selected role id
    const [ roleId, setRoleId ] = useQueryState<string | undefined>('roleId', undefined, undefined, true);

    // Scoped ServiceName for permissions call, undefined when tenant is selected
    const [ scopedServiceName, setScopedServiceName ] = useState<string>();

    useEffect(() => {
        const matchedService = services?.find(service => service.id === selectedScope);
        setScopedServiceName(matchedService?.serviceType ? getFriendlyName(matchedService.serviceType).replace(' ', '') : undefined);
    }, [ services, selectedScope ]);

    // Set default selected scope to tenantId once the principal is set
    useEffect(() => {
        if (principal) {
            setSelectedScope(prev => prev ?? tenantId);
        }
    }, [ setSelectedScope, tenantId, principal ]);

    return <CheckAccessContext.Provider value={{
        principal,
        setPrincipal,
        roleId,
        setRoleId,
        selectedScope,
        setSelectedScope,
        scopedServiceName,
    }}>
        {children}
    </CheckAccessContext.Provider>;
};
