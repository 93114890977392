import { UiLoader } from '@experiences/ui-common';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

import { loadService } from '../../../util/ServiceUtil';

const OrchestratorRouting: React.FC = () => {
    const {
        accountName, tenantName,
    } = useParams() as { accountName: string; tenantName: string };

    const tenantNameOutput = useMemo(() => {
        const tenantNameFromPath = tenantName;

        // react-router is including the params in the tenantName
        if (tenantNameFromPath.indexOf('?') > -1) {
            return tenantNameFromPath.split('?')[0];
        }

        return tenantName;
    }, [ tenantName ]);

    useEffect(() => {
        loadService(accountName, tenantNameOutput, 'orchestrator');
    }, [ tenantName, accountName, tenantNameOutput ]);

    return <UiLoader type="full" />;
};

export default OrchestratorRouting;
