import { ContactUsLinks } from '@experiences/constants';
import { useApolloTheme } from '@experiences/theme';
import { UiSessionId } from '@experiences/ui-common';
import {
    useLocalizedLinks,
    useNavigateWithParams,
} from '@experiences/util';
import { PortalAlertErrorPage } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useRef,
} from 'react';
import { useIntl } from 'react-intl';

const AccessPolicyError = () => {
    const navigate = useNavigateWithParams();
    const { themeId } = useApolloTheme();
    const portalAlertErrorPageRef = useRef<HTMLPortalAlertErrorPageElement>(null);
    const getLocalizedLink = useLocalizedLinks();
    const { formatMessage: translate } = useIntl();

    const onPrimaryClick = useCallback(() => {
        navigate('/portal_/logout');
    }, [ navigate ]);

    const onSecondaryClick = useCallback(() => {
        window.location.replace('https://www.uipath.com/');
    }, []);

    useEffect(() => {
        const { current } = portalAlertErrorPageRef;
        current?.addEventListener('primaryButtonClicked', onPrimaryClick);
        current?.addEventListener('secondaryButtonClicked', onSecondaryClick);
        return () => {
            current?.removeEventListener('primaryButtonClicked', onPrimaryClick);
            current?.removeEventListener('secondaryButtonClicked', onSecondaryClick);
        };
    }, [ onPrimaryClick, onSecondaryClick ]);

    return (
        <PortalAlertErrorPage
            ref={portalAlertErrorPageRef}
            type="error"
            heading={translate({ id: 'CLIENT_IP_RESTRICTION_HEADER' })}
            subheading={translate({ id: 'CLIENT_IP_RESTRICTION_MESSAGE' })}
            primaryActionText={translate({ id: 'CLIENT_SIGN_OUT' })}
            secondaryActionText={translate({ id: 'CLIENT_GO_TO_UIPATH_WEBSITE' })}
            theme={themeId}
            data-cy="access-policy-logout-button"
            footerLeftButtonUrl="https://status.uipath.com"
            footerLeftButtonText={translate({ id: 'CLIENT_SERVICE_STATUS' })}
            footerRightButtonUrl={getLocalizedLink(ContactUsLinks)}
            footerRightButtonText={translate({ id: 'CLIENT_CONTACT_US' })}
        >
            <UiSessionId style={{ margin: '12px' }} />
        </PortalAlertErrorPage>
    );
};

export default AccessPolicyError;
