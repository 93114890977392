import { GlobalStyles } from '@experiences/theme';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

import { notificationType } from '../../../common/constants/Constant';
import useMarketoUpdateOnDownload from '../../../common/hooks/useMarketoUpdateOnDownload';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';

const useStyles = makeStyles((theme) => ({
    ...GlobalStyles(theme),
    ...createStyles({
        buttonDefault: {
            fontWeight: 600,
            textTransform: 'none',
            height: '32px',
            '& span': { height: '20px' },
        },
        downloadButton: { minWidth: '200px' },
        copyButton: { marginLeft: '12px' },
        downloadAndCopyStudio: { marginTop: '16px' },
    }),
}));

const ResourceDownloadLinkComponent: React.FC<{
    link: string;
    type: string;
}> = ({
    link, type,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const onStudioDownload = useMarketoUpdateOnDownload();

    return (
        <div className={classes.downloadAndCopyStudio}>
            <a
                className={classes.a}
                href={link}
                rel="noopener noreferrer">
                <Button
                    variant="contained"
                    onClick={() => onStudioDownload(link)}
                    className={clsx(classes.buttonDefault, classes.downloadButton)}
                    data-cy="DownloadStudioButton"
                >
                    {translate({ id: `CLIENT_${type}` })}
                </Button>
            </a>
            <CopyToClipboard text={link}>
                <Button
                    variant="outlined"
                    onClick={() => createNotification(translate({ id: 'CLIENT_LINK_COPIED' }), notificationType.SUCCESS)}
                    className={clsx(classes.buttonDefault, classes.copyButton)}
                    data-cy="CopyStudioLinkButton"
                >
                    {translate({ id: 'CLIENT_COPY_LINK' })}
                </Button>
            </CopyToClipboard>
        </div>
    );
};

export default ResourceDownloadLinkComponent;
