import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import { TenantCreate } from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const TenantCreateComponent = React.lazy(() => import('./create/TenantCreateComponent'));

export const TenantRoutes: UiRouteObject = {
    path: routePaths.adminTenant,
    element: <UiSuspensefulOutlet />,
    children: [
        {
            path: routePaths.adminTenantCreate,
            redirectUrls: [ TenantCreate ],
            element: <TenantCreateComponent />,
        },
    ],
};
